import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import StarsIcon from '@material-ui/icons/Stars';
import CachedIcon from '@material-ui/icons/Cached';
import ArenaSlidesModel from '../../../models/arena-slides.model';
import seloParceiro from "../../../assets/arenas/selo-parceiro.png";
import ReactPlayer from 'react-player';
import "./item-slide.component.scss";
import { useHistory } from "react-router-dom";
import { UserContext } from '../../../components/userContext';
import { sendEvent } from '../../../scripts/events';

export type ItemSlideComponentProps = {
    openMoreServices?: () => void;
    slideData: any;
    isPlaying: any;
    typeCursos?: any;
    showMoreServices: boolean;
    key: any;
}

function ItemSlideComponent(props: ItemSlideComponentProps) {
    const userProps = useContext(UserContext);

    const YoutubeSlide = ({ url, isSelected }: { url: string; isSelected?: boolean }) => (
        <>
            {
                window.innerWidth > 823 ?
                    <ReactPlayer
                        width="100%" url={url}
                    />
                    :
                    <ReactPlayer
                        width="100%" url={url}
                        //playing={props.isPlaying}
                        onPlay={() => setIsPlayingNow(true)}
                        onPause={() => setIsPlayingNow(false)}
                    />
            }
        </>
    );

    let history = useHistory();

    const [isShowMenu, setIsShowMenu] = React.useState<boolean>(false);
    const [isPlayingNow, setIsPlayingNow] = React.useState<boolean>(false);

    const slideItem: ArenaSlidesModel = props.slideData;

    return (
        <>
            <div className={"item-slide flex flex-sp flex-wr " + slideItem.type + ' is-playing-' + isPlayingNow}>
                {
                    slideItem.video ?
                        <div className="img">
                            <YoutubeSlide key={props.key} url={slideItem.video} />
                            {
                                !props.typeCursos &&
                                <img src={seloParceiro} alt="Selo parceiro Bayer" />
                            }
                        </div>
                        :
                        <div className="img" style={{ backgroundImage: `url(${slideItem.bg})` }}>
                            <img src={seloParceiro} alt="Selo parceiro Bayer" />
                        </div>
                }
                <div className="box-right">
                    <h3>{slideItem.title}</h3>
                    {
                        !props.typeCursos ?
                            <>
                                <h4>Prestador de serviço: {slideItem.service_provider}</h4>
                                <p>
                                    {slideItem.description}
                                </p>
                                <ol>
                                    {
                                        slideItem?.services?.map((item, indexItem) => (
                                            <li key={indexItem}>
                                                <strong>{indexItem + 1}.</strong> {item}
                                            </li>
                                        ))
                                    }
                                </ol>
                            </>
                            :
                            <h4>{slideItem.service_provider}</h4>
                    }
                    <div className="box">
                        {
                            !props.typeCursos ?
                                <>
                                    <strong>Culturas:</strong>
                                    <p>
                                        {slideItem.cultures}
                                    </p>
                                    <strong>Benefícios:</strong>
                                    <ol>
                                        {
                                            slideItem?.benefits?.map((item, indexItem) => (
                                                <li key={indexItem}>
                                                    {item}
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </>
                                :
                                <>
                                    <strong>Descrição:</strong>
                                    <p>
                                        {slideItem.description}
                                    </p>
                                    <strong>Prestador de Serviço:</strong>
                                    <img style={{ maxHeight: "70px", width: "auto" }} src={slideItem.logo} alt="Logo" />
                                </>
                        }
                    </div>
                    <div className="cards flex flex-sp flex-wr">
                        {userProps.user && (
                            <div className="card red">
                                <StarsIcon />
                                <small>Você possui,</small>
                                <h2>{userProps.user?.points}</h2>
                                <small>Pontos Impulso Bayer.</small>
                            </div>
                        )}
                        <div className="card">
                            <CachedIcon />
                            <small>Resgate a partir de:</small>
                            <h2>{slideItem.points_redemption}</h2>
                            <small>Pontos Impulso Bayer.</small>
                        </div>
                    </div>
                    {props.typeCursos &&
                        <p>
                            <strong>Confira aqui o serviço disponível para o apoiar nesse assunto.</strong>
                        </p>
                    }
                    <Button
                        target="_blank"
                        href={slideItem.link_resgate && slideItem.link_resgate}
                        className="bt"
                        onClick={() => sendEvent({ category: 'slide-resgatar', label: slideItem.title, action: 'click' })}
                        >
                        Resgatar agora!
                    </Button>
                    <Button
                        target="_blank"
                        href="https://wa.me/551142103591?text=Olá!%20Vim%20através%20do%20Espaço%20Bayer%20e%20gostaria%20de%20mais%20informações%20sobre%20serviços.%20" 
                        rel="noopener"
                        className="bt especialista"
                        onClick={() => sendEvent({ category: 'slide-falar-com-o-especialista', label: slideItem.title, action: 'click' })}
                        >
                        Falar com o especialista
                    </Button>
                    {
                        (!props.typeCursos && props.showMoreServices && props.openMoreServices) &&
                        <>
                            <p>
                                <strong>Veja mais serviços que você pode resgatar:</strong>
                            </p>
                            <Button
                                className="bt mais"
                                onClick={() => {
                                    sendEvent({ category: 'slide-mais-serviços', label: slideItem.title, action: 'click' });
                                    props.openMoreServices();
                                }}>
                                <AddCircleOutlineIcon /> Saiba mais
                            </Button>
                        </>
                    }
                </div>
                {
                    !props.typeCursos &&
                    <img className="logo" src={slideItem.logo} alt="Logo" />
                }
            </div>
        </>
    );
}

export default ItemSlideComponent;