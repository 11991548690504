import React, { useEffect, useState } from 'react';
import "./boletim-do-clima.component.scss";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import imgGranizo from "../../../assets/boletim-do-clima/granizo.jpg";
import granizoIcon from "../../../assets/boletim-do-clima/granizo-icon.png";

import imgSol from "../../../assets/boletim-do-clima/sol.jpg";
import solIcon from "../../../assets/boletim-do-clima/sol-icon.png";

import imgNublado from "../../../assets/boletim-do-clima/nublado.jpg";
import nubladoIcon from "../../../assets/boletim-do-clima/nublado-icon.png";

import imgTempestade from "../../../assets/boletim-do-clima/tempestade.jpg";

import imgChuva from "../../../assets/boletim-do-clima/chuva.jpg";
import chuvaIcon from "../../../assets/boletim-do-clima/chuva-icon.png";


import spotifyIcon from "../../../assets/boletim-do-clima/spotify-icon.png";
import liveIcon from "../../../assets/boletim-do-clima/live-icon.png";
import { sendEvent } from '../../../scripts/events';
import { LoadVideoBgService } from '../../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../../constants/rotas-videos.constant';

export type BoletimDoClimaComponentProps = {
    //onClose: () => void;
    //openPage: ({ command }: { command: number }) => void;
    region: string;
}

export default function BoletimDoClimaComponent({ region }: BoletimDoClimaComponentProps) {
    const [weather, setWeather] = useState([]);
    const [areaTextActive, setAreaTextActive] = useState<boolean>(false);

    useEffect(() => {
        document.body.classList.add("boletim-do-clima");
        document.body.classList.remove("south");
        document.body.classList.remove("north-east");
        document.body.classList.remove("north");
        document.body.classList.remove("midwest");
        document.body.classList.remove("south-west");
        document.body.classList.add(region);
        setTimeout(() => {
            setAreaTextActive(true);
        }, 100);

        let admWP = localStorage.getItem('admWP');
        let itensBoletim = (JSON.parse(admWP)).itens_boletim;
        setWeather(itensBoletim);

        return () => {
            document.body.classList.remove("boletim-do-clima");
        }
    }, []);

    // granizo, sol, nublado, tempestade, chuva
    const selectedRegion = weather.find((item) => item.region === region);

    return (
        <section className="boletim-do-clima flex flex-cv flex-wr">
            <>
                <div className="area-image"></div>
                {!selectedRegion && (
                    <div className="area-text">
                        <h1>Dados indisponíveis</h1>
                    </div>
                )}
                {selectedRegion && (
                    <>
                        <div className={areaTextActive ? "area-text active" : "area-text"}>
                            <h1>{selectedRegion.title}</h1>
                            <h3>{selectedRegion.subtitle}</h3>
                            <div className="card">
                                {
                                    selectedRegion.image_name === 'granizo' &&
                                    <>
                                        <img src={imgGranizo} alt="Granizo" />
                                        <img className="tempo-icon" src={granizoIcon} alt="Granizo icon" />
                                    </>
                                }
                                {
                                    selectedRegion.image_name === 'sol' &&
                                    <>
                                        <img src={imgSol} alt="Sol" />
                                        <img className="tempo-icon" src={solIcon} alt="Sol icon" />
                                    </>
                                }
                                {
                                    selectedRegion.image_name === 'nublado' &&
                                    <>
                                        <img src={imgNublado} alt="Nublado" />
                                        <img className="tempo-icon" src={nubladoIcon} alt="Nublado icon" />
                                    </>
                                }
                                {
                                    selectedRegion.image_name === 'tempestade' &&
                                    <>
                                        <img src={imgTempestade} alt="Tempestade" />
                                        <img className="tempo-icon" src={chuvaIcon} alt="Tempestade icon" />
                                    </>
                                }
                                {
                                    selectedRegion.image_name === 'chuva' &&
                                    <>
                                        <img src={imgChuva} alt="Chuva" />
                                        <img className="tempo-icon" src={chuvaIcon} alt="Chuva icon" />
                                    </>
                                }

                                <h3>{selectedRegion.card_title}</h3>
                                <p>{selectedRegion.card_description}</p>
                            </div>
                            <p>
                                <strong>
                                    LIVE BOLETIM DO CLIMA
                                </strong>
                                Especialista ao vivo e convidados especiais com você aqui no Espaço Bayer, confira nossa agenda de eventos
                                <Button onClick={() => {
                                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.lives);
                                }}>clicando aqui</Button>.
                            </p>
                            <Button onClick={() => {
                                sendEvent({ category: 'boletim', label: 'Clima_btlive', action: 'click' });
                            }} target="_blank" className="live" href={selectedRegion.live}>
                                Assista agora a previsão completa da semana.
                            </Button>
                            <div>
                                <img src={liveIcon} alt="Live" />
                            </div>
                            <Button onClick={() => {
                                sendEvent({ category: 'boletim', label: 'Clima_btspot', action: 'click' });
                            }} target="_blank" className="spotify" href={selectedRegion.playlist_podcast_link}>
                                Se preferir, ouça a previsão completa da semana em podcast.
                            </Button>
                            <div>
                                <img src={spotifyIcon} alt="Spotify" />
                            </div>
                        </div>
                        <Button className="bt-fechar" onClick={() => {
                            if (window.innerWidth < 1024 && localStorage.getItem('urlFrom') === 'homeReunioes') {
                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeReunioes);
                            } else {
                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
                            }
                        }}>
                            <HighlightOffIcon />
                        </Button>
                    </>
                )}
            </>
        </section>
    )
};