import { get as getCookie, set as setCookie, remove as removeCookie } from 'es-cookie';

import { USER_DOC, USER_S_TOKEN, USER_S_EMAIL, UID, PROVIDER } from '../../constants/cookies';

export const retrieveUserDOC = (): string => getCookie(USER_DOC);

export const retrieveUserInfo = (): { token: string, email: string } => {
  return {
    token: getCookie(USER_S_TOKEN),
    email: getCookie(USER_S_EMAIL)
  }
}

export const clearUserInfo = () => {
  removeCookie(UID);
  removeCookie(PROVIDER);
  removeCookie(USER_DOC);
  removeCookie(USER_S_TOKEN);
  removeCookie(USER_S_EMAIL);
}

export const retrieveGigya = (): { UID: string, provider: string } => {
  return {
    UID: getCookie(UID),
    provider: getCookie(PROVIDER)
  }
}

export const setProvider = (value: string) => {
  setCookie(PROVIDER, value);
}
