import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as slidesJson from './arena-negocios.json';
import ArenaSlidesComponent from '../../../components/arena-slides/arena-slides.component';

export type ArenaNegociosPageProps = {
    onClose: () => void;
}

export default function ArenaNegociosPage(props: ArenaNegociosPageProps) {
    return (
        <ArenaSlidesComponent
            slidesJson={slidesJson}
            colorDark={'rgb(16,56,79)'}
            colorLight={'rgb(21, 90, 129)'}
            transform={'scale(0.5) translate(-16vw,-20vh)'}
            onClose={() => {
                props.onClose();
            }}
        />
    );
}