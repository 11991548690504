import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as slidesJson from './arena-risco.json';
import ArenaSlidesComponent from '../../../components/arena-slides/arena-slides.component';

export type ArenaRiscoPageProps = {
    onClose: () => void;
}

export default function ArenaRiscoPage(props: ArenaRiscoPageProps) {
    
    return (
        <ArenaSlidesComponent
            //firstSlide={metadata}
            slidesJson={slidesJson}
            colorDark={'rgb(68,50,71)'}
            colorLight={'rgb(122, 82, 129)'}
            transform={'scale(0.6) translate(-25vw,-7vh)'}
            onClose={()=> {
                props.onClose();
            }}
        />
    );
}