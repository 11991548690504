import { Button } from '@material-ui/core';
import React from 'react';
import ArenaSlidesModel from '../../../models/arena-slides.model';
import "./card-servicos.component.scss";
import abaIMG from "../../../assets/aba-img.png";

export default function CardServicosComponent(props: any) {
    const [isShowMenu, setIsShowMenu] = React.useState<boolean>(false);
    let itemMaisServicos: ArenaSlidesModel = props.maisServicos;

    const toggleMenu = () => {
        setIsShowMenu(!isShowMenu);
    }

    const btSaibaMais = () => {
        props.retornoCard();
    }

    return (
        <>
            <div className="card-services">
                <div className="box-img" style={{ backgroundImage: `url(${itemMaisServicos.bg})` }}>
                    <img src={itemMaisServicos.logo} alt="Logo" />
                    <img className="aba" src={abaIMG} alt="Aba" />
                </div>
                <div className="services-text flex flex-wr">
                    <div className="service-content">
                        <h3>{itemMaisServicos.title} {itemMaisServicos.service_provider}</h3>
                        <h4>Resgate: à partir de {itemMaisServicos.points_redemption} pts.</h4>

                        <p>
                            {itemMaisServicos.description.substring(0, 150) + "..."}
                        </p>
                    </div>
                    <Button onClick={btSaibaMais} className={itemMaisServicos.type}>Saiba mais</Button>
                    <i className={itemMaisServicos.type}></i>
                </div>
            </div>
        </>
    );
}