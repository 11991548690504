import axios from "axios";
import ComentariosModel from "../components/comentarios/comentarios.model";
import { environment } from "../environments/environment";

const config: any = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Credentials': true,
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    }
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

// Espaço Bayer ADM
export async function GetEspacoBayerADMService() {
    let url = '/espacobayer/get-espaco-bayer';
    return await api.get(url);
}

export async function GetForumService() {
    let url = '/espacobayer/forum';
    return await api.get(url, {});
}

export async function GetTarefaService(email: string) {
    let url = '/get-tarefa';
    return await api.post(url, { email: email }, {});
}

export async function ListarTarefasService(email: string) {
    let url = '/listar-minhas-tarefas';
    return await api.post(url, { email: email }, {});
}

export async function SalvarTarefaService(email: string, id_tarefa: string) {
    let url = '/salvar-tarefa';
    return await api.post(url, { email: email, id_tarefa: id_tarefa }, {});
}


export async function ListarComentariosService(dto: ComentariosModel) {
    let url = '/apis/listar-comentarios';
    return await api.post(url, dto, { cancelToken: dto.cancel });
}