import React, { useState, useContext, useEffect } from 'react';
import { UserType } from '../../types/user';
import { UserContext } from '../../components/userContext';
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import "./insurance-simulator.scss";

const URL = 'https://bayerpy-env-1.eba-c8hwmgta.us-east-2.elasticbeanstalk.com/bayer_proposal/espaço_bayer/azCxREEkrHsAeWuxyoj$vGv';

const getIframePath = async (user? : UserType) => {
  const points = user?.points ? user?.points : 0;
  const name = user?.name ? user?.name : "";
  const email = user?.email ? user?.email : "";
  const CPF = user?.CPF ? user?.CPF : "";
  const phone = user?.phone ? user?.phone : "";

  const body = {
    Programa: {
      Nome: "",
      IdCampanha: "",
      IdParticipante: "",
      IdProduto: "",
      SaldoPontos: points,
      TaxaConversao: 0,
    },
    DadosTitular: {
      Cpf: CPF,
      DataNascimento: "",
      Titular: name,
      Genero: "",
      Telefone: "",
      Celular: phone,
      Email: email,
      rg: "",
      orgao_emissor: "",
      data_emissao:"",
      estado_civil: ""
    },
    EnderecoTitular: {
      Logradouro: "",
      Numero: "",
      Complemento: "",
      Bairro: "",
      Cidade: "",
      UF: "",
      Cep: ""
    },
    Retorno:{
        Url : "#"
    }
  }

  return fetch(URL, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body : JSON.stringify(body)
  }).then((value) => {
    return value.json();
  }).then((json) => {
    return json.iframe;
  }).catch((err) => {
    throw new Error(err);
  });
}

export type InsuranceSimulatorProps = {
  onClose: () => void;
}

export default function InsuranceSimulator({ onClose } : InsuranceSimulatorProps) {
  const { user } = useContext(UserContext);
  const [simulatorPath, setSimulatorPath] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');

    getIframePath(user).then((iframePath) => {
      setSimulatorPath(iframePath);
    }).catch(() => {
      setSimulatorPath('https://agristamp.com.br/login/?next=/cotar/');
      //setError('Infelizmente não foi possível carregar o simulador de seguros, tente novamente mais tarde');
    });
  },[]);

  return (
    <section className="insurance flex">
      <div className="screen flex flex-cv">
        {simulatorPath && (
          <iframe src={simulatorPath} title="Simulação de seguro" />
        )}
        {error && (
          <p>{error}</p>
        )}
      </div>
      <Button className="bt-fechar" onClick={onClose}>
          <HighlightOffIcon />
      </Button>
    </section>
  );
}