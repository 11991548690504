import React, { useEffect, useState, useContext } from 'react';
import "./forum.page.scss";
import impulsoImg from "../../assets/menu/impulso-logo-branco.png";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import logoForum from "../../assets/converse-bayer/logo-forum.png";
import { sendEvent } from '../../scripts/events';
import Comments from '../../components/comments/comments';
import ModalForumComponent from '../../components/modal-forum/modal-forum.component';
import ForumModel from './forum.model';
import { GetForumService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { UserContext } from '../../components/userContext';
import { SalvarTarefaSubjectService } from '../../services/salvar-tarefa.service';
import { FinalVideoService } from '../../services/final-video.service';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';

export default function ForumPage() {

    const [animationActive, setAnimationActive] = useState<boolean>(false);
    const [showModalForum, setShowModalForum] = useState<boolean>(false);
    const [showCommentsForum, setShowCommentsForum] = useState<boolean>(false);
    const [apiForum, setApiForum] = useState<ForumModel>();

    const [idUnicoUsConvidado, setIdUnicoUsConvidado] = React.useState<string>();
    const [nomeUsuario, setNomeUsuario] = React.useState<string>();
    const [emailUsuario, setEmailUsuario] = React.useState<string>();
    const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);

    const userProps = useContext(UserContext);

    useEffect(() => {

        FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
            if (window.location.href.indexOf('forum') > -1) {
                setShowBolinhas(true);
                setTimeout(() => {
                    setAnimationActive(true);
                }, 100);
                GetForum();
            }
        });

        if (!localStorage.getItem("idConvidado")) {
            let n = Math.floor(Math.random() * 1000000000000)
            localStorage.setItem("idConvidado", ('conv' + n));
        }
        if (userProps.user) {
            setNomeUsuario(userProps.user.name);
            setEmailUsuario(userProps.user.email);
            //Tarefa5();
        }
        setIdUnicoUsConvidado(localStorage.getItem("idConvidado"));

    }, []);

    const open = (command: number, label: string) => {
        //props.openPage({ command });
    }

    const entrarModal = (nome: string, email: string) => {
        setNomeUsuario(nome);
        setEmailUsuario(email);
        Tarefa5();
        setShowCommentsForum(true);
        GetForum();
    }

    const Tarefa5 = () => {
        if (localStorage.getItem('tarefaTipo') === 'Tarefa 5') {
            setTimeout(() => {
                SalvarTarefaSubjectService.sendSalvar(true);
            }, 10000);
        }
    }

    const GetForum = () => {
        LoadingService.sendLoadingAtivo(true);
        GetForumService().then(res => {
            LoadingService.sendLoadingAtivo(false);
            if (res.data.error) {
                SnackService.sendMSG(res.data.error);
            } else {
                setApiForum(res.data);
                setShowModalForum(false);
            }
        }).catch(err => {
            LoadingService.sendLoadingAtivo(false);
            if (err.response?.status === 401) {
                SnackService.sendMSG('ERRO 401');
            } else {
                SnackService.sendMSG('Erro com a conexão ao verificar ADM.');
            }
        })
    }

    return (
        <>
            {
                showBolinhas &&
                <>
                    <section className={"converse-bayer flex" + " animation-" + animationActive}>
                        <div className="area-title">
                            <h1 className="flex flex-cv">
                                {/* <img src={logoForum} alt="Logo Forum" /> */}
                                <span dangerouslySetInnerHTML={{ __html: apiForum?.titulo }}>
                                </span>
                            </h1>
                            <div dangerouslySetInnerHTML={{ __html: apiForum?.iframe }}></div>
                            <h4>
                                {apiForum?.subtitulo_01}
                            </h4>
                            <p>
                                {apiForum?.descricao_01}
                            </p>
                            <div className="bts">
                                {
                                    apiForum?.legenda_botao_01 &&
                                    <Button onClick={() => {
                                        sendEvent({ category: 'converse_bayer', label: 'Conv_btwhats', action: 'click' });
                                        if (localStorage.getItem('tarefaTipo') === 'Tarefa 6') {
                                            setTimeout(() => {
                                                SalvarTarefaSubjectService.sendSalvar(true);
                                            }, 10000);
                                        }
                                    }} className="bt" href={apiForum?.link_botao_01} target="_blank" rel="noopener">
                                        {apiForum?.legenda_botao_01}
                                    </Button>
                                }
                                {
                                    apiForum?.legenda_botao_02 &&
                                    <Button onClick={() => {
                                        sendEvent({ category: 'converse_bayer', label: 'Conv_btVideoChamada', action: 'click' });
                                    }} className="bt" href={apiForum?.link_botao_2} target="_blank" rel="noopener">
                                        {apiForum?.legenda_botao_02}
                                    </Button>
                                }
                                {
                                    apiForum?.legenda_botao_03 &&
                                    <Button onClick={() => {
                                        sendEvent({ category: 'converse_bayer', label: 'Conv_btlive', action: 'click' });
                                        LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.lives);
                                    }} className="bt">
                                        {apiForum?.legenda_botao_03}
                                    </Button>
                                }
                                {
                                    apiForum?.legenda_botao_04 &&
                                    <Button onClick={() => {
                                        sendEvent({ category: 'converse_bayer', label: 'Conv_btPodCast', action: 'click' });
                                    }} className="bt" href={apiForum?.link_botao_4} target="_blank" rel="noopener">
                                        {apiForum?.legenda_botao_04}
                                    </Button>
                                }
                            </div>
                            <h4>
                                {apiForum?.subtitulo_2}
                            </h4>
                            {
                                apiForum?.legenda_botao_5 &&
                                <Button onClick={() => {
                                    sendEvent({ category: 'converse_bayer', label: 'Conv_360', action: 'click' });
                                }} className="bt menor" href={apiForum?.link_botao_5} target="_blank" rel="noopener">
                                    {apiForum?.legenda_botao_5}
                                </Button>
                            }
                            <p dangerouslySetInnerHTML={{ __html: apiForum?.descricao_2 }}>
                            </p>
                            <p dangerouslySetInnerHTML={{ __html: apiForum?.descricao_3 }}>
                            </p>
                            <p>
                                <small>Powered by:</small> <img src={impulsoImg} alt="Impulso Bayer" />
                            </p>
                        </div>
                        <div className="area-text">
                            <h5>
                                {apiForum?.titulo_dir}
                            </h5>
                            <h4>
                                {apiForum?.subtitulo_dir_1}
                            </h4>
                            <h3>
                                {apiForum?.subtitulo_dir_2}
                            </h3>
                            {
                                (showCommentsForum) &&
                                <Comments
                                    liveId={apiForum?.id_chat}
                                    userConvidado={{
                                        user: {
                                            UID: nomeUsuario ? idUnicoUsConvidado : '',
                                            name: nomeUsuario,
                                            email: emailUsuario,
                                            CPF: '12345678982',
                                            stars: 0,
                                            points: 0,
                                            loading: true,
                                            phone: '',
                                            plantedArea: []
                                        }
                                    }} />
                            }
                            {
                                (!showCommentsForum) &&
                                <Button className="bt bt-entrar-forum" onClick={() => {
                                    sendEvent({ category: 'converse_bayer', label: 'bt_part_forum', action: 'click' });
                                    if (emailUsuario && nomeUsuario) {
                                        setShowCommentsForum(true);
                                    } else {
                                        setShowModalForum(true);
                                    }
                                }}>
                                    Participar do Fórum
                                </Button>
                            }
                        </div>
                        <Button className="bt-fechar" onClick={() => {
                            //props.onClose
                            if (window.innerWidth < 1024 && localStorage.getItem('urlFrom') === 'homeReunioes') {
                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeReunioes);
                            } else {
                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
                            }
                        }}>
                            <HighlightOffIcon />
                        </Button>
                    </section>
                    {
                        showModalForum &&
                        <ModalForumComponent entrar={entrarModal} onClose={() => {
                            setShowModalForum(false)
                        }} />
                    }
                </>
            }
        </>
    );
}