import { ROTAVIDEOS } from './rotas-videos.constant';

export const CAROL = {
  attendant: 'Carol',
  talk: [
    {
      id: '1',
      message: 'Seja muito bem-vindo(a) ao Espaço Bayer.',
      trigger: '2',
    },
    {
      id: '2',
      message: 'Aqui você vai conhecer nossos serviços e produtos de um jeito diferente, imersivo e interativo. Fale com especialistas, troque experiências, ganhe pontos extras e resgate utilizando seus pontos Impulso Bayer.',
      trigger: '3',
    },
    {
      id: '3',
      message: 'Escolha como quer fazer essa viagem pelo "metaverso" da Bayer:',
      trigger: '4',
    },
    {
      id: '4',
      options: [
        { value: { option: ROTAVIDEOS.maisServicos }, label: 'PESQUISAR TODOS OS SERVIÇOS' },
        { value: { option: ROTAVIDEOS.foxXproPlantacao }, label: 'Produto em destaque' },
        { value: { option: ROTAVIDEOS.lives }, label: 'PARTICIPAR DE EVENTOS' },
        // { value: { option: ROTAVIDEOS.desafios }, label: 'CUMPRIR DESAFIOS PREMIADOS' },
        { value: { option: ROTAVIDEOS.forum }, label: 'PARTICIPAR DO FÓRUM' },
        { value: { option: ROTAVIDEOS.especialista }, label: 'FALAR COM UM ESPECIALISTA' },
        { value: { option: ROTAVIDEOS.sobreBayer }, label: 'SOBRE O IMPULSO BAYER' },
      ],
      end: true,
    },
  ]
};

export const JOAO_PISO_SUPERIOR = {
  attendant: 'João',
  talk: [
    {
      id: '1',
      message: 'Olá! O nosso produto em destaque é o Fox Xpro.',
      trigger: '2',
    },
    {
      id: '2',
      message: 'Quer saber mais sobre a evolução da confiança para a sua lavoura?',
      trigger: '3',
    },
    {
      id: '3',
      options: [
        { value: { option: ROTAVIDEOS.foxXproPlantacao }, label: 'Saber mais sobre Fox Xpro' },
      ],
      end: true,
    },
  ]
};

export const JOAO_PLANTACAO = {
  attendant: 'João',
  talk: [
    {
      id: '1',
      message: 'Suas culturas de soja e milho merecem a confiança e tecnologia de Fox Xpro.',
      trigger: '2',
    },
    {
      id: '2',
      message: 'O amplo espectro de proteção e controle sobre 7 alvos impacta diretamente na produtividade e nada melhor do que ouvir a indicação de quem confiou e viu na prática esses resultados!',
      trigger: '3',
    },
    {
      id: '3',
      message: 'Assista agora nossa websérie e veja o que os produtores de todo o Brasil tem para contar a você sobre Fox Xpro:',
      trigger: '4',
    },
    {
      id: '4',
      options: [
        { value: { option: ROTAVIDEOS.assistir_webserie }, label: 'ASSISTIR A WEBSÉRIE' },
        { value: { option: ROTAVIDEOS.especialista }, label: 'FALAR COM UM ESPECIALISTA' },
      ],
      end: true,
    },
  ]
};
