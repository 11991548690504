import React, { useEffect, useState } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import { sendEvent } from '../../scripts/events';
import impulsoLogo from "../../assets/menu/impulso-logo.png";
import { Button, Menu, MenuItem } from '@material-ui/core';
import HoverMessage from '../../components/hover-message/hover-message.component';
import { MenuService } from '../../services/menu.service';

import './home.page.scss';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { FinalVideoService } from '../../services/final-video.service';
import Home360Component from './home360/home360.component';

export interface MainMenuScreenProps {
  openPage: ({ command }: { command: number }) => void;
  setTutorialVisible: (tutorialVisible: boolean) => void;
}

export default function HomePage(props: MainMenuScreenProps) {

  const [, setButtonsEnabled] = useState(false);
  const [message, setMessage] = useState('');
  const handleMouse = (message: string) => setMessage(message);

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const [openMenuEventos, setOpenMenuEventos] = useState(false);
  const [anchorElEventos, setAnchorElEventos] = React.useState<null | HTMLElement>(null);
  const handleClickMenuEventos = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElEventos(event.currentTarget);
    setOpenMenuEventos(true);
  };
  const handleCloseMenuEventos = () => {
    setAnchorElEventos(null);
    setOpenMenuEventos(false);
  };

  const [openMenuFeira, setOpenMenuFeira] = useState(false);
  const [anchorElFeira, setAnchorElFeira] = React.useState<null | HTMLElement>(null);
  const handleClickMenuFeira = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFeira(event.currentTarget);
    setOpenMenuFeira(true);
  };
  const handleCloseMenuFeira = () => {
    setAnchorElFeira(null);
    setOpenMenuFeira(false);
  };

  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showDesktop, setShowDesktop] = React.useState<boolean>(true);
  const [burgerAtivo, setBurgerAtivo] = React.useState<boolean>(false);


  useEffect(() => {
    setButtonsEnabled(true);
    //props.setTutorialVisible(true);

    if (window.innerWidth < 1024) {
      setShowDesktop(false);
    }

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.pathname === '/home') {
        setShowBolinhas(true);
        document.body.classList.add("show-powered");
        document.body.classList.add("loaded");
        document.body.classList.add("home-inferior");
      }
    });

    if (window.location.href.indexOf(ROTAVIDEOS.homeInferior) > -1) {
      setShowBolinhas(true);
      document.body.classList.add("show-powered");
      document.body.classList.add("loaded");
    }

    document.body.classList.add("home-inferior");

    return () => {
      document.body.classList.remove("show-powered");
      document.body.classList.remove("home-inferior");
    }
  }, []);

  const open = (command: number, label: string) => {
    //sendEvent({ category: 'plano-aberto', label, action: 'click' });
    //props.openPage({ command });
  }

  return (
    <>
      {
        showBolinhas &&
        <>
          {
            showDesktop ?
              <>
                <section className="main-menu">
                  <Button className="bolinhas inovation"></Button>
                </section>

                <div className="main-menu-hud">
                  <FullscreenHud>
                    <FullscreenHudElement key="configs" highlight={assets.empty} >
                      <>
                        <defs>
                          <filter id="blur">
                            <feGaussianBlur stdDeviation="4" />
                          </filter>
                        </defs>
                      </>
                    </FullscreenHudElement>

                    {/* Clima */}
                    <FullscreenHudElement key="weather" highlight={assets.empty}>
                      <>
                        <circle cx="1000" cy="500" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="1000" cy="500" r="10" className='circle-button borda' />
                        <circle cx="1000" cy="500" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="960" y="420" width="80" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="973" y="447" className="legenda-mobile" >Clima</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-weather" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home', label: 'Home_ptclima', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.clima);
                    }}>
                      <rect x='910' y='430' width='150' height='150'
                        onMouseOver={() => handleMouse('Confira aqui a previsão do tempo na sua região')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim clima */}

                    {/* Forum */}
                    <FullscreenHudElement key="contact" highlight={assets.empty} >
                      <>
                        <circle cx="1335" cy="500" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="1335" cy="500" r="10" className='circle-button borda' />
                        <circle cx="1335" cy="500" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="1275" y="430" width="120" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="1290" y="455" className="legenda-mobile" >Converse</text>
                      </>

                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-contact" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home', label: 'Home_ptconverse', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.forum);
                    }}>
                      <rect x='1270' y='420' width='150' height='150'
                        onMouseOver={() => handleMouse('Converse com os Especialistas de Serviços Bayer')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim forum */}

                    {/* Lives */}
                    <FullscreenHudElement key="live" highlight={assets.empty}>
                      <>
                        <circle cx="980" cy="710" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="980" cy="710" r="10" className='circle-button borda' />
                        <circle cx="980" cy="710" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="1020" y="690" width="70" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="1035" y="715" className="legenda-mobile" >Live</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-live" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home', label: 'Home_ptlive', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.lives);
                    }}>
                      <rect x='900' y='630' width='150' height='150'
                        onMouseOver={() => handleMouse('Confira aqui as nossas Lives')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim lives */}

                    {/* Reuniões */}
                    <FullscreenHudElement key="meeting" highlight={assets.empty}>
                      <>
                        <circle cx="410" cy="490" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="410" cy="490" r="10" className='circle-button borda' />
                        <circle cx="410" cy="490" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="345" y="410" width="130" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="365" y="435" className="legenda-mobile" >Reuniões</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-meeting" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home', label: 'Home_ptmeeting', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.reunioes);
                    }}>
                      <rect x='335' y='420' width='150' height='150'
                        onMouseOver={() => handleMouse('Confira aqui as nossas reuniões')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim reuniões */}

                  </FullscreenHud>
                </div>

                <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
                  <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
                </div>

                <div className="fot-logo flex flex-ch">
                  <div className="box flex flex-cv">
                    <span>Powered by</span> <img src={impulsoLogo} alt="Impulso Logo" />
                  </div>
                </div>
              </>
              :
              <>
                <Home360Component />
              </>
          }

          <div className={"menu-foot"}>
            <Button className="bt piso-superior-mobile" onClick={() => {
              sendEvent({ category: 'home', label: 'menu_foot_superior', action: 'click' });
              LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
            }}>
              Piso superior
            </Button>

            <ul className={"burger-" + burgerAtivo}>
              <li>
                <Button
                  aria-controls="eventos"
                  aria-haspopup="true"
                  className="bt"
                  onClick={handleClickMenuFeira}
                >Feiras</Button>
                <Menu
                  id="eventos"
                  anchorEl={anchorElFeira}
                  open={openMenuFeira}
                  onClose={handleCloseMenuFeira}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => {
                    setOpenMenuFeira(false);
                    sendEvent({ category: 'home', label: 'menu_foot_show_rural', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.feira);
                  }}>Show Rural</MenuItem>
                  <MenuItem onClick={() => {
                    setOpenMenuFeira(false);
                    sendEvent({ category: 'home', label: 'menu_foot_expodireto', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.expodireto);
                  }}>Expodireto</MenuItem>
                  <MenuItem onClick={() => {
                    setOpenMenuFeira(false);
                    sendEvent({ category: 'home', label: 'menu_foot_tecnoshow', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.tecnoshow);
                  }}>Tecnoshow</MenuItem>
                  <MenuItem onClick={() => {
                    setOpenMenuFeira(false);
                    sendEvent({ category: 'home', label: 'menu_foot_agrobrasilia', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.agrobrasilia);
                  }}>Agro Brasília</MenuItem>
                </Menu>
              </li>

              <li>
                <Button
                  aria-controls="eventos"
                  aria-haspopup="true"
                  className="bt"
                  onClick={handleClickMenuEventos}
                >Eventos</Button>
                <Menu
                  id="eventos"
                  anchorEl={anchorElEventos}
                  open={openMenuEventos}
                  onClose={handleCloseMenuEventos}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => {
                    setOpenMenuEventos(false);
                    sendEvent({ category: 'home', label: 'menu_foot_lives', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.lives);
                  }}>Lives</MenuItem>
                  <MenuItem onClick={() => {
                    setOpenMenuEventos(false);
                    sendEvent({ category: 'home', label: 'menu_foot_reunioes', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.reunioes);
                  }}>Reuniões</MenuItem>
                </Menu>
              </li>
              <li>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home', label: 'menu_foot_clima', action: 'click' });
                  LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.clima);
                }}>
                  Boletim do clima
                </Button>
              </li>
              <li>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home', label: 'menu_foot_forum', action: 'click' });
                  LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.forum);
                }}>
                  Fórum
                </Button>
              </li>
              {/* <li>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home', label: 'menu_foot_desafios', action: 'click' });
                  MenuService.sendAtivo(true);
                }}>
                  Desafios premiados
                </Button>
              </li> */}
              <li className='piso-superior'>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home', label: 'menu_foot_superior', action: 'click' });
                  LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
                }}>
                  Piso superior
                </Button>
              </li>
            </ul>

            <button
              onClick={() => {
                setBurgerAtivo(!burgerAtivo);
              }}
              className={"hamburger hamburger--squeeze " + (burgerAtivo ? 'is-active' : '')}
              //className={"hamburger hamburger--3dxy "}
              type="button"
            >
              <strong>Menu</strong>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </>
      }
    </>
  );
}
