import React from 'react';
import "./hover-message.component.scss";

export interface HoverMessageProps {
  children?: any;
  className?: string;
}

export default function HoverMessage(props: HoverMessageProps) {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
}