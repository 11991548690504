import React, { useContext, useEffect, useState } from 'react';
import "./board-productivity.page.scss";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarIcon from '@material-ui/icons/Star';

import fogueteIcon from "../../assets/board-climate/foguete-icon.png";
import raioIcon from "../../assets/board-climate/raio-icon.png";

import { UserContext } from '../../components/userContext';
import { sendEvent } from '../../scripts/events';

export type BoardProductivityPageProps = {
    onClose: () => void;
}

export default function BoardProductivityPage(props: BoardProductivityPageProps) {
    const { user } = useContext(UserContext);
    const [userInfo, setUserInfo] = useState({
        UID: '',
        name: '',
        email: '',
        CPF: '',
        stars: 0,
        points: 0,
        loading: false,
    });

    useEffect(() => {
        if (user) {
            setUserInfo(user);
            return;
        }
    }, [user]);

    const Stars = () => {
        const allStars = [];
        let yellowStarsTotal = 0;
        for (let index = 0; index < userInfo.stars; index++) {
            allStars.push(<StarIcon />);
            yellowStarsTotal++;
        }
        let grayStarsTotal = 5 - yellowStarsTotal;
        for (let index = 0; index < grayStarsTotal; index++) {
            allStars.push(<StarIcon className="gray" />);
        }
        return (<>{allStars}</>);
    }

    return (
        <>
            <section className="board-climate produtividade flex flex-cv flex-wr">
                <div className="area-text">
                    <div className="box-points flex flex-ch">
                        <div className="esq">
                            <small>Você possui,</small>
                            <h3>{userInfo ? userInfo.points : 0}</h3>
                            <small>Pontos Impulso Bayer.</small>
                        </div>
                        <div className="right flex flex-cv flex-ch flex-wr">
                            <div>
                                <small>Você possui,</small>
                                <div className="flex flex-cv flex-ch">
                                    <Stars />
                                </div>
                                <small>estrelas.</small>
                            </div>
                        </div>
                    </div>
                    <div className="box-msgs entrada">
                        <img className="icon" src={fogueteIcon} alt="Foguete" />
                        <h2>Patrulha – Soja e milho</h2>
                        <h3>Prestador de serviço: Patrulha</h3>
                        <h4>Resgate a partir de: 17.500 Pontos Impulso Bayer</h4>
                        <ol>
                            <li>
                                • Visão geral da propriedade com nível de infestação de praga por talhão
                            </li>
                            <li>
                                • Indicação da quantidade e estágio das pragas encontradas
                            </li>
                            <li>
                                • Detalhe dos dados coletados em cada ponto amostral
                            </li>
                            <li>
                                • Histórico do talhão
                            </li>
                        </ol>
                        <p>
                            <strong>
                                O Patrulha é um serviço que oferece uma equipe especializada para apoiar o produtor no monitoramento das principais pragas da soja e do milho, facilitando a sua tomada de decisão. Uma ferramenta digital e conectada, que disponibiliza o monitoramento da lavoura na palma da mão e com um sistema compatível com Climate FieldView™.
                            </strong>
                        </p>
                        <Button className="bt"
                            onClick={() => {
                                sendEvent({ category: 'board-produtividade-resgatar', label: 'patrulha_sojamilho', action: 'click' });
                            }}
                            target="_blank" rel="noopener"
                            href='https://www.orbia.ag/categoria/65168/patrulha?q=&s=0&fft=34&fc=70709&mprt=p'>
                            Resgatar agora!
                        </Button>
                        <Button
                            target="_blank"
                            href="https://wa.me/551142103591?text=Olá!%20Vim%20através%20do%20Espaço%20Bayer%20e%20gostaria%20de%20mais%20informações%20sobre%20serviços.%20"
                            rel="noopener"
                            className="bt especialista"
                            onClick={() => sendEvent({ category: 'board-produtividade-resgatar', label: 'especialista', action: 'click' })}
                        >
                            Falar com o especialista
                        </Button>
                    </div>
                    <div className="box-msgs plus">
                        <img className="icon" src={fogueteIcon} alt="Raio" />
                        <h2>Patrulha – Cana-de-Açúcar</h2>
                        <h3>Prestador de serviço: Patrulha</h3>
                        <h4>Resgate a partir de: 93.000 Pontos Impulso Bayer</h4>
                        <ol>
                            <li>
                                • Visão geral da propriedade com nível de infestação de praga por talhão
                            </li>
                            <li>
                                • Indicação da quantidade e estágio das pragas encontradas
                            </li>
                            <li>
                                • Detalhe dos dados coletados em cada ponto amostral
                            </li>
                            <li>
                                • Histórico do talhão
                            </li>
                        </ol>
                        <p>
                            <strong>
                                O Patrulha é um serviço que oferece uma equipe especializada para apoiar o produtor no monitoramento da broca e da cigarrinha da cana, facilitando a sua tomada de decisão. Uma ferramenta digital e conectada, que disponibiliza o monitoramento do canavial na palma da mão e com um sistema compatível com Climate FieldView™.
                            </strong>
                        </p>
                        <Button className="bt"
                            onClick={() => {
                                sendEvent({ category: 'board-produtividade-resgatar', label: 'patrulha_cana', action: 'click' });
                            }}
                            target="_blank" rel="noopener"
                            href='https://www.orbia.ag/categoria/65168/patrulha?q=&s=0&fft=34&fc=69362&mprt=p'>
                            Resgatar agora!
                        </Button>

                        <Button
                            target="_blank"
                            href="https://wa.me/551142103591?text=Olá!%20Vim%20através%20do%20Espaço%20Bayer%20e%20gostaria%20de%20mais%20informações%20sobre%20serviços.%20"
                            rel="noopener"
                            className="bt especialista"
                            onClick={() => sendEvent({ category: 'board-produtividade-resgatar', label: 'especialista', action: 'click' })}
                        >
                            Falar com o especialista
                        </Button>
                        {/* <small>* De acordo com a compatibilidade das máquinas</small> */}
                        {/* <img className="box" src={boxImg} alt="Box" /> */}
                    </div>
                </div>
                <div className="area-image"></div>
                <Button className="bt-fechar climate produtividade" onClick={() => {
                    props.onClose();
                }}>
                    <HighlightOffIcon />
                </Button>
            </section>
        </>
    );
}