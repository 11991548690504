import React, { useEffect, useState, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { JOAO_PLANTACAO } from '../../constants/chatBot';
import { UserType } from '../../types/user';
import ico from '../../assets/attendantIco.svg'
import "./chat-plantacao.page.scss";
import { Button } from '@material-ui/core';
import { sendEvent } from '../../scripts/events';
import { FinalVideoService } from '../../services/final-video.service';
import { UserContext } from '../../components/userContext';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { MaisServicosService } from '../../services/mais-servicos.service';
import { MenuService } from '../../services/menu.service';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SalvarTarefaSubjectService } from '../../services/salvar-tarefa.service';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',

  headerBgColor: '#fff',
  headerFontColor: '#2c3a49',
  headerFontSize: '15px',

  botBubbleColor: '#3a6dd8',
  botFontColor: '#fff',

  userBubbleColor: '#93d131',
  userFontColor: '#fff',
};

const optionStyle = {
  backgroundColor: '#93d131',
  display: 'block',
};

const StyledChatBot = styled(ChatBot)`
  .rsc-content {
    height: calc(360px - 60px);
  }

  @media (max-width: 1024px) {
    .rsc-content {
      height: calc(360px - 100px);
    }
  }

  .rsc-footer {
    display: none;
  }
`;

export enum Attendant { Receptionist, Concierge };

export type ChatBotProps = {
  handleClose: ({ command, metadata }: { command: number, metadata?: any }) => void;
  onToggleFloating: () => void;
  user?: UserType;
  attendant: Attendant;
  btClicadoChat: (opt: number) => void;
}

const Chat = ({ user, attendant, btClicadoChat, handleClose, onToggleFloating }: ChatBotProps) => {
  const firstName = user ? (user.name ? user.name.split(' ')[0] : '') : '';

  const talk = (attendant: Attendant) => JOAO_PLANTACAO;

  const handleEnd = ({ steps, values }: { steps: any, values: [any] }) => {
    const lastAnswer = values.length > 0 ? values[values.length - 1] : undefined;
    let showFirst: number[] = [];

    if (lastAnswer) {

      if (lastAnswer.option === ROTAVIDEOS.especialista) {
        sendEvent({ category: 'chat_lavoura', label: 'especialista', action: 'click' });
        window.open('https://wa.me/551142103591?text=Olá!%20Vim%20através%20do%20Espaço%20Bayer%20e%20gostaria%20de%20mais%20informações%20sobre%20serviços.%20', '_blank')
      }

      if (lastAnswer.option === ROTAVIDEOS.assistir_webserie) {
        sendEvent({ category: 'chat_lavoura', label: 'web_series', action: 'click' });
        if (localStorage.getItem('tarefaTipo') === 'Tarefa 7') {
          setTimeout(() => {
            SalvarTarefaSubjectService.sendSalvar(true);
          }, 5000);
        }
        btClicadoChat(lastAnswer.option);
      }

    }

    handleClose({ command: lastAnswer.option, metadata: { showFirst } });
  };

  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <StyledChatBot
          handleEnd={handleEnd}
          className={'custom-chat-bot chat-plantacao ' + talk(attendant).attendant}
          botAvatar={ico}
          headerTitle={talk(attendant).attendant}
          hideSubmitButton={true}
          hideUserAvatar={true}
          botDelay={2000}
          height={'360px'}
          floating={true}
          opened={true}
          steps={talk(attendant).talk}
          bubbleOptionStyle={optionStyle}
          toggleFloating={() => {
            onToggleFloating();
          }}
        />
      </React.StrictMode>
    </ThemeProvider>
  )
}

export default function ChatPlantacaoPage() {

  const [attendant, setAttendant] = useState(Attendant.Concierge);
  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const userProps = useContext(UserContext);
  const [animaPage, setAnimaPage] = React.useState<boolean>(false);
  const [modalVideo, setModalVideo] = React.useState<boolean>(true);

  useEffect(() => {
    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('foxXproPlantacao') > -1) {
        LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.foxXproPlantacao);
      }
    });

    setTimeout(() => {
      //setShowBolinhas(true);
    }, 2500);

    document.body.classList.add("page-fox-xpro-plantacao");

    return () => {
      document.body.classList.remove("page-fox-xpro-plantacao");
    }
  }, []);

  return (
    <>
      {showBolinhas && (
        <Chat
          onToggleFloating={() => {
            //props.onCloseX
            LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
          }}
          handleClose={() => {
            //
          }}
          user={userProps.user}
          attendant={attendant}
          btClicadoChat={(opt) => {
            setAnimaPage(true);
          }}
        />
      )}

      {
        modalVideo &&
        <div className={"box-video-plantacao flex flex-cv flex-ch ativo-" + modalVideo}>
          <iframe allowFullScreen frameBorder={0} width="560" height="315" src="https://www.youtube.com/embed/7iz88JMyaVk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          <Button className="bt-fechar" onClick={() => {
            setShowBolinhas(true);
            setModalVideo(false);
          }}>
            <HighlightOffIcon />
          </Button>
        </div>
      }

      {
        animaPage &&
        <section className={"plantacao flex flex-cv flex-ch anima-" + animaPage}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/videoseries?list=PLxBxiy5rB4sc52StBYmXdRb05o6V4A4HT"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          >
          </iframe>
          <Button className="bt-fechar" onClick={() => {
            setAnimaPage(false);
          }}>
            <HighlightOffIcon />
          </Button>
        </section>
      }
    </>
  );
}
