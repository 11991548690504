import React, { useEffect, useState, useRef } from 'react';
import "./agenda.page.scss";

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import logoAgenda from "../../assets/agenda/logo-agenda.png";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { sendEvent } from '../../scripts/events';
import { GetEspacoBayerADMService } from '../../services/api.service';
import ModalConvidadoComponent from '../../components/modal-convidado/modal-convidado.component';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { FinalVideoService } from '../../services/final-video.service';
import ChatLivePage from '../chat-live/chat-live.page';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';

interface Column {
  id: 'status' | 'live' | 'date' | 'hour' | 'theme' | 'anfitriao';
  label: string;
  minWidth?: number;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'status', label: 'Status', minWidth: 50, align: 'center' },
  { id: 'live', label: 'Evevento', minWidth: 170 },
  { id: 'date', label: 'Data', minWidth: 70, align: 'center' },
  { id: 'hour', label: 'Horário', minWidth: 60, align: 'center' },
  { id: 'theme', label: 'Tema', minWidth: 120, align: 'center' },
  { id: 'anfitriao', label: 'Anfitrião', minWidth: 100 },
];

interface Data {
  live: string;
  anfitriao: string;
  senha_da_live: string;
  tamanho_fonte_live: string;
  theme: string;
  date: DateAndWeekDay;
  hour: string;
  liveid?: string;
  livecommerce?: string;
  iframe?: string;
  passcode?: string;
  status?: number;
  name: string;
  speaker: string;
  link_da_meeting: string;
  desafio_premiado: string;
  texto_cta_carol: string;
  link_cta_carol: string;
  texto_link_cta_carol: string;
  link_cta_carol_2: string;
  texto_link_cta_carol_2: string;
}

interface DateAndWeekDay {
  date: string;
  weekDay: string;
}

function createData({ live, anfitriao, senha_da_live, tamanho_fonte_live, theme, date, hour, liveid, livecommerce, iframe, passcode, status, name, speaker, link_da_meeting, desafio_premiado, texto_cta_carol, link_cta_carol, texto_link_cta_carol, link_cta_carol_2, texto_link_cta_carol_2 }:
  { live: string, anfitriao: string, senha_da_live: string, tamanho_fonte_live: string, theme: string, date: string, hour: string, liveid?: string, livecommerce?: string, iframe?: string, passcode?: string, status?: number, name?: string, speaker?: string, link_da_meeting?: string, desafio_premiado?: string, texto_cta_carol?: string, link_cta_carol?: string, texto_link_cta_carol?: string, link_cta_carol_2?: string, texto_link_cta_carol_2?: string }): Data {
  const weekDayNames = ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'];
  const pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

  try {
    const arrayDate = date.match(pattern);
    const [, dia, mes, ano] = arrayDate;
    var dt = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));
    const weekDay = weekDayNames[dt.getDay()];
    return { live, anfitriao, senha_da_live, tamanho_fonte_live, theme, date: { date, weekDay }, hour, liveid, livecommerce, iframe, passcode, status, name, speaker, link_da_meeting, desafio_premiado, texto_cta_carol, link_cta_carol, texto_link_cta_carol, link_cta_carol_2, texto_link_cta_carol_2 };
  } catch {
    return { live, anfitriao, senha_da_live, tamanho_fonte_live, theme, date: { date, weekDay: '' }, hour, liveid, livecommerce, iframe, passcode, status, name, speaker, link_da_meeting, desafio_premiado, texto_cta_carol, link_cta_carol, texto_link_cta_carol, link_cta_carol_2, texto_link_cta_carol_2 };
  }
}

const rows = (liveSchedule: any[]) => {
  if (liveSchedule.length > 0) {
    return liveSchedule.map((item) => createData({ ...item }))
  }
  return [];
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export default function AgendaPage() {
  const [liveSchedule, setLiveSchedule] = useState([]);
  const [animationActive, setAnimationActive] = useState<boolean>(false);

  const [nomeConvidadoModal, setNomeConvidadoModal] = React.useState<string>('');
  const [isPublic, setIsPublic] = React.useState<boolean>(false);

  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showChat, setShowChat] = React.useState<boolean>(false);

  const tableRow1Ref = useRef(null);

  useEffect(() => {

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf(ROTAVIDEOS.reunioes) > -1 || window.location.href.indexOf(ROTAVIDEOS.lives) > -1 || window.location.href.indexOf(ROTAVIDEOS.expodireto) > -1) {
        setShowBolinhas(true);
        setTimeout(() => {
          setAnimationActive(true);
          GetEspacoBayerADM();
        }, 100);
      }
    });

  }, []);

  const GetEspacoBayerADM = () => {
    LoadingService.sendLoadingAtivo(true);
    GetEspacoBayerADMService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        localStorage.setItem('admWP', JSON.stringify(res.data));
        setLiveSchedule(res.data.itens_live);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      if (err.response?.status === 401) {
        SnackService.sendMSG('ERRO 401');
      } else {
        SnackService.sendMSG('Erro com a conexão ao verificar ADM.');
      }
    })
  }


  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isShowModal, setIsShowModal] = React.useState<boolean>(true);
  const [showModalConvidado, setShowModalConvidado] = React.useState<boolean>(false);
  const [liveItemClicada, setLiveItemClicada] = React.useState<any>(null);
  const [abriuRow1, setAbriuRow1] = React.useState<boolean>(false);


  const closeModal = () => {
    setIsShowModal(false);
    if (window.innerWidth < 1024 && localStorage.getItem('urlFrom') === 'homeReunioes') {
      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeReunioes);
    } else {
      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
    }
  }

  const closeModalConvidado = () => {
    setShowModalConvidado(false);
  }

  const openModal = () => {
    setIsShowModal(true);
  }

  const entrarConvidado = (nomeConvidadoModal: string) => {
    setNomeConvidadoModal(nomeConvidadoModal);
    setShowChat(true);
  }

  return (
    <>
      {
        showBolinhas &&
        <>
          <section className={"agenda" + " animation-" + animationActive}>
            <div className={"modal-agenda flex flex-ch flex-cv " + (isShowModal && "active")}>
              <div className="box-agenda" onClick={openModal}>
                <div className="filtro flex flex-bv flex-sp">
                  <h1 className="flex flex-cv flex-wr">
                    <img src={logoAgenda} alt="Logo agenda" />
                    <span>
                      Agenda
                      <small>
                        Confira aqui a nossa programação de eventos!
                      </small>
                    </span>
                  </h1>
                  <h3>
                    <small>
                      Faça seu evento no Espaço Bayer! <a href="https://painelespacobayer.online/" target="_blank">Clique aqui.</a>
                    </small>
                  </h3>
                </div>
                <div className="area-table">
                  <PerfectScrollbar>
                    <TableContainer className={classes.container}>
                      <Table aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows(liveSchedule).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, indexRow) => (
                            <TableRow hover
                              role="checkbox"
                              tabIndex={-1}
                              ref={() => {
                                if ((window.location.href.indexOf(ROTAVIDEOS.expodireto) > -1 && indexRow === 0 && !abriuRow1) && (row['status'] === 1 || row['status'] === 3)) {
                                  setAbriuRow1(true);
                                  setTimeout(() => {
                                    if (row['live'] === 'Live pública' || row['live'] === 'Live para convidados') {
                                      sendEvent({ category: 'agenda', label: 'Agenda_live', action: 'click' });
                                      if (row.live.indexOf('convidado') >= 0) {
                                        setLiveItemClicada(row);
                                        setIsPublic(false);
                                        setShowModalConvidado(true);
                                      } else {
                                        setLiveItemClicada(row);
                                        setIsPublic(true);
                                        setShowModalConvidado(true);
                                      }
                                    } else {
                                      sendEvent({ category: 'agenda', label: 'Agenda_meeting', action: 'click' });
                                      window.open(
                                        row.link_da_meeting,
                                        '_blank'
                                      );
                                    }
                                  }, 500);
                                }
                              }}
                              key={indexRow}
                              className={(row['status'] === 1 || row['status'] === 3) && 'online'}
                              onClick={(row['status'] === 1 || row['status'] === 3) && (() => {
                                if (row['live'] === 'Live pública' || row['live'] === 'Live para convidados') {
                                  sendEvent({ category: 'agenda', label: 'Agenda_live', action: 'click' });
                                  if (row.live.indexOf('convidado') >= 0) {
                                    setIsPublic(false);
                                    setShowModalConvidado(true);
                                    setLiveItemClicada(row);
                                  } else {
                                    setIsPublic(true);
                                    setShowModalConvidado(true);
                                    setLiveItemClicada(row);
                                  }
                                } else {
                                  sendEvent({ category: 'agenda', label: 'Agenda_meeting', action: 'click' });
                                  window.open(
                                    row.link_da_meeting,
                                    '_blank'
                                  );
                                }

                              })}>

                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                //style={{ fontSize: (column.id === 'theme' && row['tamanho_fonte_live']) ? row['tamanho_fonte_live'] + 'px' : '14px' }}
                                >
                                  {column.id === 'date' && (
                                    <>
                                      {row['date'].date}
                                      <small>{row['date'].weekDay}</small>
                                    </>
                                  )}
                                  {column.id === 'status' && (
                                    row[column.id] === 1 ?
                                      <div className="status on"><b></b>online</div>
                                      :
                                      row[column.id] === 2 ?
                                        <div className="status off"><b></b>offline</div>
                                        :
                                        <div className="status on"><b></b>gravada</div>
                                  )}
                                  {column.id !== 'date' && column.id !== 'status' && (
                                    row[column.id]
                                  )}
                                </TableCell>)
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </PerfectScrollbar>
                </div>
                {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
                <Button onClick={closeModal} className="bt-fechar">
                  <HighlightOffIcon />
                </Button>
              </div>
            </div>
          </section>
          {
            showModalConvidado &&
            <ModalConvidadoComponent
              entrarConvidado={(nome) => {
                entrarConvidado(nome);
                setShowModalConvidado(false);
              }}
              isPublic={isPublic}
              senhaADM={liveItemClicada.senha_da_live}
              onClose={closeModalConvidado}
            />
          }

          {
            showChat &&
            <ChatLivePage
              userConvidado={nomeConvidadoModal}
              liveItem={liveItemClicada}
              onClose={() => {
                setShowChat(false);
              }}
              onLogin={() => {
                //handleNavigation
              }}
            />
          }
        </>
      }
    </>
  );
}
