
export const ROTAVIDEOS = {
    'entrada': 'entrada',
    'entradaInferior': 'entradaInferior',
    'home': 'home',
    'showRural': 'showRural',
    'expodireto': 'expodireto',
    'agrobrasilia': 'agrobrasilia',
    'tecnoshow': 'tecnoshow',
    'feira': 'feira',
    'homeInferior': 'homeInferior',
    'homeReunioes': 'homeReunioes',
    'homeRisco': 'homeRisco',
    'inovacao': 'inovacao',
    'produtividade': 'produtividade',
    'risco': 'risco',
    'totem': 'totem',
    'voltarRisco': 'voltarRisco',
    'reunioes': 'reunioes',
    'negocios': 'negocios',
    'clima': 'clima',
    'forum': 'forum',
    'lives': 'lives',
    'chatCarol': 'chatCarol',
    'homeSuperior': 'homeSuperior',
    'foxXpro': 'foxXpro',
    'foxXproPlantacao': 'foxXproPlantacao',
    'maisServicos': 'maisServicos',
    'desafios': 'desafios',
    'especialista': 'especialista',
    'assistir_webserie': 'assistir_webserie',
    'sobreBayer': 'sobreBayer'
}