import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import "./minhas-tarefas.component.scss";
import { useSelect } from '../../hooks/useSelect.hook';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import logo from "../../assets/menu/logo-espaco-bayer.png";
import { ListarTarefasService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import MinhasTarefasModel from './minhas-tarefas.model';
import CupomModel from './cupom.model';

export type MinhasTarefasComponentProps = {
    showTarefas: boolean;
    email: string;
    onClose: () => void;
}

export default function MinhasTarefasComponent(props: MinhasTarefasComponentProps) {

    const [showTarefas, setShowTarefas] = React.useState(props.showTarefas);
    const [showCupom, setShowCupom] = React.useState(false);
    const [cupom, setCupom] = React.useState<CupomModel>();

    const [itensTarefas, setItensTarefas] = React.useState<MinhasTarefasModel[]>();

    useEffect(() => {
        ListarTarefas();
    }, []);

    const ListarTarefas = () => {
        LoadingService.sendLoadingAtivo(true);
        ListarTarefasService(props.email).then(res => {
            LoadingService.sendLoadingAtivo(false);
            if (res.data.error) {
                SnackService.sendMSG(res.data.error);
            } else {
                setItensTarefas(res.data);
            }
        }).catch(err => {
            LoadingService.sendLoadingAtivo(false);
            if (err.response?.status === 401) {
                SnackService.sendMSG('ERRO 401');
            } else {
                SnackService.sendMSG('Erro com a conexão ao verificar ADM.');
            }
        })
    }

    const {
        value: filtro,
        setValue: setFiltro,
        bind: bindFiltro,
        IsOpenSelec: isOpenFiltro,
        setClose: setCloseFiltro
    } = useSelect(1);

    const onChangeFiltro = (valFiltro?: any) => {
        setFiltro(valFiltro);
        setCloseFiltro();
    };

    const fecharTarefas = () => {
        setShowTarefas(false);
        props.onClose();
    };

    return (
        <>
            <section className={"minhas-tarefas " + (showTarefas && 'ativo')}>
                <div className="filtro flex flex-sp flex-cv">
                    <h2>Minhas tarefas</h2>
                    {/* <FormControl>
                        <InputLabel>Filtrar</InputLabel>
                        <Select
                            {...bindFiltro}
                            onChange={(event) => onChangeFiltro(event.target.value)}
                            open={isOpenFiltro}
                        >
                            <MenuItem value={1}>30 dias atrás</MenuItem>
                            <MenuItem value={2}>60 dias atrás</MenuItem>
                            <MenuItem value={3}>90 dias atrás</MenuItem>
                            <MenuItem value={4}>120 dias atrás</MenuItem>
                        </Select>
                    </FormControl> */}
                </div>
                <div className="itens">
                    {
                        itensTarefas?.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="col tarefa_desc">
                                    {item.tarefa}
                                </div>
                                <div className="col tarefa">
                                    {item.descricao_tarefa}
                                </div>
                                <div className="col status ativo"></div>
                                <div className="col data">
                                    {item.data}
                                </div>
                                <Button className="ativo" onClick={() => {
                                    setCupom({
                                        cupom: item.cupom,
                                        pontos: item.pontos
                                    });
                                    setShowCupom(true);
                                }}>Ver meu cupom</Button>
                            </div>
                        ))
                    }
                    {
                        itensTarefas?.length === 0 &&
                        <p>
                            Nenhuma tarefa encontrada.
                        </p>
                    }
                </div>

                <Button className="bt-fechar" onClick={fecharTarefas}>
                    <HighlightOffIcon />
                </Button>
            </section>
            <div className={"modal-cupom flex flex-ch flex-cv " + (showCupom && 'ativo')}>
                <div className="box">
                    <h2>
                        cupom <img src={logo} alt="Logo" />
                    </h2>
                    <div className="cols flex">
                        <div className="col esq flex flex-cv">
                            <h3>
                                {cupom?.pontos.toLocaleString('pt-br', { minimumFractionDigits: 0 })}
                                <span>Pontos Impulso Bayer</span>
                            </h3>
                        </div>
                        <div className="col dir flex flex-cv">
                            <div className="txt">
                                <p>
                                    Copie e cole esse código <br />
                                    no carrinho do Orbia:
                                </p>
                                <h4>
                                    {cupom?.cupom}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <Button className="bt-fechar" onClick={() => { setShowCupom(false); }}>
                        <HighlightOffIcon />
                    </Button>
                </div>
            </div>
        </>
    );
}