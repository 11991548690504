import React, { createContext } from 'react';
import { UserType } from '../../types/user';
import { useUser } from './useUser';

type ContextProps = {
  user: UserType,
  logout: () => any,
  updateUser: ({ UID, provider, email }: { UID: string; provider: string; email: string }) => any,
};

export const UserContext = createContext<Partial<ContextProps>>({
  user: null,
  logout: () => new Promise(() => {}),
  updateUser: ({ UID, provider, email }: { UID: string; provider: string; email: string }) => new Promise(() => {}),
});

export const UserProvider = ({ children }:{ children: any}) => {
  const userContext = useUser();

  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
};
