import React, { useEffect, useState } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import { sendEvent } from '../../scripts/events';

import { Button, Menu, MenuItem } from '@material-ui/core';
import HoverMessage from '../../components/hover-message/hover-message.component';
import { MenuService } from '../../services/menu.service';
import impulsoLogo from "../../assets/menu/impulso-logo.png";

import './home-superior.page.scss';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { FinalVideoService } from '../../services/final-video.service';
import { MaisServicosService } from '../../services/mais-servicos.service';
import HomeSuperior360Component from './home-superior360/home-superior360.component';


export interface MainMenuScreenProps {
  openPage: ({ command }: { command: number }) => void;
  setTutorialVisible: (tutorialVisible: boolean) => void;
}

export default function HomeSuperiorPage(props: MainMenuScreenProps) {
  const [, setButtonsEnabled] = useState(false);
  const [message, setMessage] = useState('');
  const handleMouse = (message: string) => setMessage(message);

  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showDesktop, setShowDesktop] = React.useState<boolean>(true);
  const [burgerAtivo, setBurgerAtivo] = React.useState<boolean>(false);

  useEffect(() => {
    setButtonsEnabled(true);

    if (window.innerWidth < 1024) {
      setShowDesktop(false);
    }

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf(ROTAVIDEOS.homeSuperior) > -1) {
        setShowBolinhas(true);
        document.body.classList.add("show-powered");
        document.body.classList.add("home-superior");
        document.body.classList.add("loaded");
      }
    });

    return () => {
      document.body.classList.remove("show-powered");
      document.body.classList.remove("home-superior");
    }
  }, []);

  const [openMenuServicos, setopenMenuServicos] = useState(false);
  const [anchorElServicos, setanchorElServicos] = React.useState<null | HTMLElement>(null);
  const handleClickMenuEventos = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElServicos(event.currentTarget);
    setopenMenuServicos(true);
  };
  const handleCloseMenuServicos = () => {
    setanchorElServicos(null);
    setopenMenuServicos(false);
  };

  return (
    <>
      {
        showBolinhas &&
        <>

          {
            showDesktop ?
              <>
                <section className="main-menu">
                  <Button className="bolinhas inovation"></Button>
                </section>

                <div className="main-menu-hud">
                  <FullscreenHud>
                    <FullscreenHudElement key="configs" highlight={assets.empty} >
                      <>
                        <defs>
                          <filter id="blur">
                            <feGaussianBlur stdDeviation="4" />
                          </filter>
                        </defs>
                      </>
                    </FullscreenHudElement>

                    {/* Produtividade  */}
                    <FullscreenHudElement key="produtivity" highlight={assets.empty}>
                      <>
                        <circle cx="180" cy="400" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="180" cy="400" r="10" className='circle-button borda' />
                        <circle cx="180" cy="400" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="200" y="380" width="150" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="210" y="405" className="legenda-mobile" >Produtividade</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-produtivity" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home_superior', label: 'Home_ptprod', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.produtividade);
                    }} >
                      <rect x='100' y='330' width='150' height='150'
                        onMouseOver={() => handleMouse('Conheça os serviços para ter mais produtividade')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim produtividade */}

                    {/* Risco */}
                    <FullscreenHudElement key="risk" highlight={assets.empty}>
                      <>
                        <circle cx="680" cy="400" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="680" cy="400" r="10" className='circle-button borda' />
                        <circle cx="680" cy="400" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="700" y="380" width="100" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="725" y="405" className="legenda-mobile" >Risco</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-risk" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home_superior', label: 'Home_ptrisco', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.risco);
                    }}>
                      <rect x='600' y='330' width='150' height='150'
                        onMouseOver={() => handleMouse('Conheça os serviços para minimizar os riscos do seu negócio')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim risco */}

                    {/* Negocios */}
                    <FullscreenHudElement key="bussiness" highlight={assets.empty}>
                      <>
                        <circle cx="1000" cy="400" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="1000" cy="400" r="10" className='circle-button borda' />
                        <circle cx="1000" cy="400" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="1020" y="380" width="110" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="1030" y="405" className="legenda-mobile" >Negócios</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-bussiness" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home_superior', label: 'Home_ptnegocio', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.negocios);
                    }}>
                      <rect x='930' y='320' width='150' height='150'
                        onMouseOver={() => handleMouse('Conheça os serviços para aprimorar a gestão do seu negócio')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim Negocios */}

                    {/* Inovação */}
                    <FullscreenHudElement key="inovation" highlight={assets.empty}>
                      <>
                        <circle cx="1500" cy="400" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="1500" cy="400" r="10" className='circle-button borda' />
                        <circle cx="1500" cy="400" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="1530" y="380" width="150" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="1560" y="405" className="legenda-mobile" >Inovação</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-inovation" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home_superior', label: 'Home_ptinova', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.inovacao);
                    }} >
                      <rect
                        onMouseOver={() => handleMouse('Conheça os serviços para levar inovação para o seu negócio')}
                        onMouseOut={() => handleMouse('')}
                        x='1420' y='320' width='150' height='150' />
                    </FullscreenHudElement>
                    {/* Fim inovação */}

                    {/* Fox Xpro */}
                    <FullscreenHudElement key="xpro" highlight={assets.empty}>
                      <>
                        <circle cx="1000" cy="600" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                        <circle cx="1000" cy="600" r="10" className='circle-button borda' />
                        <circle cx="1000" cy="600" r="10" className='circle-button' strokeWidth='10px' />
                        <rect x="1020" y="580" width="110" height="40" className="legenda-mobile-bg" rx="20" ry="20"></rect>
                        <text x="1035" y="605" className="legenda-mobile" >Fox Xpro</text>
                      </>
                    </FullscreenHudElement>
                    <FullscreenHudElement key="opt-xpro" highlight={assets.empty} onClick={() => {
                      sendEvent({ category: 'home_superior0', label: 'Home_ptxpro', action: 'click' });
                      LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.foxXpro);
                    }}>
                      <rect x='930' y='520' width='150' height='150'
                        onMouseOver={() => handleMouse('FOX XPRO: Ter confiança é ter proteção e resultado para soja e milho!')}
                        onMouseOut={() => handleMouse('')}
                      />
                    </FullscreenHudElement>
                    {/* Fim Fox Xpro */}

                  </FullscreenHud>
                </div>

                <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
                  <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
                </div>

                <div className="fot-logo flex flex-ch">
                  <div className="box flex flex-cv">
                    <span>Powered by</span> <img src={impulsoLogo} alt="Impulso Logo" />
                  </div>
                </div>
              </>
              :
              <>
                <HomeSuperior360Component />
              </>
          }

          <div className="menu-foot">

          <Button className="bt piso-superior-mobile" onClick={() => {
              sendEvent({ category: 'home', label: 'menu_foot_superior', action: 'click' });
              LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
            }}>
              Térreo
            </Button>

            <ul className={"burger-" + burgerAtivo}>
              <li>
                <Button
                  aria-controls="servicos"
                  aria-haspopup="true"
                  className="bt"
                  onClick={handleClickMenuEventos}
                >Serviços</Button>
                <Menu
                  id="servicos"
                  anchorEl={anchorElServicos}
                  open={openMenuServicos}
                  onClose={handleCloseMenuServicos}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => {
                    setopenMenuServicos(false);
                    sendEvent({ category: 'home_superior', label: 'menu_foot_produtividade', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.produtividade);
                  }}>Produtividade</MenuItem>
                  <MenuItem onClick={() => {
                    setopenMenuServicos(false);
                    sendEvent({ category: 'home_superior', label: 'menu_foot_risco', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.risco);
                  }}>Risco</MenuItem>
                  <MenuItem onClick={() => {
                    setopenMenuServicos(false);
                    sendEvent({ category: 'home_superior', label: 'menu_foot_negocios', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.negocios);
                  }}>Negócios</MenuItem>
                  <MenuItem onClick={() => {
                    setopenMenuServicos(false);
                    sendEvent({ category: 'home_superior', label: 'menu_foot_inova', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.inovacao);
                  }}>Inovação</MenuItem>

                  <MenuItem onClick={() => {
                    setopenMenuServicos(false);
                    sendEvent({ category: 'home_superior', label: 'menu_foot_mais_servicos', action: 'click' });
                    MaisServicosService.send(true);
                  }}>Mais serviços</MenuItem>
                </Menu>
              </li>

              <li>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home_superior', label: 'menu_foot_fox', action: 'click' });
                  LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.foxXpro);
                }}>
                  Fox Xpro
                </Button>
              </li>

              {/* <li>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home_superior', label: 'menu_foot_desafios', action: 'click' });
                  MenuService.sendAtivo(true);
                  setBurgerAtivo(false);
                }}>
                  Desafios premiados
                </Button>
              </li> */}

              <li className='piso-superior'>
                <Button className="bt" onClick={() => {
                  sendEvent({ category: 'home_superior', label: 'menu_foot_terreo', action: 'click' });
                  LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
                }}>
                  Térreo
                </Button>
              </li>
            </ul>

            <button
              onClick={() => {
                setBurgerAtivo(!burgerAtivo);
              }}
              className={"hamburger hamburger--squeeze " + (burgerAtivo ? 'is-active' : '')}
              //className={"hamburger hamburger--3dxy "}
              type="button"
            >
              <strong>Menu</strong>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </>
      }
    </>
  );
}
