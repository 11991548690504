import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import "./entrada.page.scss";
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { FinalVideoService } from '../../services/final-video.service';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import { sendEvent } from '../../scripts/events';
import HoverMessage from '../../components/hover-message/hover-message.component';

export interface EntradaPageProps {
    onEntrar?: () => void;
}

export default function EntradaPage(props: EntradaPageProps) {

    const [showBTintro, setShowBTintro] = React.useState<boolean>(true);
    const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState('');


    useEffect(() => {

        document.body.classList.add("entrada");
        document.body.classList.add("loaded");

        FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
            if (window.location.href.indexOf(ROTAVIDEOS.entrada) > -1) {
                setShowBolinhas(true);
                setShowBTintro(false);
                document.body.classList.add("show-powered");
            }
        });

        props.onEntrar();

        return () => {
            document.body.classList.remove("entrada");
            document.body.classList.remove("loaded");
            document.body.classList.remove("show-powered");
        }
    }, []);

    const handleMouse = (message: string) => setMessage(message);

    const PularIntroducao = () => {
        LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
    }

    return (
        <>
            {
                showBolinhas &&
                <>
                    <FullscreenHud>
                        <FullscreenHudElement key="configs" highlight={assets.empty} >
                            <>
                                <defs>
                                    <filter id="blur">
                                        <feGaussianBlur stdDeviation="4" />
                                    </filter>
                                </defs>
                            </>
                        </FullscreenHudElement>

                        <FullscreenHudElement key="opt-1" highlight={assets.empty} >
                            <>
                                <circle cx="850" cy="700" r="10" className='circle-button' strokeWidth='10px' />
                                <circle cx="850" cy="700" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                                <circle cx="850" cy="700" r="10" className='circle-button borda' />
                            </>
                        </FullscreenHudElement>
                        <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
                            sendEvent({ category: 'entrada', label: 'entrada_pt_piso_superior', action: 'click' });
                            LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.entradaInferior);
                        }}>
                            <rect x='720' y='600' width='250' height='200'
                                onMouseOver={() => handleMouse('Piso inferior.')}
                                onMouseOut={() => handleMouse('')}
                            />
                        </FullscreenHudElement>

                        <FullscreenHudElement key="opt-3" highlight={assets.empty} >
                            <>
                                <circle cx="1000" cy="450" r="10" className='circle-button' strokeWidth='10px' />
                                <circle cx="1000" cy="450" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                                <circle cx="1000" cy="450" r="10" className='circle-button borda' />
                            </>
                        </FullscreenHudElement>
                        <FullscreenHudElement key="opt-2" highlight={assets.empty} onClick={() => {
                            sendEvent({ category: 'entrada', label: 'entrada_pt_piso_inferior', action: 'click' });
                            LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
                        }}>
                            <rect x='880' y='350' width='250' height='200'
                                onMouseOver={() => handleMouse('Piso superior.')}
                                onMouseOut={() => handleMouse('')}
                            />
                        </FullscreenHudElement>

                    </FullscreenHud>

                    <div className="menu-foot">
                        <ul>
                            <li>
                                <Button className="bt" onClick={() => {
                                    sendEvent({ category: 'entrada', label: 'menu_foot_terreo', action: 'click' });
                                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.entradaInferior);
                                }}>
                                    Térreo
                                </Button>
                            </li>
                            <li>
                                <Button className="bt" onClick={() => {
                                    sendEvent({ category: 'entrada', label: 'menu_foot_superior', action: 'click' });
                                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
                                }}>
                                    Piso superior
                                </Button>
                            </li>
                        </ul>
                    </div>

                    <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
                        <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
                    </div>
                </>
            }

            {
                showBTintro &&
                <div className="flex flex-ch bt-intro">
                    {/* <Button className="bt" onClick={PularIntroducao}>
                        <ArrowForwardIcon />
                        Pular introdução
                    </Button> */}
                </div>
            }
        </>
    );
}