import React, { useEffect } from 'react';
import "./home-superior360-risco.component.scss";
import * as PANOLENS from "panolens";
import * as THREE from "three";

import imgPisoSuperiorRisco from '../../../assets/panolens/piso-superior-risco.jpg';
import imgInfoSpot from '../../../assets/panolens/info-spot.png';
import imgInfoSpotPiso from '../../../assets/panolens/pt-chao.png';
import { SnackService } from '../../../services/snack.service';
import { sendEvent } from '../../../scripts/events';
import { LoadVideoBgService } from '../../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../../constants/rotas-videos.constant';

import icoPanoOff from '../../../assets/panolens/ico-pano-off.svg';
import icoPanoOn from '../../../assets/panolens/ico-pano-on.svg';
import { Button } from '@material-ui/core';
import { Ativa360Service } from '../../../services/ativa360.service';

const panoramaSuperiorRisco = new PANOLENS.ImagePanorama(imgPisoSuperiorRisco);

const viewerSuperiorRisco = new PANOLENS.Viewer({
    container: document.querySelector("#home-superior-risco"),
    controlBar: true,
    autoHideInfospot: false
});

viewerSuperiorRisco.add(panoramaSuperiorRisco);
viewerSuperiorRisco.getCamera().fov = 120;
viewerSuperiorRisco.getCamera().updateProjectionMatrix();
//viewerSuperiorRisco.OrbitControls.noZoom = true;
// posição inicial camera
viewerSuperiorRisco.camera.position.set(-0.37762263011158637, 0.1075657714400121, 0.91969057516217);
setTimeout(() => {
    (document.querySelectorAll(".panolens-container")[3]).classList.add('risco');
}, 500);

let x = 50;
let y = -130;
let z = -150;

const createInfoSpotSuperiorRisco = (pos: any, img: any, hoverTxt: string, send_event: any, nome_video: any) => {
    let position = new THREE.Vector3(pos.x, pos.y, pos.z);
    let infospot = new PANOLENS.Infospot(30, img, false);
    infospot.position.copy(position);
    infospot.addHoverText(hoverTxt);
    infospot.addEventListener('click', () => {
        sendEvent(send_event);
        localStorage.setItem('urlFrom', 'homeRisco')
        LoadVideoBgService.sendNomeVideo(nome_video);
    });
    panoramaSuperiorRisco.add(infospot);
}

// Produtividade
createInfoSpotSuperiorRisco(
    { x: 290, y: 30, z: -30 },
    imgInfoSpot,
    'Produtividade',
    { category: 'home_visao_risco', label: 'menu_pts_produtividade', action: 'click' },
    ROTAVIDEOS.produtividade
);

// Risco
createInfoSpotSuperiorRisco(
    { x: 50, y: -10, z: -210 },
    imgInfoSpot,
    'Risco',
    { category: 'home_visao_risco', label: 'menu_pts_risco', action: 'click' },
    ROTAVIDEOS.risco
);

// Negócios
createInfoSpotSuperiorRisco(
    { x: -190, y: -10, z: -90 },
    imgInfoSpot,
    'Negócios',
    { category: 'home_visao_risco', label: 'menu_pts_negocios', action: 'click' },
    ROTAVIDEOS.negocios
);

// Inovação
createInfoSpotSuperiorRisco(
    { x: -250, y: -10, z: 150 },
    imgInfoSpot,
    'Inovação',
    { category: 'home_visao_risco', label: 'menu_pts_inovacao', action: 'click' },
    ROTAVIDEOS.inovacao
);

// Fox Xpro
createInfoSpotSuperiorRisco(
    { x: -10, y: -10, z: 270 },
    imgInfoSpot,
    'Fox Xpro',
    { category: 'home_visao_risco', label: 'menu_pts_foxXpro', action: 'click' },
    ROTAVIDEOS.foxXpro
);

// Visão Home Superior
// createInfoSpotSuperiorRisco(
//     { x: 50, y: -210, z: 90 },
//     imgInfoSpotPiso,
//     'Visão superior',
//     { category: 'home_visao_risco', label: 'menu_pts_home_superior', action: 'click' },
//     ROTAVIDEOS.homeSuperior
// );

let positionPadrao = new THREE.Vector3(210, -80, -210);
let infospotPadrao = new PANOLENS.Infospot(30, imgInfoSpotPiso, false);
infospotPadrao.position.copy(positionPadrao);
infospotPadrao.addHoverText('Infospot padrao');
infospotPadrao.addEventListener('click', () => { });
// panoramaSuperiorRisco.add(infospotPadrao);

var UA = navigator.userAgent;

export default function Home360RiscoComponent() {

    const [isAndroid, setIsAndroid] = React.useState<boolean>(/\b(Android|iPhone|Windows Phone|iPad|iPod|BlackBerry|webOS)\b/i.test(UA));
    const [isIos, setIsIos] = React.useState<boolean>(/\b(iPhone|iPad|iPod)\b/i.test(UA));
    const [toggleOculus, setToggleOculus] = React.useState<boolean>(Ativa360Service.get360Ativo());

    useEffect(() => {
        document.body.classList.add("home-risco");
        document.body.classList.add("show-powered");

        if (toggleOculus) {
            viewerSuperiorRisco.enableControl(1);
            viewerSuperiorRisco.enableEffect( PANOLENS.MODES.CARDBOARD);
        } else {
            viewerSuperiorRisco.enableControl(0);
            viewerSuperiorRisco.enableEffect( PANOLENS.MODES.NORMAL);
        }

        return () => {
            document.body.classList.remove("show-powered");
            document.body.classList.remove("home-risco");
        }
    }, []);

    document.addEventListener('keydown', function (e: any) {
        //console.log(e.keyCode);
        switch (e.keyCode) {
            case 65:
                //alert('left'); ---- A
                x -= 10;
                positionPadrao = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospotPadrao.position.copy(positionPadrao);
                break;
            case 68:
                //alert('right'); ---- D
                x += 10;
                positionPadrao = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospotPadrao.position.copy(positionPadrao);
                break;
            case 87:
                //alert('up'); ---- W
                y += 10;
                positionPadrao = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospotPadrao.position.copy(positionPadrao);
                break;
            case 83:
                //alert('down'); ---- S
                y -= 10;
                positionPadrao = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospotPadrao.position.copy(positionPadrao);
                break;
            case 90:
                //alert('profundidade'); ---- Z
                z += 10;
                positionPadrao = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospotPadrao.position.copy(positionPadrao);
                break;
            case 88:
                //alert('profundidade'); ---- X
                z -= 10;
                positionPadrao = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospotPadrao.position.copy(positionPadrao);
                console.log(positionPadrao);
                break;
            case 84:
                //alert('profundidade'); ---- T
                // tecla T para debugar posição inicial que deseja
                console.log(viewerSuperiorRisco.camera.position);
                break;
        }
    });

    const toggleOculusFunc = () => {

        if (!toggleOculus) {
            if (isIos) {
                (DeviceMotionEvent as any).requestPermission();
            }
            viewerSuperiorRisco.enableControl(1);
            viewerSuperiorRisco.enableEffect( PANOLENS.MODES.CARDBOARD);
        } else {
            viewerSuperiorRisco.enableControl(0);
            viewerSuperiorRisco.enableEffect( PANOLENS.MODES.NORMAL);
        }

        setToggleOculus(!toggleOculus);
        Ativa360Service.toggle360Ativo();
    }

    return (
        <>
            <div id="home-superior-risco">
                { }
                <img className='seta-frente' src={imgInfoSpotPiso} onClick={() => {
                    sendEvent({ category: 'home_visao_risco', label: 'menu_pts_home_superior', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
                }} />
            </div>

            {
                // isAndroid &&
                // <Button onClick={toggleOculusFunc} className={'bt-oculus ativo-' + toggleOculus}>
                //     <img className='pano-off' src={icoPanoOff} />
                //     <img className='pano-on' src={icoPanoOn} />
                // </Button>
            }
        </>
    );
}