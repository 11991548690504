import React, { useEffect, useState } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import HoverMessage from '../../components/hover-message/hover-message.component';
import { sendEvent } from '../../scripts/events';
import { FinalVideoService } from '../../services/final-video.service';
import ArenaNegociosPage from '../arenas/arena-negocios/arena-negocios.page';
import ArenaGestaoDePessoasCursosPage from '../arenas/arena-gestao-de-pessoas-cursos/arena-gestao-de-pessoas-cursos.page';
import ArenaSucessaoFamiliarCursosPage from '../arenas/arena-sucessao-familiar-cursos/arena-sucessao-familiar-cursos.page';
import ArenaGestaoADMCursosPage from '../arenas/arena-gestao-adm-cursos/arena-gestao-adm-cursos.page';
import ArenaPlanejamentoCursosPage from '../arenas/arena-planejamento-cursos/arena-planejamento-cursos.page';
import ArenaCertificacaoCursosPage from '../arenas/arena-certificacao-cursos/arena-certificacao-cursos.page';
import './negocios.page.scss';

export default function NegociosPage() {
  const [message, setMessage] = useState('');
  const handleMouse = (message: string) => setMessage(message);
  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showArenaNegociosSlide, setShowArenaNegociosSlide] = React.useState<boolean>(false);
  const [showArenaGestaoDePessoasSlide, setShowArenaGestaoDePessoasSlide] = React.useState<boolean>(false);
  const [showArenaSucessaoFamiliar, setShowArenaSucessaoFamiliar] = React.useState<boolean>(false);
  const [showGestaoADM, setShowGestaoADM] = React.useState<boolean>(false);
  const [showPlanejamento, setShowPlanejamento] = React.useState<boolean>(false);
  const [showCertificacao, setShowCertificacao] = React.useState<boolean>(false);


  useEffect(() => {
    

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('negocios') > -1) {
        setShowBolinhas(true);
      }
    });

    document.body.classList.add("page-negocios");
    document.body.classList.add("bt-voltar-superior-ativo");

    return () => {
      document.body.classList.remove("page-negocios");
      document.body.classList.remove("bt-voltar-superior-ativo");
    };
  }, []);

  return (
    <section className='negocios'>
      {
        showBolinhas &&
        <>
          <FullscreenHud>
            <FullscreenHudElement key="configs" highlight={assets.empty} >
              <>
                <defs>
                  <filter id="blur">
                    <feGaussianBlur stdDeviation="4" />
                  </filter>
                </defs>
              </>
            </FullscreenHudElement>

            <FullscreenHudElement key="slider-1" highlight={assets.empty} >
              <>
                <circle cx="750" cy="850" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="750" cy="850" r="10" className='circle-button borda' />
                <circle cx="750" cy="850" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_negocios', label: 'Neg_ptserv', action: 'click' });
              setShowArenaNegociosSlide(true);
            }}>
              <rect x='650' y='740' width='200' height='200'
                onMouseOver={() => handleMouse('Conheça os serviços para aprimorar a gestão do seu negócio')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-1-cirle" highlight={assets.empty} >
              <>
                <circle cx="1090" cy="460" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1090" cy="460" r="10" className='circle-button borda' />
                <circle cx="1090" cy="460" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-1" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_negocios', label: 'Neg_ptrh', action: 'click' });
              setShowArenaGestaoDePessoasSlide(true);
            }}>
              <rect x='1010' y='350' width='170' height='250' />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-2-cirle" highlight={assets.empty} >
              <>
                <circle cx="1225" cy="750" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1225" cy="750" r="10" className='circle-button borda' />
                <circle cx="1225" cy="750" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-2" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_negocios', label: 'Neg_ptfam', action: 'click' });
              setShowArenaSucessaoFamiliar(true);
            }}>
              <rect x='960' y='635' width='350' height='150' />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-3-cirle" highlight={assets.empty} >
              <>
                <circle cx="1360" cy="450" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1360" cy="450" r="10" className='circle-button borda' />
                <circle cx="1360" cy="450" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-3" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_negocios', label: 'Neg_ptadm', action: 'click' });
              setShowGestaoADM(true);
            }}>
              <rect x="1270" y="310" width="200" height="300" />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-4-cirle" highlight={assets.empty} >
              <>
                <circle cx="1510" cy="750" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1510" cy="750" r="10" className='circle-button borda' />
                <circle cx="1510" cy="750" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-4" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_negocios', label: 'Neg_ptplan', action: 'click' });
              setShowPlanejamento(true);
            }}>
              <rect x="1420" y="640" width="380" height="150" />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-4-cirle" highlight={assets.empty} >
              <>
                <circle cx="1640" cy="450" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1640" cy="450" r="10" className='circle-button borda' />
                <circle cx="1640" cy="450" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-4" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_negocios', label: 'Neg_ptcert', action: 'click' });
              setShowCertificacao(true);
            }}>
              <rect x="1535" y="320" width="210" height="220" />
            </FullscreenHudElement>

          </FullscreenHud>
          <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
            <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
          </div>

          {
            showArenaNegociosSlide &&
            <ArenaNegociosPage onClose={() => {
              setShowArenaNegociosSlide(false);
            }} />
          }

          {
            showArenaGestaoDePessoasSlide &&
            <ArenaGestaoDePessoasCursosPage onClose={() => {
              setShowArenaGestaoDePessoasSlide(false);
            }} />
          }

          {
            showArenaSucessaoFamiliar &&
            <ArenaSucessaoFamiliarCursosPage onClose={() => {
              setShowArenaSucessaoFamiliar(false);
            }} />
          }

          {
            showGestaoADM &&
            <ArenaGestaoADMCursosPage onClose={() => {
              setShowGestaoADM(false);
            }} />
          }

          {
            showPlanejamento &&
            <ArenaPlanejamentoCursosPage onClose={() => {
              setShowPlanejamento(false);
            }} />
          }

          {
            showCertificacao &&
            <ArenaCertificacaoCursosPage onClose={() => {
              setShowCertificacao(false);
            }} />
          }
        </>
      }
    </section>
  );
}
