import React, { useEffect, useState } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import HoverMessage from '../../components/hover-message/hover-message.component';
import { sendEvent } from '../../scripts/events';
import { FinalVideoService } from '../../services/final-video.service';
import YouTubePage from '../youtube/youtube.page';
import BoardClimatePage from '../board-climate/board-climate.page';
import ArenaInovacaoPage from '../arenas/arena-inovacao/arena-inovacao.page';
import './inovacao.page.scss';

export default function InovacaoPage() {
  const [message, setMessage] = useState('');
  const handleMouse = (message: string) => setMessage(message);
  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showVideoClimate, setShowVideoClimate] = React.useState<boolean>(false);
  const [showBoardClimate, setShowBoardClimate] = React.useState<boolean>(false);
  const [showArenaInovacaoSlide, setShowArenaInovacaoSlide] = React.useState<boolean>(false);


  useEffect(() => {

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('inovacao') > -1) {
        setShowBolinhas(true);
        if (localStorage.getItem('tarefaTipo') === 'Tarefa 1') {
          //setShowVideoClimate(true);
        }
      }
    });

    document.body.classList.add("bt-voltar-superior-ativo");
    document.body.classList.add("page-inovacao");

    return () => {
      document.body.classList.remove("bt-voltar-superior-ativo");
      document.body.classList.remove("loaded");
      document.body.classList.remove("page-inovacao");
    };
  }, []);

  return (
    <section className='inovacao'>
      {
        showBolinhas &&
        <>
          <FullscreenHud>
            <FullscreenHudElement key="configs" highlight={assets.empty} >
              <>
                <defs>
                  <filter id="blur">
                    <feGaussianBlur stdDeviation="4" />
                  </filter>
                </defs>
                <circle cx="1500" cy="870" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1500" cy="870" r="10" className='circle-button borda' />

                <circle cx="780" cy="575" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="780" cy="575" r="10" className='circle-button borda' />
              </>
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-1" highlight={assets.empty}>
              <circle cx="780" cy="575" r="10" strokeWidth='10px' className='circle-button' />
            </FullscreenHudElement>
            <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_inovacao', label: 'Inov_pthero', action: 'click' });
              //heroINOVATIONvideo();
              setShowVideoClimate(true);
            }}>
              <rect x='590' y='470' width='400' height='200'
                onMouseOver={() => handleMouse('Climate FieldView™ é a plataforma de agricultura digital da Bayer. Clique aqui que eu te mostro como ele funciona!')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

            <FullscreenHudElement key="slider-1" highlight={assets.empty} >
              <circle cx="1500" cy="870" r="10" className='circle-button' strokeWidth='10px' />
            </FullscreenHudElement>
            <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_inovacao', label: 'Inove_ptserv', action: 'click' });
              setShowArenaInovacaoSlide(true);
            }}>
              <rect x='1290' y='770' width='400' height='200'
                onMouseOver={() => handleMouse('Conheça os serviços para levar inovação para o seu negócio')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

          </FullscreenHud>

          <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
            <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
          </div>

          {
            showVideoClimate &&
            <YouTubePage
              videoId={'Hy-HgwOX_r8'}
              videoName={'climate'}
              onClose={() => {
                setShowBoardClimate(true);
                setShowVideoClimate(false);
              }} />
          }

          {
            showBoardClimate &&
            <BoardClimatePage onClose={() => {
              setShowBoardClimate(false);
            }} />
          }

          {
            showArenaInovacaoSlide &&
            <ArenaInovacaoPage onClose={() => {
              setShowArenaInovacaoSlide(false);
            }} />
          }
        </>
      }
    </section>
  );
}
