declare global {
  interface Window {
    dataLayer: any[];
  }
}

export interface Props {
    label?: string;
    category: string;
    action: string;
}

export const sendEvent = ({ label, category, action }: Props): void => {
  const data = {
    'event': 'b-custom-event',
    'data-gtm-event-category': `bayer_${category}`,
    'data-gtm-event-action': action,
    'data-gtm-event-label': label,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};