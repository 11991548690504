import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { UserContext } from '../userContext';
import { set as setCookie } from 'es-cookie';
import { UID, PROVIDER, USER_S_EMAIL } from '../../constants/cookies';
import imgMulher from "../../assets/modal-login/mulher.png";
import { UserType } from '../../types/user';
import Modal from '../modal/modal';
import "./gigya-login.component.scss";
import { sendEvent } from '../../scripts/events';

const Login = styled.div` // hide the signup and the other ways of connect
  position: relative;
  .with-site-login, .with-divider, .under-site-login, .gigya-clear {
    display: none;
  }
  [data-gigya-provider="googleplus"], [data-gigya-provider="facebook"] {
    display: none;
  }
  .gigya-login-providers-container [data-gigya-provider="oidc-orbiaprod"] {
    text-align: center;
    width: auto !important;
  }
`;

const Gigya = styled.div`
  background-color: white;
`;

const LoginModal = styled.div`
  color: white;
  background-color: #4bd3ed;
  display: flex;
  height: 100%;
  border-radius: 40px;
`;

const Title = styled.h2`
  margin: 0;
  color: #023952;
`;

const LoginButton = styled.button`
  font-size: 13px;
  background-color: #0f3952;
  color: white;
  border-radius: 15px;
  text-transform: uppercase;
  padding: 5px;
  width: 45%;
`;

const NewAccountLink = styled.a`
  text-align: center;
  font-size: 13px;
  border: solid 1px #0f3952;
  background-color: transparent;
  color: #0f3952;
  border-radius: 15px;
  text-transform: uppercase;
  padding: 5px;
  text-decoration: none;
  box-sizing: border-box;
  width: 45%;
`;

const ProgramName = styled.span`
  font-weight: 700;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  max-width: 350px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 25px;
  @media only screen and (min-width: 768px) {
    margin-left: 0;
  }
`

const Description = styled.p`
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Attendant = styled.img`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    margin-top: 20px;
    object-fit: contain;
    align-self: flex-end;
  }
`;

const Loading = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  p {
    color: white;
    margin-left: 20px;
  }
`;

export type GigyaLoginProps = {
  metadata: any,
  redirectTo: number,
  handleOnLogin: ({ command, user, metadata }: { command: number, user?: UserType, metadata?: any }) => void;
  onClose: () => void;
}

function firstLoginHandler(eventObj: any) {
  var application = window.location.hostname;
  var date = new Date();
  var apps = eventObj.data.bc_accessApplications;
  var json_obj;
  var timestamp = date.toISOString();
  if (!apps) {
    json_obj = { "hostname": application, "firstLogin": timestamp, "lastLogin": timestamp };
    apps = [JSON.stringify(json_obj)];
  } else {
    var isNewApp = true;
    var appIndex;
    for (var i = 0; i < apps.length; i++) {
      var host = JSON.parse(apps[i]).hostname;
      if (host == application) {
        isNewApp = false;
        appIndex = i;
        break;
      }
    }
    if (isNewApp) {
      json_obj = { "hostname": application, "firstLogin": timestamp, "lastLogin": timestamp };
      apps.push(JSON.stringify(json_obj));
    } else {
      json_obj = JSON.parse(apps[appIndex]);
      json_obj.lastLogin = timestamp;
      apps[appIndex] = JSON.stringify(json_obj);
    }
  }
  (window as any).gigya.accounts.setAccountInfo({ data: { "bc_accessApplications": apps } });
}

export default function GigyaLoginComponent(props?: GigyaLoginProps) {
  const userProps = useContext(UserContext);

  const [error, setError] = useState(false);
  const [showLoginModal, setLoginModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  const [animationActive, setAnimationActive] = React.useState<boolean>(false);

  useEffect(() => {
    openLogin();
    setTimeout(() => {
      setAnimationActive(true);
    }, 100);
  }, []);

  const errorHandler = (eventObj: any) => {
    setError(true);
  }

  const screenSetOptions = {
    screenSet: 'bayer-RegistrationLogin_Social',
    startScreen: 'bayer-login-screen',
    lang: 'pt-br',
    containerID: 'idGigya',
    deviceType: 'auto',
    onAfterScreenLoad: () => {
      console.log('afterLoadScreen');
      setTimeout(() => {
        let link: any = document.querySelector('#idGigya span.gigya-login-provider');
        link?.click();
      }, 1000);
    },
    onError: errorHandler
  };

  const openLogin = () => {
    if (window !== undefined) {
      setLoginModal(false);
      (window as any).gigya.accounts.addEventHandlers({
        onLogin: (...args: any) => {
          setShowLoading(true);
          const data = args[0];
          firstLoginHandler(args[0]);
          sendEvent({ category: 'logado_id_dousuario', label: data.profile.email, action: 'click' });
          userProps.updateUser({
            email: data.profile.email,
            UID: data.UID,
            provider: data.provider,
          }).then((user: UserType) => {
            setCookie(UID, data.UID);
            setCookie(USER_S_EMAIL, data.profile.email);
            setCookie(PROVIDER, data.provider);
            sendEvent({ category: 'login', label: 'novo-login', action: 'click' });
            props.onClose();
          }).catch((err: any) => {
            console.log(err);
          });
        }
      });
      (window as any).gigya.accounts.showScreenSet(screenSetOptions);
    }
  };

  return (
    <Modal
      content={
        <Login className={"login-modal" + " animation-" + animationActive}>
          <Gigya id='idGigya' />
          {showLoading && (
            <Loading>
              <p>Loading...</p>
            </Loading>
          )}
          {showLoginModal && (
            <LoginModal>
              <Attendant src={imgMulher} alt="Atendente" width="200px" height="200px" />
              <Container>
                <Title>Olá, queremos proporcionar mais para você!</Title>
                <Description>Cadastre-se ou faça seu login para oferecermos uma experiência personalizada, com produtos e serviços selecionados especialmente para suas necessidades.</Description>
                <Actions className="bts">
                  <LoginButton className="bt" onClick={() => {
                    sendEvent({ category: 'menu', label: 'Pop_btlogin', action: 'click' });
                    openLogin();
                  }}>Login</LoginButton>
                  <NewAccountLink className="bt cad" onClick={() => {
                    sendEvent({ category: 'menu', label: 'Pop_btcadastro', action: 'click' });
                  }} href='https://www.orbia.ag/Account/LogOn' target="_blank">Cadastrar</NewAccountLink>
                </Actions>
              </Container>
            </LoginModal>
          )}
          {error && (
            <p>Não foi possível realizar o login, tente novamente em breve</p>
          )}
        </Login>
      }
      onClose={props.onClose}
    />
  );
}
