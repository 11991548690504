
import { Button } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import logo from "../../assets/menu/logo-espaco-bayer.png";
import foto from "../../assets/menu/img-profile.png";
import impulsoLogo from "../../assets/menu/impulso-logo-branco.png";
import "./header.component.scss";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { ReactComponent as MilhoIcon } from "../../assets/menu/milho.svg";
import { ReactComponent as SojaIcon } from "../../assets/menu/soja.svg";
import { ReactComponent as TrigoIcon } from "../../assets/menu/trigo.svg";
import { ReactComponent as AcucarIcon } from "../../assets/menu/acucar.svg";
import { ReactComponent as UvaIcon } from "../../assets/menu/uva.svg";
import { ReactComponent as CafeIcon } from "../../assets/menu/cafe.svg";
import { UserContext } from '../../components/userContext';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { sendEvent } from '../../scripts/events';
import LiveAgoraModel from './live-agora.model';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { audioControl } from '@dream-pixel/crew-engine-web';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Countdown from 'react-countdown';
import MinhasTarefasComponent from '../minhas-tarefas/minhas-tarefas.component';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GetEspacoBayerADMService, GetTarefaService, SalvarTarefaService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import TarefaModel from './tarefa.model';
import { MenuService } from '../../services/menu.service';
import { SalvarTarefaSubjectService } from '../../services/salvar-tarefa.service';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { MaisServicosService } from '../../services/mais-servicos.service';

export type HeaderComponentProps = {
    login: () => void;
    audioContrVisible: boolean;
}

let tt = true;
let semErro = false;

function HeaderComponent({ login, audioContrVisible }: HeaderComponentProps) {
    const userProps = useContext(UserContext);
    const [isShowMenu, setIsShowMenu] = React.useState<boolean>(false);
    const [audioOn, setAudioOn] = React.useState(true);
    const [showTarefas, setShowTarefas] = React.useState(false);
    const [cardAtual, setCardAtual] = React.useState(0);
    const [apiLiveAgora, setApiLiveAgora] = React.useState<LiveAgoraModel[]>();

    let data = new Date();
    let dataFormatada = ((data.getMonth() + 1)) + "/" + ((data.getDate())) + "/" + data.getFullYear() + " " + data.getHours() + ":" + data.getMinutes();
    var date_hj: any = new Date(dataFormatada);
    const [statusCountDown, setStatusCountDown] = React.useState<string>('aguardando');
    const [elapsedStage, setElapsedStage] = React.useState<string>('');

    const [tarefa, setTarefa] = React.useState<TarefaModel>();

    useEffect(() => {
        audioControl.addEventListener('volumeEnableChanged', c => setAudioOn(c.audioEnabled));

        MenuService.getAtivo().subscribe(ativo => {
            if (userProps.user) {
                GetTarefa();
            }
            setIsShowMenu(true);
        });

        SalvarTarefaSubjectService.getSalvar().subscribe(ativo => {
            if (localStorage.getItem('tarefaID')) {
                SalvarTarefa();
            }
        });

        if (userProps.user && isShowMenu) {
            GetTarefa();
        }

    }, [userProps.user]);

    useEffect(() => {
        GetEspacoBayerADM();
    }, []);

    const GetEspacoBayerADM = () => {
        LoadingService.sendLoadingAtivo(true);
        GetEspacoBayerADMService().then(res => {
            LoadingService.sendLoadingAtivo(false);
            if (res.data.error) {
                SnackService.sendMSG(res.data.error);
            } else {
                //props.resApi(res?.data?.itens_notificacao);
                setApiLiveAgora(res?.data?.itens_notificacao);
                let calc = 0;

                window.setInterval(() => {
                    if (calc >= res?.data?.itens_notificacao?.length) {
                        calc = 0;
                    }
                    setCardAtual(() => calc);
                    calc++;
                }, 10000);

                localStorage.setItem('admWP', JSON.stringify(res.data));
            }
        }).catch(err => {
            LoadingService.sendLoadingAtivo(false);
            if (err.response?.status === 401) {
                SnackService.sendMSG('ERRO 401');
            } else {
                SnackService.sendMSG('Erro com a conexão ao verificar ADM.');
            }
        })
    }

    const GetTarefa = () => {
        LoadingService.sendLoadingAtivo(true);
        let usEmail = userProps.user.email ? userProps.user.email : localStorage.getItem('tarefaEmail');
        GetTarefaService(usEmail).then(res => {
            LoadingService.sendLoadingAtivo(false);
            if (res.data.error) {
                SnackService.sendMSG(res.data.error);
            } else {
                setTarefa(res.data);
                startCountDown(res.data.encerramento_tarefa);
                localStorage.setItem('tarefaID', res.data.ID);
                localStorage.setItem('tarefaEmail', userProps.user.email);
                localStorage.setItem('tarefaTipo', res.data.tarefa);
            }
        }).catch(err => {
            LoadingService.sendLoadingAtivo(false);
            if (err.response?.status === 401) {
                SnackService.sendMSG('ERRO 401');
            } else {
                SnackService.sendMSG('Erro com a conexão ao verificar ADM.');
            }
        })
    }

    const SalvarTarefa = () => {
        if (tt) {

            // para não fazer varios requests
            tt = false;
            setTimeout(() => {
                tt = true;
            }, 5000);
            // para não fazer varios requests

            LoadingService.sendLoadingAtivo(true);
            SalvarTarefaService(localStorage.getItem('tarefaEmail'), localStorage.getItem('tarefaID')).then(res => {
                LoadingService.sendLoadingAtivo(false);
                if (res.data.error) {
                    SnackService.sendMSG(res.data.error);
                } else {
                    SnackService.sendMSG(res.data.msg);
                    semErro = true;
                    if (res.data.status === 2) {
                        GetTarefa();
                    }
                }
            }).catch(err => {
                LoadingService.sendLoadingAtivo(false);
                if (err.response?.status === 401) {
                    SnackService.sendMSG('ERRO 401');
                } else {
                    if (semErro) {
                        semErro = false;
                    } else {
                        SnackService.sendMSG('Erro com a conexão ao verificar ADM.');
                    }
                }
            })
        }
    }

    function onToggleAudio() {
        audioControl.toggleAudioEnabled();
    }

    const toggleMenu = () => {
        setIsShowMenu(!isShowMenu);
        if (!isShowMenu) {
            if (userProps.user) {
                GetTarefa();
            }
        }
    }

    const Ico = ({ name }: { name: string }) => {
        switch (name) {
            case 'milho':
                return <MilhoIcon viewBox="-280 60 1350 800" />
            case 'soja':
                return <SojaIcon viewBox="-280 60 1350 800" />
            case 'tribo':
                return <TrigoIcon viewBox="-280 60 1350 800" />
            case 'acucar':
                return <AcucarIcon viewBox="-280 60 1350 800" />
            case 'uva':
                return <UvaIcon viewBox="-280 60 1350 800" />
            case 'cafe':
                return <CafeIcon viewBox="-280 60 1350 800" />
            default:
                return <MilhoIcon viewBox="-280 60 1350 800" />
        }
    }

    const Crop = ({ name }: { name: string }) => (
        <>
            <Ico name={name} />
            <small>{name}</small>
        </>
    );

    const startCountDown = (date_fut_DB: string) => {
        var date_fut: any = new Date(date_fut_DB);
        var elapsed: any = date_hj - date_fut;
        elapsed = elapsed.toString().replace('-', '');
        setElapsedStage(elapsed);
        if (date_hj > date_fut) {
            setStatusCountDown('encerrado');
        } else {
            setStatusCountDown('countdown');
        }
    }

    const fimCountDown = () => {
        //SnackService.sendMSG('Correeee!! Acabou de começar o evento =)');
    }

    const Completionist = () => <h3>Tarefa encerrada</h3>;

    const renderer = ({ days, hours, minutes, seconds, completed }: { days: any, hours: any, minutes: any, seconds: any, completed: any }) => {
        if (completed) {
            // Render a completed state
            setStatusCountDown('encerrado');
            return <Completionist />;
        } else {
            // Render a countdown
            if (hours.toString().length === 1) {
                hours = '0' + hours;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            return <span>{days}d {hours}:{minutes}:{seconds}</span>;
        }
    };

    return (
        <>
            <nav className={isShowMenu && "ativo"}>
                <div className="info-us flex flex-cv flex-sp flex-wr">
                    <>
                        {!userProps.user && (
                            <div className="not-logged flex flex-cv flex-wr">
                                <div className="itens">
                                    <p>
                                        Aproveite melhor a experiência
                                        e participe dos Desafios do Espaço Bayer
                                        com sua conta do Impulso Bayer / Orbia.
                                    </p>
                                    <div className="flex flex-wr">
                                        <Button onClick={() => {
                                            sendEvent({ category: 'menu', label: 'Menu_btlogin', action: 'click' });
                                            login();
                                        }}>Login</Button>
                                        <Button onClick={() => {
                                            sendEvent({ category: 'menu', label: 'Menu_btcadastrar', action: 'click' });
                                        }} className="cad" href='https://www.orbia.ag/Account/LogOn' target="_blank">Cadastrar</Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {userProps.user && (
                            <>
                                <div className="box-infos flex flex-ch flex-wr">
                                    <div className="foto">
                                        <img src={foto} width="140px" height="140px" alt="Foto" />
                                        <h3>{userProps.user?.name}</h3>
                                    </div>
                                    <div className="box-points flex flex-ch">
                                        <div className="esq">
                                            <small>Você ganhou,</small>
                                            <h3>
                                                {
                                                    tarefa?.pontos_usuario ? tarefa?.pontos_usuario?.toLocaleString('pt-br', { minimumFractionDigits: 0 }) : 0
                                                }
                                            </h3>
                                            <small>Pontos Impulso Bayer.</small>
                                        </div>
                                        <ol className="right flex flex-cv flex-ch">
                                            <li>
                                                <Button onClick={() => { setShowTarefas(true) }}>
                                                    Minhas Tarefas
                                                </Button>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="box-contatos">
                                        <p>
                                            <EmailIcon /> <strong>E-mail: </strong>{userProps.user?.email}
                                        </p>
                                        {userProps.user?.phone && (
                                            <p>
                                                <PhoneIcon /> <strong>Telefone: </strong>{userProps.user?.phone}
                                            </p>
                                        )}
                                    </div>
                                    {userProps.user?.plantedArea && (
                                        <>
                                            <h4>Culturas</h4>
                                            <ol className="flex flex-ch flex-wr">
                                                {userProps.user?.plantedArea.map((item, index) => (
                                                    <li key={`start-${index}`}>
                                                        <Crop name={item.crop} />
                                                    </li>
                                                ))}
                                            </ol>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="impulso-bayer">
                            <Button onClick={() => {
                                sendEvent({ category: 'menu', label: 'menu_btimpulso', action: 'click' });
                            }} className="bt-impulso" href="https://www.agro.bayer.com.br/impulso-bayer/o-programa" target="_blank">
                                <span>Saiba mais sobre o</span>
                                <img src={impulsoLogo} alt="Impulso Logo" />
                            </Button>
                        </div>
                    </>
                </div>
                <div className="area-menu">
                    <h2>
                        Sua jornada virtual vale <br />
                        pontos extras impulso bayer!
                        <i>+</i>
                    </h2>

                    {
                        !userProps.user &&
                        <div className="nao-logado">
                            <p>
                                <strong>Para participar é muito simples:</strong>
                            </p>

                            <h3>Passo 1:</h3>
                            <p>
                                Acesse o Espaço Bayer logado com sua conta do Impulso/Orbia. Se você ainda não tiver um login, basta se cadastrar como novo membro.
                            </p>

                            <h3>Passo 2:</h3>
                            <p>
                                Toda semana tem um desafio diferente: cumpriu, ganhou! Realize as tarefas indicadas e ganhe pontos para resgatar em produtos ou serviços Bayer.
                            </p>

                            <h3>Passo 3:</h3>
                            <p>
                                Ao cumprir a tarefa da semana, você receberá um cupom de desconto para utilizar no carrinho do Orbia.
                            </p>

                            <p>
                                Faça <Button onClick={() => {
                                    sendEvent({ category: 'menu', label: 'Menu_link_login', action: 'click' });
                                    login();
                                }}>Login</Button> ou <Button onClick={() => {
                                    sendEvent({ category: 'menu', label: 'Menu_btcadastrar', action: 'click' });
                                }} href='https://www.orbia.ag/Account/LogOn' target="_blank">cadastre-se</Button> para participar e ganhe pontos extras!
                            </p>
                        </div>
                    }

                    {
                        userProps.user &&
                        <div className="logado">
                            <h3>Tarefa da semana</h3>
                            <div className="box-tema flex flex-wr">
                                <div className="esq flex flex-cv">
                                    <div className="box">
                                        <span>Tema</span>
                                        <h4>{tarefa?.tema}</h4>
                                    </div>
                                </div>
                                <div className="dir">
                                    <h4>{tarefa?.titulo}</h4>
                                    {
                                        statusCountDown === 'countdown' &&
                                        <Button onClick={() => {
                                            if (localStorage.getItem('tarefaTipo') === 'Tarefa 1') {
                                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.inovacao);
                                                setIsShowMenu(false);
                                            } else if (localStorage.getItem('tarefaTipo') === 'Tarefa 2') {
                                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.produtividade);
                                                setIsShowMenu(false);
                                            } else if (localStorage.getItem('tarefaTipo') === 'Tarefa 3' || localStorage.getItem('tarefaTipo') === 'Tarefa 4') {
                                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.lives);
                                                setIsShowMenu(false);
                                            } else if (localStorage.getItem('tarefaTipo') === 'Tarefa 5' || localStorage.getItem('tarefaTipo') === 'Tarefa 6') {
                                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.forum);
                                                setIsShowMenu(false);
                                            } else if (localStorage.getItem('tarefaTipo') === 'Tarefa 7' || localStorage.getItem('tarefaTipo') === 'Tarefa 7') {
                                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.foxXproPlantacao);
                                                setIsShowMenu(false);
                                            } else if (localStorage.getItem('tarefaTipo') === 'Tarefa 8') {
                                                LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.feira);
                                                setIsShowMenu(false);
                                            }
                                        }}>
                                            <div className="ico">
                                                <ArrowRight />
                                            </div>
                                            <span dangerouslySetInnerHTML={{ __html: tarefa?.legenda_botao }}>
                                            </span>
                                        </Button>
                                    }
                                    {
                                        statusCountDown === 'encerrado' &&
                                        <span>Encerrado!</span>
                                    }

                                </div>
                            </div>
                            <div className="cards flex flex-wr">
                                <div className="card flex flex-cv flex-ch">
                                    <h6>Essa tarefa vale</h6>
                                    <div className="box">
                                        <h2>{tarefa?.essa_tarefa_vale_quantos_pontos.toLocaleString('pt-br', { minimumFractionDigits: 0 })}</h2>
                                        <h3>
                                            Pontos <br />
                                            Impulso Bayer
                                        </h3>
                                    </div>
                                </div>
                                <div className="card flex flex-cv flex-ch">
                                    <h6>
                                        TEMPO PARA CUMPRIR <br />
                                        ESSA TAREFA
                                    </h6>
                                    <div className="box">
                                        {
                                            statusCountDown === 'countdown' &&
                                            <h2>
                                                <Countdown onComplete={() => fimCountDown()} date={Date.now() + parseInt(elapsedStage)} renderer={renderer} />
                                            </h2>
                                        }
                                        {
                                            statusCountDown === 'encerrado' &&
                                            <h3>Tarefa encerrada</h3>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="links">
                        <ul className="flex">
                            <li>
                                <Button onClick={() => {
                                    sendEvent({ category: 'menu', label: 'Menu_btorbia', action: 'click' });
                                }} href="https://www.orbia.ag/" target="_blank">Orbia</Button>
                            </li>
                            <li>
                                <Button onClick={() => {
                                    sendEvent({ category: 'menu', label: 'Menu_btagro', action: 'click' });
                                }} href="https://www.agro.bayer.com.br/" target="_blank">Agro Bayer</Button>
                            </li>
                            {userProps.user && (
                                <li>
                                    <Button className="sair" onClick={() => userProps.logout()}>
                                        <PowerSettingsNewIcon /> Encerrar a sessão
                                    </Button>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="social-media">
                        <ul className="flex">
                            <li>
                                <Button href="https://www.facebook.com/AgroBayerBrasil/" target="_blank">
                                    <FacebookIcon />
                                </Button>
                            </li>
                            {/* <li>
                                <Button href="https://www.google.com/" target="_blank">
                                    <TwitterIcon />
                                </Button>
                            </li> */}
                            <li>
                                <Button href="https://www.instagram.com/agrobayerbrasil/" target="_blank">
                                    <InstagramIcon />
                                </Button>
                            </li>
                            <li>
                                <Button href="https://www.youtube.com/user/BayerCropScienceBR" target="_blank">
                                    <YouTubeIcon />
                                </Button>
                            </li>
                        </ul>
                        <div className="foot flex flex-sp flex-bv flex-wr">
                            <p>
                                ©2021 Espaço Bayer. <br />
                                Todos direitos reservados.
                            </p>
                            <ul>
                                <li>
                                    <Button href="https://www.agro.bayer.com.br/politica-de-privacidade" target="_blank">
                                        Política de privacidade
                                    </Button>
                                </li>
                                <li>
                                    <Button href="https://www.agro.bayer.com.br/condicoes-gerais" target="_blank">
                                        Termos de uso
                                    </Button>
                                </li>
                                <li>
                                    <Button href="https://www.agro.bayer.com.br/imprint" target="_blank">
                                        Imprint
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <header className="flex flex-sp flex-tv flex-tv">
                <img src={logo} alt="Logo" />
                <div className="dir flex flex-cv flex-ri flex-wr">
                    <Button onClick={() => {
                        sendEvent({ category: 'home', label: 'Home_btmenu', action: 'click' });
                        toggleMenu();
                    }} className={isShowMenu ? "menu-ppp ativo" : "menu-ppp"}>
                        <b>
                            {
                                !isShowMenu && <>Menu</>
                            }
                        </b>
                        <span className="ppp flex flex-cv flex-sp">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        <span className="x">
                            <span></span>
                            <span></span>
                        </span>
                    </Button>
                    {(!userProps.user && !isShowMenu) && (
                        <Button onClick={() => {
                            sendEvent({ category: 'home', label: 'Home_btlogin', action: 'click' });
                            login();
                        }} className="bt-login">
                            <b>Login</b> <AccountCircleIcon />
                        </Button>
                    )}
                    {audioContrVisible && (
                        <>
                            <Button className="bt sound" onClick={() => {
                                onToggleAudio();
                                sendEvent({ category: 'entrada', label: 'BT_semsom', action: 'click' });
                            }}>
                                <b>Som</b>
                                {
                                    audioOn ?
                                        <VolumeUpIcon />
                                        :
                                        <VolumeOffIcon />
                                }
                            </Button>
                        </>
                    )}
                    <Button className="bt-home-totem" onClick={() => {
                        LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.voltarRisco);
                    }}>
                        <span>Voltar</span> <ArrowBackIcon />
                    </Button>
                    <Button className="bt-voltar-superior" onClick={() => {
                        if (window.innerWidth < 1024 && localStorage.getItem('urlFrom') === 'homeRisco') {
                            LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeRisco);
                        } else {
                            LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
                        }
                    }}>
                        <span>Voltar</span> <ArrowBackIcon />
                    </Button>
                    <Button className="bt-home" onClick={() => {
                        LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home);
                    }}>
                        <span>Voltar</span> <ArrowBackIcon />
                    </Button>

                    {(!isShowMenu) && (
                        <>
                            <div className={"cards card-atual-" + cardAtual}>
                                {
                                    apiLiveAgora?.length > 0 && apiLiveAgora.map((itemNotificacao, indexNot) => (
                                        <div key={indexNot} className={"card-live-agora card-" + indexNot}>
                                            <h4>{itemNotificacao?.chamada}</h4>
                                            <h2>{itemNotificacao?.titulo}</h2>
                                            <Button className="bt-assistir" onClick={() => {
                                                sendEvent({ category: 'acontecendo_agora', label: 'bt_assistir_agora', action: 'click' });
                                                itemNotificacao.direcionar_para === 'Agenda' && LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.lives);
                                                itemNotificacao.direcionar_para === 'Fórum' && LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.forum);
                                                itemNotificacao.direcionar_para === 'Feira' && LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.feira);
                                                itemNotificacao.direcionar_para === 'Mais serviços' && MaisServicosService.send(true);
                                                itemNotificacao.direcionar_para === 'Desafios' && setIsShowMenu(true);
                                            }}>
                                                <ArrowRight /> {itemNotificacao?.legenda_botao}
                                            </Button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="box-ajuda flex flex-ri">
                                <Button onClick={() => {
                                    sendEvent({ category: 'home', label: 'home_btajuda', action: 'click' });
                                    //ajudaChatCarol();
                                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.chatCarol);
                                }} className="bt-ajuda">
                                    Precisa de ajuda? <ContactSupportIcon />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {
                    showTarefas &&
                    <MinhasTarefasComponent email={userProps.user.email} onClose={() => { setShowTarefas(false) }} showTarefas={showTarefas} />
                }
            </header>
        </>
    );
}

export default HeaderComponent;