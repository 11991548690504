/**
 * All cookies should be prefixed with `BW_` to namespace them.
 */

export const ENVIRONMENT = 'BW_ENVIRONMENT';
export const USER_DOC = 'BW_USER_DOC';
export const USER_S_TOKEN = 'BW_S_TOKEN';
export const USER_S_EMAIL = 'BW_S_EMAIL';
export const UID = 'BW_UID';
export const PROVIDER = 'BW_PROVIDER';
