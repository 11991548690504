import React, { useState, useEffect } from 'react';
import { ChunkLoader, Screen, ProgressInfo } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import { Button } from '@material-ui/core';
import { sendEvent } from '../../scripts/events';
import "./loading-entrada.page.scss";

import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LiveAgoraModel from '../../components/header/live-agora.model';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}
          <p>
            Carregando...
          </p>
        </Typography>
      </Box>
    </Box>
  );
}

export const MINIMUM_PROGRESS_VALUE_DISPLAY = .01;

export interface LoadingScreenProps {
  onLoad?: () => void;
  onEntrar?: () => void;
}

export default function LoadingEntradaPage(props: LoadingScreenProps) {
  const [percentage, setPercentage] = useState(MINIMUM_PROGRESS_VALUE_DISPLAY * 100);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loader = new ChunkLoader(assets, 20);

    const onLoad = () => setLoaded(true);
    const onError = () => console.error('TODO: do something of fail');
    const onProgress = ({ progress }: ProgressInfo) => setPercentage(progress * 100);

    loader.addEventListener('load', onLoad);
    loader.addEventListener('error', onError);
    loader.addEventListener('progress', onProgress);
    loader.load();

    return () => {
      loader.removeEventListener('load', onLoad);
      loader.removeEventListener('error', onError);
      loader.removeEventListener('progress', onProgress);
    };
  }, [props]);

  useEffect(() => {
    if (loaded === true) {
      props.onLoad();
      sendEvent({ category: 'entrada', action: 'load' });
    }
  }, [loaded]);

  const btComecar = () => {
    props.onEntrar();
    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.entradaInferior);
    sendEvent({ category: 'entrada', label: 'bt_entrar', action: 'click' });
  }

  return (
    <>
      <section className="inicio">
        <div className="itens flex flex-wr flex-ch">
          {loaded && (
            <Button onClick={btComecar}>ENTRAR NO ESPAÇO BAYER</Button>
          )}
          <p className="txt">
            <strong>Atenção:</strong> Recomendamos os navegadores Google Chrome ou Safari para acessar essa plataforma.
          </p>
          {
            !loaded &&
            <p className="progress-p">
              <CircularProgressWithLabel value={parseFloat(percentage.toFixed(2))} />
            </p>
          }
          <p className="txt t3">
            Aguarde enquanto carregamos sua experiência, ela se iniciará automaticamente após o carregamento.
          </p>
          <p className="txt t2">
            O conteúdo deste site é destinado a agricultores e demais profissionais do setor agrícola
          </p>
        </div>
      </section>
    </>
  )
}
