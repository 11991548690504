import React from 'react';
import "./youtube.page.scss";
import YouTube from 'react-youtube';
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { sendEvent } from '../../scripts/events';
import { SalvarTarefaSubjectService } from '../../services/salvar-tarefa.service';

export type YouTubePageProps = {
    onClose: () => void;
    videoId: string;
    videoName?: string;
}

export default function YouTubePage({ onClose, videoId, videoName }: YouTubePageProps) {
    const onReady = (event: any) => {
        event.target.pauseVideo();
    }

    const closeVideo = () => {
        if (videoName === 'patrulha') {
            sendEvent({ category: 'video-youtube', label: 'patrulha_fechar', action: 'click' });
        }
        if (videoName === 'climate') {
            sendEvent({ category: 'video-youtube', label: 'climate_fechar', action: 'click' });
        }
        onClose();
    }

    const playVideo = () => {
        if (videoName === 'patrulha') {
            sendEvent({ category: 'video-youtube', label: 'patrulha_play', action: 'click' });
        }
        if (videoName === 'climate') {
            sendEvent({ category: 'video-youtube', label: 'climate_play', action: 'click' });
        }
    }

    const onEnd = () => {
        if (videoName === 'climate' && localStorage.getItem('tarefaTipo') === 'Tarefa 1') {
            SalvarTarefaSubjectService.sendSalvar(true);
        }
        if (videoName === 'patrulha' && localStorage.getItem('tarefaTipo') === 'Tarefa 2') {
            SalvarTarefaSubjectService.sendSalvar(true);
        }
    }

    return (
        <section className="youtube flex">
            <div className="video flex flex-cv">
                <YouTube
                    containerClassName='video-container flex flex-ch flex-cv'
                    videoId={videoId}
                    opts={{
                        height: '390',
                        width: '640',
                        playerVars: {
                            autoplay: 0,
                        }
                    }}
                    onPlay={playVideo}
                    onReady={onReady}
                    onEnd={onEnd}
                />
            </div>
            <Button className="bt-fechar" onClick={closeVideo}>
                <HighlightOffIcon />
            </Button>
        </section>
    );
}