import { Button, FormControl, FormControlLabel, FormHelperText, Checkbox, TextField } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import "./modal-forum.component.scss";
import imgMulher from "../../assets/modal-login/mulher.png";
import { useInput } from '../../hooks/useInput.hook';
import { sendEvent } from '../../scripts/events';
import { UserContext } from '../userContext';
import InputMask from 'react-input-mask';


export interface ModalForumProps {
    onClose: () => void;
    entrar: (nome: string, email: string) => void;
}

export default function ModalForumComponent(props: ModalForumProps) {

    const [animationActive, setAnimationActive] = React.useState<boolean>(false);

    const [emailErro, setEmailErro] = React.useState<boolean>(false);
    const { value: emailText, bind: bindEmailText, setValue: setEmailText } = useInput('');

    const [celularErro, setCelularErro] = React.useState<boolean>(false);
    const { value: celularText, bind: bindCelularText, setValue: setCelularText } = useInput('');

    const userProps = useContext(UserContext);

    useEffect(() => {
        setTimeout(() => {
            setAnimationActive(true);
            if (userProps.user) {
                setEmailText(userProps.user.email);
            }
        }, 100);
    }, [props]);

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const entrar = () => {

        if (!validateEmail(emailText)) {
            setEmailErro(true);
            return;
        }

        if (!celularText) {
            setCelularErro(true);
            return;
        }

        sendEvent({ category: 'entrar_forum_email', label: emailText, action: 'click' });
        sendEvent({ category: 'entrar_forum_celular', label: celularText, action: 'click' });
        props.entrar(emailText.split('@')[0], emailText);
    }

    return (
        <>
            <section className={'modal-forum' + " animation-" + animationActive}>
                <div className="box flex flex-wr flex-sp">
                    <div className="box-img flex flex-bv">
                        <img src={imgMulher} alt="Carol" />
                    </div>
                    <div className="box-form">
                        <h2>
                            Para participar, identifique-se!
                        </h2>

                        <div className="campos flex flex-sp flex-wr">
                            <FormControl>
                                <TextField label="Digite seu e-mail" variant="outlined" {...bindEmailText} />
                                {
                                    emailErro &&
                                    <FormHelperText>Digite um e-mail válido</FormHelperText>
                                }
                            </FormControl>
                            <FormControl>
                                <InputMask mask="(99) 9 9999-9999" {...bindCelularText}>
                                    {
                                        (bindCelularText: any) =>
                                            <TextField label="Digite seu celular com DDD" variant="outlined" {...bindCelularText} />
                                        // <MaterialInput {...inputProps} type="tel" disableUnderline />
                                    }
                                </InputMask>
                                {
                                    celularErro &&
                                    <FormHelperText>Digite o celular</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className="bts">
                            <FormControlLabel control={<Checkbox defaultChecked />} label={<>
                                Aceito receber comunicações e interagir no Espaço Bayer. <a href="https://www.agro.bayer.com.br/politica-de-privacidade" target="_blank">Termos e condições</a>
                            </>} />
                            <Button onClick={entrar} className="bt">
                                Entrar
                            </Button>
                            <Button onClick={() => props.onClose()} className="bt cad">
                                Voltar
                            </Button>
                        </div>
                    </div>
                    <Button
                        className='closeModal'
                        aria-label='Fechar'
                        onClick={() => props.onClose()}
                    />
                </div>
            </section>
        </>
    );
}