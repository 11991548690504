import React, { useContext, useEffect, useState } from 'react';
import "./board-climate.page.scss";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarIcon from '@material-ui/icons/Star';

import fogueteIcon from "../../assets/board-climate/foguete-icon.png";
import raioIcon from "../../assets/board-climate/raio-icon.png";

import { UserContext } from '../../components/userContext';
import { sendEvent } from '../../scripts/events';

export type BoardClimateProps = {
    onClose: () => void;
}

export default function BoardClimatePage(props: BoardClimateProps) {
    const { user } = useContext(UserContext);
    const [userInfo, setUserInfo] = useState({
        UID: '',
        name: '',
        email: '',
        CPF: '',
        stars: 0,
        points: 0,
        loading: false,
    });

    useEffect(() => {
        if (user) {
            setUserInfo(user);
            return;
        }
    }, [user]);

    const Stars = () => {
        const allStars = [];
        let yellowStarsTotal = 0;
        for (let index = 0; index < userInfo.stars; index++) {
            allStars.push(<StarIcon />);
            yellowStarsTotal++;
        }
        let grayStarsTotal = 5 - yellowStarsTotal;
        for (let index = 0; index < grayStarsTotal; index++) {
            allStars.push(<StarIcon className="gray" />);
        }
        return (<>{allStars}</>);
    }

    return (
        <>
            <section className="board-climate flex flex-cv flex-wr">
                <div className="area-image"></div>
                <div className="area-text">
                    <div className="box-points flex flex-ch">
                        <div className="esq">
                            <small>Você possui,</small>
                            <h3>{userInfo ? userInfo.points : 0}</h3>
                            <small>Pontos Impulso Bayer.</small>
                        </div>
                        <div className="right flex flex-cv flex-ch flex-wr">
                            <div>
                                <small>Você possui,</small>
                                <div className="flex flex-cv flex-ch">
                                    <Stars />
                                </div>
                                <small>estrelas.</small>
                            </div>
                        </div>
                    </div>

                    <div className="box-msgs entrada">
                        <img className="icon" src={fogueteIcon} alt="Foguete" />
                        <h2>Plano de Entrada</h2>
                        <h3>Licença Climate FieldView™</h3>
                        <h4>Resgate a partir de: 5.814 Pontos Impulso Bayer</h4>
                        <p>
                            <strong>
                                {/* Para clientes 3, 4 ou 5 estrelas: acesso gratuitoao Plano Plus com mapeamento ilimitado de acordo com a compatibilidade. */}
                            </strong>
                        </p>

                        <Button className="bt"
                            onClick={() => {
                                sendEvent({ category: 'board-climate-resgatar', label: 'climate_entrada', action: 'click' });
                            }}
                            target="_blank" rel="noopener"
                            href='https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.orbia.ag%2Fproduto%2F81393%2FRA1370%2F0%2Flicenca-climate-fieldview-plano-de-entrada%3Fc%3D31062&data=04%7C01%7C%7C69f8175abb244e38eb8408d9d5069695%7Cfcb2b37b5da0466b9b830014b67a7c78%7C0%7C0%7C637775046532421204%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=FIdpJYhlOGINgyaVloZfOMITxRrF5FmwEw6Otv0%2BdN8%3D&reserved=0'>
                            Resgatar agora!
                        </Button>
                    </div>
                    <div className="box-msgs entrada">
                        <img className="icon" src={fogueteIcon} alt="Raio" />
                        <h2>Plano Plus</h2>
                        <h3>Licença Climate FieldView™</h3>
                        <h4>Resgate a partir de: 34.884 Pontos Impulso Bayer</h4>
                        <p>
                            <strong>
                                {/* Para clientes 3, 4 ou 5 estrelas: acesso gratuitoao Plano Plus com mapeamento ilimitado de acordo com a compatibilidade. */}
                            </strong>
                        </p>
                        <Button className="bt"
                            onClick={() => {
                                sendEvent({ category: 'board-climate-resgatar', label: 'climate_plus', action: 'click' });
                            }}
                            target="_blank" rel="noopener"
                            href='https://www.orbia.ag/produto/81394/RA1371/0/licenca-climate-fieldviewT-plano-plus'>
                            Resgatar agora!
                        </Button>
                    </div>

                    <div className="box-msgs entrada">
                        <img className="icon" src={fogueteIcon} alt="Foguete" />
                        <h2>Kit Tablet Ipad 9 + Climate FieldView™ Drive</h2>
                        <h4>Resgate a partir de: 252.465 Pontos Impulso Bayer</h4>
                        <p>
                            <strong>
                                {/* Clientes com 1 ou 2 estrelas: acesso gratuito ao Plano de Entrada via Distribuidor Bayer */}
                            </strong>
                        </p>
                        <Button className="bt"
                            onClick={() => {
                                sendEvent({ category: 'board-climate-resgatar', label: 'climate_kit', action: 'click' });
                            }}
                            target="_blank" rel="noopener"
                            href='https://www.orbia.ag/produto/91601/CB2118/0/kit-tablet-ipad-9-wi-fi-fixacao-capa-protecao-climate-fieldview-drive'>
                            Resgatar agora!
                        </Button>
                    </div>
                    <div className="box-msgs entrada">
                        <img className="icon" src={fogueteIcon} alt="Raio" />
                        <h2>Climate FieldView™ Drive</h2>
                        {/* <h3>Primeira experiência digital do agricultor com FIELDVIEW</h3> */}
                        <h4>Resgate a partir de: 40.209 Pontos Impulso Bayer</h4>
                        <p>
                            <strong>
                                {/* Para clientes 3, 4 ou 5 estrelas: acesso gratuitoao Plano Plus com mapeamento ilimitado de acordo com a compatibilidade. */}
                            </strong>
                        </p>
                        <Button className="bt"
                            onClick={() => {
                                sendEvent({ category: 'board-climate-resgatar', label: 'climate_drive', action: 'click' });
                            }}
                            target="_blank" rel="noopener"
                            href='https://www.orbia.ag/produto/39704/BY5388/0/climate-fieldview-drive?c=31062'>
                            Resgatar agora!
                        </Button>

                        {/* <img className="box" src={boxImg} alt="Box" /> */}
                    </div>

                    <div className="box-msgs plus">
                        <img className="icon" src={raioIcon} alt="Raio" />
                        <h2>Precisa de ajuda?</h2>
                        {/* <h3>Primeira experiência digital do agricultor com FIELDVIEW</h3> */}
                        <p>
                            <strong>
                                {/* Para clientes 3, 4 ou 5 estrelas: acesso gratuitoao Plano Plus com mapeamento ilimitado de acordo com a compatibilidade. */}
                            </strong>
                        </p>

                        <Button
                            target="_blank"
                            href="https://wa.me/551142103591?text=Olá!%20Vim%20através%20do%20Espaço%20Bayer%20e%20gostaria%20de%20mais%20informações%20sobre%20serviços.%20"
                            rel="noopener"
                            className="bt especialista"
                            onClick={() => sendEvent({ category: 'board-climate-resgatar', label: 'especialista', action: 'click' })}
                        >
                            Falar com o especialista
                        </Button>

                        {/* <small>* De acordo com a compatibilidade das máquinas</small> */}
                        {/* <img className="box" src={boxImg} alt="Box" /> */}
                    </div>
                </div>
                <Button className="bt-fechar climate" onClick={() => {
                    props.onClose();
                }}>
                    <HighlightOffIcon />
                </Button>
            </section>
        </>
    );
}