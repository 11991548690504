import React, { useEffect } from 'react';
import "./home360.component.scss";
import * as PANOLENS from "panolens";
import * as THREE from "three";

import img from '../../../assets/panolens/piso-inferior.jpg';
import imgInfoSpot from '../../../assets/panolens/info-spot.png';
import imgInfoSpotPiso from '../../../assets/panolens/pt-chao.png';
import icoPanoOff from '../../../assets/panolens/ico-pano-off.svg';
import icoPanoOn from '../../../assets/panolens/ico-pano-on.svg';

import { SnackService } from '../../../services/snack.service';
import { LoadVideoBgService } from '../../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../../constants/rotas-videos.constant';
import { sendEvent } from '../../../scripts/events';
import { Button } from '@material-ui/core';
import { Ativa360Service } from '../../../services/ativa360.service';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const panorama = new PANOLENS.ImagePanorama(img);

const viewerHomeInferior = new PANOLENS.Viewer({
    container: document.querySelector("#home-inferior"),
    controlBar: true,
    autoHideInfospot: false,
    //enableControl: 1
});

//viewerHomeInferior.classList.add('home360');
viewerHomeInferior.add(panorama);
viewerHomeInferior.getCamera().fov = 120;
viewerHomeInferior.getCamera().updateProjectionMatrix();
//viewerHomeInferior.OrbitControls.noZoom = true;
// posição inicial camera
viewerHomeInferior.camera.position.set(-0.4603607953241172, 0.12004255638973507, 0.8795781504698509);
//viewerHomeInferior.enableControl(1);

setTimeout(() => {
    (document.querySelectorAll(".panolens-container")[1]).classList.add('inferior');
}, 500);

let x = 50;
let y = -130;
let z = -150;

const createInfoSpot = (pos: any, img: any, hoverTxt: string, send_event: any, nome_video: any) => {
    let position = new THREE.Vector3(pos.x, pos.y, pos.z);
    let infospot = new PANOLENS.Infospot(30, img, false);
    infospot.position.copy(position);
    infospot.addHoverText(hoverTxt);
    infospot.addEventListener('click', () => {
        sendEvent(send_event);
        localStorage.setItem('urlFrom', 'home');
        LoadVideoBgService.sendNomeVideo(nome_video);
    });
    panorama.add(infospot);
}

// Reuniões
createInfoSpot(
    { x: -30, y: 70, z: -390 },
    imgInfoSpot,
    'Reuniões',
    { category: 'home', label: 'menu_pts_reunioes', action: 'click' },
    ROTAVIDEOS.reunioes
);

// Clima
createInfoSpot(
    { x: -170, y: 50, z: -350 },
    imgInfoSpot,
    'Clima',
    { category: 'home', label: 'menu_pts_clima', action: 'click' },
    ROTAVIDEOS.clima
);

// Fórum
createInfoSpot(
    { x: -330, y: 70, z: -330 },
    imgInfoSpot,
    'Fórum',
    { category: 'home', label: 'menu_pts_forum', action: 'click' },
    ROTAVIDEOS.forum
);

// Lives
createInfoSpot(
    { x: -10, y: -50, z: -150 },
    imgInfoSpot,
    'Lives',
    { category: 'home', label: 'menu_pts_lives', action: 'click' },
    ROTAVIDEOS.lives
);

// Visão Reuniões
// createInfoSpot(
//     { x: -70, y: -190, z: -110 },
//     imgInfoSpotPiso,
//     'Visão Reuniões',
//     { category: 'home', label: 'menu_pts_visao_reunioes', action: 'click' },
//     ROTAVIDEOS.homeReunioes
// );

let position2 = new THREE.Vector3(210, -80, -210);
let infospot2 = new PANOLENS.Infospot(30, imgInfoSpotPiso, false);
infospot2.position.copy(position2);
infospot2.addHoverText('Infospot 2 inferior');
infospot2.addEventListener('click', () => {
    SnackService.sendMSG('Patrulha Bayer')
});
//panorama.add(infospot2);


var UA = navigator.userAgent;
//var isAndroid = /\b(Android)\b/i.test(UA);

var body: any = document.documentElement;


export default function Home360Component() {

    const [isAndroid, setIsAndroid] = React.useState<boolean>(/\b(Android|iPhone|Windows Phone|iPad|iPod|BlackBerry|webOS|OculusBrowser|VR)\b/i.test(UA));
    const [isIos, setIsIos] = React.useState<boolean>(/\b(iPhone|iPad|iPod)\b/i.test(UA));
    const [toggleOculus, setToggleOculus] = React.useState<boolean>(Ativa360Service.get360Ativo());
    const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);

    useEffect(() => {
        //viewerHomeInferior.add(panorama);
        if (toggleOculus) {
            viewerHomeInferior.enableControl(1);
            viewerHomeInferior.enableEffect( PANOLENS.MODES.CARDBOARD);
        } else {
            viewerHomeInferior.enableControl(0);
            viewerHomeInferior.enableEffect( PANOLENS.MODES.NORMAL);
        }
    }, [viewerHomeInferior]);

    document.addEventListener('keydown', function (e: any) {
        //console.log(e.keyCode);
        switch (e.keyCode) {
            case 65:
                //alert('left'); ---- A
                x -= 10;
                position2 = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospot2.position.copy(position2);
                break;
            case 68:
                //alert('right'); ---- D
                x += 10;
                position2 = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospot2.position.copy(position2);
                break;
            case 87:
                //alert('up'); ---- W
                y += 10;
                position2 = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospot2.position.copy(position2);
                break;
            case 83:
                //alert('down'); ---- S
                y -= 10;
                position2 = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospot2.position.copy(position2);
                break;
            case 90:
                //alert('profundidade'); ---- Z
                z += 10;
                position2 = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospot2.position.copy(position2);
                break;
            case 88:
                //alert('profundidade'); ---- X
                z -= 10;
                position2 = new THREE.Vector3(
                    x,
                    y,
                    z
                );
                infospot2.position.copy(position2);
                console.log(position2);
                break;
            case 84:
                //alert('profundidade'); ---- T
                // tecla T para debugar posição inicial que deseja
                console.log(viewerHomeInferior.camera.position);
                break;
        }
    });

    const toggleOculusFunc = () => {

        if (!toggleOculus) {
            if (isIos) {
                (DeviceMotionEvent as any).requestPermission();
            }
            viewerHomeInferior.enableControl(1);
            viewerHomeInferior.enableEffect( PANOLENS.MODES.CARDBOARD);
        } else {
            viewerHomeInferior.enableControl(0);
            viewerHomeInferior.enableEffect( PANOLENS.MODES.NORMAL);
        }

        setToggleOculus(!toggleOculus);
        Ativa360Service.toggle360Ativo();
    }

    const toggleFullScreen = () => {

        if (isFullScreen) {
            let doc2: any = document;
            if (doc2.exitFullscreen) {
                doc2.exitFullscreen();
            } else if (doc2.webkitExitFullscreen) { /* Safari */
                doc2.webkitExitFullscreen();
            } else if (doc2.msExitFullscreen) { /* IE11 */
                doc2.msExitFullscreen();
            }
        } else {
            if (body.requestFullscreen) {
                body.requestFullscreen();
            } else if (body.webkitRequestFullscreen) { /* Safari */
                body.webkitRequestFullscreen();
            } else if (body.msRequestFullscreen) { /* IE11 */
                body.msRequestFullscreen();
            }
        }

        setIsFullScreen(!isFullScreen);
    }

    return (
        <>
            <div id="home-inferior">
                { }
                <img className='seta-frente' src={imgInfoSpotPiso} onClick={() => {
                    sendEvent({ category: 'home', label: 'menu_pts_visao_reunioes', action: 'click' });
                    LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeReunioes);
                }} />
            </div>
            {
                // isAndroid &&
                // <>
                //     <Button onClick={toggleOculusFunc} className={'bt-oculus ativo-' + toggleOculus}>
                //         <img className='pano-off' src={icoPanoOff} />
                //         <img className='pano-on' src={icoPanoOn} />
                //     </Button>
                //     <Button onClick={toggleFullScreen} className={'bt bt-full'}>
                //         {
                //             isFullScreen ?
                //                 <><FullscreenExitIcon /></> :
                //                 <><FullscreenIcon /></>
                //         }
                //     </Button>
                // </>
            }
        </>
    );
}