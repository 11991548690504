import React, { useEffect, useState, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { JOAO_PISO_SUPERIOR } from '../../constants/chatBot';
import { UserType } from '../../types/user';
import ico from '../../assets/attendantIco.svg'
import "./chat-fox.page.scss";
import { FinalVideoService } from '../../services/final-video.service';
import { UserContext } from '../../components/userContext';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { sendEvent } from '../../scripts/events';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',

  headerBgColor: '#fff',
  headerFontColor: '#2c3a49',
  headerFontSize: '15px',

  botBubbleColor: '#3a6dd8',
  botFontColor: '#fff',

  userBubbleColor: '#93d131',
  userFontColor: '#fff',
};

const optionStyle = {
  backgroundColor: '#93d131',
  display: 'block',
};

const StyledChatBot = styled(ChatBot)`
  .rsc-content {
    height: calc(360px - 60px);
  }

  @media (max-width: 1024px) {
    .rsc-content {
      height: calc(360px - 100px);
    }
  }

  .rsc-footer {
    display: none;
  }
`;

export enum Attendant { Receptionist, Concierge };

export type ChatBotProps = {
  handleClose: ({ command, metadata }: { command: number, metadata?: any }) => void;
  onToggleFloating: () => void;
  user?: UserType;
  attendant: Attendant;
}

const Chat = ({ user, attendant, handleClose, onToggleFloating }: ChatBotProps) => {
  const firstName = user ? (user.name ? user.name.split(' ')[0] : '') : '';

  const talk = (attendant: Attendant) => JOAO_PISO_SUPERIOR;

  const handleEnd = ({ steps, values }: { steps: any, values: [any] }) => {
    const lastAnswer = values.length > 0 ? values[values.length - 1] : undefined;
    let showFirst: number[] = [];

    if (lastAnswer) {
      if (lastAnswer.option === ROTAVIDEOS.foxXproPlantacao) {
        sendEvent({ category: 'chat_fox', label: 'lavoura', action: 'click' });
        LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.foxXproPlantacao);
      }

    }

    handleClose({ command: lastAnswer.option, metadata: { showFirst } });
  };

  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <StyledChatBot
          handleEnd={handleEnd}
          className={'custom-chat-bot chat-fox ' + talk(attendant).attendant}
          botAvatar={ico}
          headerTitle={talk(attendant).attendant}
          hideSubmitButton={true}
          hideUserAvatar={true}
          botDelay={2000}
          height={'360px'}
          floating={true}
          opened={true}
          steps={talk(attendant).talk}
          bubbleOptionStyle={optionStyle}
          toggleFloating={() => {
            onToggleFloating();
          }}
        />
      </React.StrictMode>
    </ThemeProvider>
  )
}

export default function ChatFoxPage() {

  const [attendant, setAttendant] = useState(Attendant.Concierge);
  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const userProps = useContext(UserContext);

  useEffect(() => {
    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('foxXpro') > -1) {
        setShowBolinhas(true);
      }
    });

    document.body.classList.add("chat-fox");

    return () => {
      document.body.classList.remove("chat-fox");
    };
  }, []);

  return (
    <>
      {showBolinhas && (
        <Chat
          onToggleFloating={() => {
            //props.onCloseX
            if (window.innerWidth < 1024 && localStorage.getItem('urlFrom') === 'homeRisco') {
              LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeRisco);
            } else {
              LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.homeSuperior);
            }
          }}
          handleClose={() => {
            //
          }}
          user={userProps.user}
          attendant={attendant} />
      )}
    </>
  );
}
