import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as slidesJson from './arena-inovacao.json';
import ArenaSlidesComponent from '../../../components/arena-slides/arena-slides.component';

export type ArenaInovacaoProps = {
    onClose: () => void;
}


export default function ArenaInovacaoPage(props: ArenaInovacaoProps) {
    
    return (
        <>
            <ArenaSlidesComponent
                slidesJson={slidesJson}
                colorDark={'rgb(137,211,41)'}
                colorLight={'rgb(172, 236, 88)'}
                transform={'scale(0.58) translate(21vw,-18vh)'}
                onClose={() => {
                    props.onClose();
                }}
            />
        </>
    );
}