
import React from 'react';

let is360Ativo = false;

export const Ativa360Service = {
    toggle360Ativo: () => {
        is360Ativo = !is360Ativo;
        return is360Ativo
    },
    get360Ativo: () => is360Ativo
};