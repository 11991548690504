import { useState } from "react";

export const useSelect = (initialValue: any) => {
    const [value, setValue] = useState(initialValue);
    const [IsOpenSelec, setIsOpenSelec] = useState(initialValue);

    return {
        value,
        IsOpenSelec,
        setValue,
        setIsOpenSelec,
        reset: () => setValue(""),
        setClose: () => setIsOpenSelec(false),
        bind: {
            value,
            IsOpenSelec,
            onOpen: () => {
                setIsOpenSelec(true);
            },
            onClose: () => {
                setIsOpenSelec(false);
            },
            onChange: (event: React.ChangeEvent<{ value: unknown }>) => {
                setValue(event.target.value as any);
                setIsOpenSelec(false);
            }
        }
    };
};