import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as slidesJson from './arena-produtividade.json';
import ArenaSlidesComponent from '../../../components/arena-slides/arena-slides.component';

export type ArenaProdutividadePageProps = {
    onClose: () => void;
}


export default function ArenaProdutividadePage(props: ArenaProdutividadePageProps) {
    
    return (
        <ArenaSlidesComponent
            onClose={() => {
                props.onClose();
            }}
            slidesJson={slidesJson}
            colorDark={'rgb(0, 68, 34)'}
            colorLight={'rgb(3, 97, 50)'}
            transform={'scale(0.6) translate(0,-10vh)'}
        />
    );
}