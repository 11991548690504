import React, { useEffect, useState } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import HoverMessage from '../../components/hover-message/hover-message.component';
import { sendEvent } from '../../scripts/events';
import { FinalVideoService } from '../../services/final-video.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import ArenaRiscoPage from '../arenas/arena-risco/arena-risco.page';
import './risco.page.scss';

export default function RiscoPage() {
  const [message, setMessage] = useState('');
  const handleMouse = (message: string) => setMessage(message);
  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showArenaRiscoSlide, setShowArenaRiscoSlide] = React.useState<boolean>(false);
  const [showDesktop, setShowDesktop] = React.useState<boolean>(true);

  useEffect(() => {

    if (window.innerWidth < 1024) {
      setShowDesktop(false);
    }

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf(ROTAVIDEOS.risco) > -1 || window.location.href.indexOf(ROTAVIDEOS.voltarRisco) > -1) {
        setShowBolinhas(true);
      }
    });

    document.body.classList.add("bt-voltar-superior-ativo");
    document.body.classList.add("page-risco");

    return () => {
      document.body.classList.remove("bt-voltar-superior-ativo");
      document.body.classList.remove("page-risco");
    };
  }, []);

  return (
    <section className='risco'>
      {
        showBolinhas &&
        <>
          <FullscreenHud>
            <FullscreenHudElement key="configs" highlight={assets.empty} >
              <>
                <defs>
                  <filter id="blur">
                    <feGaussianBlur stdDeviation="4" />
                  </filter>
                </defs>
              </>
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-1" highlight={assets.empty} >
              <>
                <circle cx={showDesktop ? "700" : "430"} cy={showDesktop ? "850" : "1000"} r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx={showDesktop ? "700" : "430"} cy={showDesktop ? "850" : "1000"} r="10" className='circle-button borda' />
                <circle cx={showDesktop ? "700" : "430"} cy={showDesktop ? "850" : "1000"} r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_risco', label: 'Risc_ptserv', action: 'click' });
              setShowArenaRiscoSlide(true);
            }}>
              <rect x={showDesktop ? '520' : '250'} y={showDesktop ? '740' : '870'} width='400' height='200'
                onMouseOver={() => handleMouse('Conheça os serviços para minímizar os riscos do seu negócio')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-3" highlight={assets.empty} >
              <>
                <circle cx="1310" cy="580" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1310" cy="580" r="10" className='circle-button borda' />
                <circle cx="1310" cy="580" r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-2" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_risco', label: 'Risc_pthero', action: 'click' });
              LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.totem);
            }}>
              <rect x='1100' y='480' width='400' height='200'
                onMouseOver={() => handleMouse('Proteja seus investimento do plantio à colheita: conheça o seguro agrícola!')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

          </FullscreenHud>
          <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
            <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
          </div>

          {
            showArenaRiscoSlide &&
            <ArenaRiscoPage onClose={() => {
              setShowArenaRiscoSlide(false);
            }} />
          }
        </>
      }
    </section>
  );
}
