import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import './arena-slides.component.scss';
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ItemSlideComponent from './item-slide/item-slide.component';
import ArenaSlidesModel from '../../models/arena-slides.model';
import { createGlobalStyle } from 'styled-components';
import { MaisServicosService } from '../../services/mais-servicos.service';

export type ArenaSlidesComponentProps = {
    onClose?: () => void;
    openMoreServices?: () => void;
    slidesJson: any;
    colorDark: any;
    colorLight: any;
    transform: any;
    typeCursos?: any;
    firstSlide?: { showFirst: number[] };
}

function mapOrder(array: Array<ArenaSlidesModel>, order: number[], key: string) {
    const copy = [...array];
    order.slice().reverse().forEach((orderItem) => {
        copy.forEach((item, index) => {
            if (item.id === orderItem) {
                copy.splice(index, 1);
                copy.unshift(item);
            }
        });
    });

    return copy;
};

export default function ArenaSlidesComponent(props: ArenaSlidesComponentProps) {
    const [isFullScreen, setIsFullScreen] = React.useState<boolean>(true);
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
    const [animationActive, setAnimationActive] = React.useState<boolean>(false);

    const GlobalStyles = createGlobalStyle`
        html {
            --color-dark: ${props.colorDark};
            --color-light: ${props.colorLight};
            --transform: ${props.transform};
        }
    `;

    const customRenderItem = (item: any, props: any) => <item.type {...item.props} {...props} />;
    const [slidesData, setSlidesData] = useState<Array<ArenaSlidesModel>>();

    useEffect(() => {
        setTimeout(() => {
            setAnimationActive(true);
        }, 100);
    }, []);

    useEffect(() => {
        if (props.firstSlide?.showFirst.length > 0) {
            const orderedArray = mapOrder(props.slidesJson.default.slides, props.firstSlide.showFirst, 'id');
            setSlidesData(orderedArray);
        } else {
            setSlidesData(props.slidesJson.default.slides);
        }
    }, [props.firstSlide, props.slidesJson.default.slides]);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    }

    const updateCurrentSlide = (index: number, item: any) => {
        setIsPlaying(true);
        setIsPlaying(false);
    };

    return (
        <>
            <GlobalStyles />
            <section className={"arena inovacao flex flex-ch flex-cv" + " curso-" + props.typeCursos + " animation-" + animationActive}>
                <div className={isFullScreen ? "area-transform" : "area-transform mini"}>
                    {slidesData &&
                        <Carousel onChange={updateCurrentSlide} renderItem={customRenderItem} showThumbs={false} swipeable={false} showStatus={false} useKeyboardArrows>
                            {
                                slidesData?.map((item, indexItem) => (
                                    <ItemSlideComponent
                                        isPlaying={isPlaying}
                                        showMoreServices={true}
                                        key={indexItem}
                                        slideData={item}
                                        typeCursos={props.typeCursos}
                                        openMoreServices={() => {
                                            MaisServicosService.send(true);
                                        }}
                                    />
                                ))
                            }
                        </Carousel>}
                    {/* <Button className="bt-full" onClick={toggleFullScreen}>
                        {
                            isFullScreen ?
                                <><FullscreenExitIcon /> Fechar tela cheia</> :
                                <><FullscreenIcon /> Ver em tela cheia</>
                        }
                    </Button> */}
                    <Button className="bt-fechar" onClick={() => props.onClose()}>
                        <HighlightOffIcon />
                    </Button>
                </div>
            </section>
        </>
    );
}