import { Button, FormControl, FormControlLabel, FormHelperText, Checkbox, TextField } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import "./modal-convidado.component.scss";
import imgMulher from "../../assets/modal-login/mulher.png";
import { useInput } from '../../hooks/useInput.hook';
import { sendEvent } from '../../scripts/events';
import { UserContext } from '../userContext';
import InputMask from 'react-input-mask';

export interface ModalConvidadosProps {
    onClose: () => void;
    entrarConvidado: (nome: string) => void;
    senhaADM: string;
    isPublic?: boolean;
}

export default function ModalConvidadoComponent(props: ModalConvidadosProps) {

    const [animationActive, setAnimationActive] = React.useState<boolean>(false);
    const [senhaErro, setSenhaErro] = React.useState<boolean>(false);
    const [nomeErro, setNomeErro] = React.useState<boolean>(false);
    const { value: senhaText, bind: bindSenhaText } = useInput('');
    const { value: nomeText, bind: bindNomeText, setValue: setNomeText } = useInput('');
    const userProps = useContext(UserContext);

    const [celularErro, setCelularErro] = React.useState<boolean>(false);
    const { value: celularText, bind: bindCelularText, setValue: setCelularText } = useInput('');

    useEffect(() => {
        setTimeout(() => {
            setAnimationActive(true);
            if (userProps.user) {
                setNomeText(userProps.user.email);
                setCelularText(userProps.user.phone);
            }
        }, 100);
    }, [props]);

    const closeModal = () => {
        props.onClose();
    }

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const entrarConvidado = () => {
        if (props.senhaADM !== senhaText && !props.isPublic) {
            setSenhaErro(true);
        }

        if (!validateEmail(nomeText)) {
            setNomeErro(true);
        }

        if (!celularText) {
            setCelularErro(true);
            return;
        }

        if (validateEmail(nomeText) && (props.senhaADM === senhaText || props.isPublic) && celularText) {
            if (props.isPublic) {
                sendEvent({ category: 'live_publica', label: (nomeText + '/' + celularText), action: 'click' });
                //sendEvent({ category: 'live_publica_cel', label: celularText, action: 'click' });
            } else {
                sendEvent({ category: 'convidado', label: (nomeText + '/' + celularText), action: 'click' });
                //sendEvent({ category: 'live_convidado_cel', label: celularText, action: 'click' });
            }
            props.entrarConvidado(nomeText.split('@')[0]);
        }
    }

    return (
        <>
            <section className={'modal-convidado' + " animation-" + animationActive}>
                <div className="box flex flex-wr flex-sp">
                    <div className="box-img flex flex-bv">
                        <img src={imgMulher} alt="Carol" />
                    </div>
                    <div className="box-form">
                        {
                            props.isPublic ?
                                <h2>
                                    Live pública
                                </h2>
                                :
                                <h2>
                                    Live para convidados
                                </h2>
                        }

                        {
                            props.isPublic ?
                                <p>
                                    Insira aqui seu e-mail para entrar na Live:
                                </p>
                                :
                                <p>
                                    Insira aqui a senha que recebeu no seu convite para a Live:
                                </p>
                        }

                        <div className="campos flex flex-wr flex-sp">
                            {
                                !props.isPublic &&
                                <FormControl>
                                    <TextField label="Digite a senha" variant="outlined" {...bindSenhaText} />
                                    {
                                        senhaErro &&
                                        <FormHelperText>Senha não confere</FormHelperText>
                                    }
                                </FormControl>
                            }
                            <FormControl className={'live-public-' + props.isPublic}>
                                <TextField label="Digite seu e-mail" variant="outlined" {...bindNomeText} />
                                {
                                    nomeErro &&
                                    <FormHelperText>Digite um e-mail válido</FormHelperText>
                                }
                            </FormControl>
                            <FormControl>
                                <InputMask mask="(99) 9 9999-9999" {...bindCelularText}>
                                    {
                                        (bindCelularText: any) =>
                                            <TextField label="Digite seu celular com DDD" variant="outlined" {...bindCelularText} />
                                    }
                                </InputMask>
                                {
                                    celularErro &&
                                    <FormHelperText>Digite o celular</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className="bts">
                            <FormControlLabel control={<Checkbox defaultChecked />} label={<>
                                Aceito receber comunicações e interagir no Espaço Bayer. <a href="https://www.agro.bayer.com.br/politica-de-privacidade" target="_blank">Termos e condições</a>
                            </>} />
                            <Button onClick={entrarConvidado} className="bt">
                                Entrar
                            </Button>
                            <Button onClick={closeModal} className="bt cad">
                                Voltar
                            </Button>
                        </div>
                    </div>
                    <Button
                        className='closeModal'
                        aria-label='Fechar'
                        onClick={() => closeModal()}
                    />
                </div>
            </section>
        </>
    );
}