import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import './modal.scss';

export interface ModalProps {
  onClose: () => void;
  content: any;
}

export default function Modal(props: ModalProps) {
  const [show, setShow] = useState(true);
  const [animationActive, setAnimationActive] = React.useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimationActive(true);
    }, 100);
  }, []);

  async function onClose() {
    setShow(false);
    props.onClose();
  }

  return (
    <>
      {show && (
        <div className={'modal-wrapper' + " animation-" + animationActive}>
          <article className='modal'>
            <section className='main'>
              {props.content}
              <Button
                className='closeModal'
                aria-label='Fechar'
                onClick={() => onClose()}
              />
            </section>
          </article>
        </div>
      )}
    </>
  )
};
