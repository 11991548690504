
import EnvironmentModel from "./environment.model"

let env: EnvironmentModel = {
    env: "local",
    serverUrl: "http://localhost:8888/adm-espaco-bayer/",
    apiOrbiaUrl: 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/'
};

const getEnv = (urlAtual: string) => {
    if ((urlAtual).indexOf('sitebayer2') >= 0) { // hml
        env = {
            env: "hml",
            serverUrl: "https://a7apps.com/hml/bayer-wave2-back/",
            apiOrbiaUrl: 'http://bayerapihml-env.eba-txavnq2p.us-east-2.elasticbeanstalk.com/'
        };
    } else if ((urlAtual).indexOf('espaco.bayer') >= 0) { // prod
        env = {
            env: "prd",
            serverUrl: "https://painelespacobayer.online/",
            apiOrbiaUrl: 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/'
        };
    } else if ((urlAtual).indexOf('localhost') >= 0) { // local
        env = {
            env: "local",
            serverUrl: "http://localhost:8888/adm-espaco-bayer/",
            apiOrbiaUrl: 'http://localhost:3001/'
        };
    } else if ((urlAtual).indexOf('espacobayer.a7apps') >= 0) { // hml A7Apps
        env = {
            env: "local",
            serverUrl: "https://a7apps.com/hml/bayer-wave2-back/",
            apiOrbiaUrl: 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/'
        };
    }

    return env;
}

export const environment: EnvironmentModel = getEnv(window.location.href);
