import React, { useEffect, useState } from 'react';
import { assets } from '../../assets';
import { videoControl, audioControl, CeContainer } from '@dream-pixel/crew-engine-web';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { useHistory } from "react-router-dom";
import './videos-bg.scss';
import { FinalVideoService } from '../../services/final-video.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';

export default function VideosBgComponent() {

    let history = useHistory();
    const [showBG, setShowBG] = useState(true);

    useEffect(() => {
        LoadVideoBgService.getNomeVideo().subscribe((nomeVideo: string) => {

            if (
                nomeVideo === ROTAVIDEOS.feira ||
                nomeVideo === ROTAVIDEOS.showRural ||
                nomeVideo === ROTAVIDEOS.agrobrasilia ||
                nomeVideo === ROTAVIDEOS.tecnoshow ||
                nomeVideo === ROTAVIDEOS.expodireto
            ) {
                setShowBG(false);
                history?.push({
                    pathname: nomeVideo
                })
            } else {

                setShowBG(true);

                // loadVideo(nomeVideo).then(() => {
                //     FinalVideoService.sendFinal(true);
                // });

                history?.push({
                    pathname: nomeVideo
                });

                if (window.innerWidth < 1024) {
                    if (nomeVideo === ROTAVIDEOS.entradaInferior) {
                        loadVideo(nomeVideo).then(() => {
                            FinalVideoService.sendFinal(true);
                        });
                    } else if (nomeVideo === ROTAVIDEOS.chatCarol) {
                        loadVideo(nomeVideo).then(() => {
                            FinalVideoService.sendFinal(true);
                        });
                    } else {
                        setTimeout(() => {
                            FinalVideoService.sendFinal(true);
                        }, 500);
                    }
                } else {
                    loadVideo(nomeVideo).then(() => {
                        FinalVideoService.sendFinal(true);
                    });
                }
            }

        });
    }, []);

    async function loadVideo(name: string | any) {
        if (!name) {
            return;
        }

        if (name === ROTAVIDEOS.entradaInferior) {
            audioControl.setAudio(assets.music);
            localStorage.setItem('btPularIntroVisible', 'true');
            setTimeout(() => {
                audioControl.disableAudio();
                document.body.classList.add("hide-audio");
            }, 32000);
        }

        const assetName = Object.getOwnPropertyNames(assets).find(prop => prop === name);
        const asset = (assets as any)[assetName];
        await videoControl.autoPlayToEnd(asset);
    }


    return (
        <>
            <CeContainer background='background-pattern'>
            </CeContainer >
        </>
    );
}