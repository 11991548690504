import React, { useEffect, useContext, useState } from 'react';
import "./mais-servicos.page.scss";
import impulsoImg from "../../assets/menu/impulso-logo-branco.png";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarIcon from '@material-ui/icons/Star';
import logo from "../../assets/menu/logo-espaco-bayer.png";
import Slider from '@material-ui/core/Slider';
import ArenaSlidesModel from '../../models/arena-slides.model';
import CardServicosComponent from './card-servicos/card-servicos.component';
import ItemSlideComponent from '../../components/arena-slides/item-slide/item-slide.component';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import * as arenaInovacaoJson from '../arenas/arena-inovacao/arena-inovacao-filtro.json';
import * as arenaInovacaoFieldviewJson from '../arenas/arena-inovacao/arena-inovacao-fieldview.json';
import * as arenaNegociosJson from '../arenas/arena-negocios/arena-negocios.json';
import * as arenaProdutividadeJson from '../arenas/arena-produtividade/arena-produtividade.json';
import * as arenaRiscoJson from '../arenas/arena-risco/arena-risco.json';
import { UserContext } from '../../components/userContext';
import { useSelect } from '../../hooks/useSelect.hook';
import { FlashOnRounded } from '@material-ui/icons';

function valuetext(value: number) {
    return `${value.toLocaleString('pt-BR')}`;
}

function valueLabelFormat(value: number) {
    return `${value.toLocaleString('pt-BR')}`;
}

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 250000,
        label: '250k',
    },
    {
        value: 500000,
        label: '500k',
    },
    {
        value: 750000,
        label: '750k',
    },
    {
        value: 1000000,
        label: '1M',
    }
];

export type MaisServicosPageProps = {
    onClose: () => void;
}

export default function MaisServicosPage({ onClose }: MaisServicosPageProps) {
    const userProps = useContext(UserContext);

    const [value, setValue] = useState<number[]>([0, 1000000]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [itemMaisServicos, setItemMaisServicos] = useState<ArenaSlidesModel>();
    const [maisServicosList, setMaisServicosList] = useState<ArenaSlidesModel[]>();

    const [risk, setRisk] = useState(false);
    const [bussiness, setBussiness] = useState(false);
    const [fieldview, setFieldview] = useState(false);
    const [inovation, setInovation] = useState(false);
    const [productivity, setProductivity] = useState(false);

    let anyArenaInovacaoJson: any = arenaInovacaoJson;
    let anyArenaInovacaoFieldviewJson: any = arenaInovacaoFieldviewJson;
    let anyArenaNegociosJson: any = arenaNegociosJson;
    let anyArenaProdutividadeJson: any = arenaProdutividadeJson;
    let anyArenaRiscoJson: any = arenaRiscoJson;

    const [animationActive, setAnimationActive] = useState<boolean>(false);

    const {
        value: ranking,
        setValue: setRanking,
        bind: bindRanking,
        IsOpenSelec: isOpenRanking,
        setClose: setCloseRanking
    } = useSelect(0);

    const toggleByArea = (area: string) => {
        setRisk(false);
        setBussiness(false);
        setInovation(false);
        setFieldview(false);
        setProductivity(false);

        switch (area) {
            case 'risk':
                return setRisk(!risk);
            case 'bussiness':
                return setBussiness(!bussiness);
            case 'inovation':
                return setInovation(!inovation);
            case 'fieldview':
                return setFieldview(!fieldview);
            case 'productivity':
                return setProductivity(!productivity);
        }
    }

    function dynamicSort(property: any) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a: any, b: any) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    useEffect(() => {
        const list = [];
        if (!risk && !bussiness && !inovation && !fieldview && !productivity) {
            list.push(...anyArenaRiscoJson.default.slides.sort(dynamicSort("title")));
            list.push(...anyArenaNegociosJson.default.slides.sort(dynamicSort("title")));
            list.push(...anyArenaInovacaoJson.default.slides.sort(dynamicSort("title")));
            list.push(...anyArenaInovacaoFieldviewJson.default.slides.sort(dynamicSort("title")));
            list.push(...anyArenaProdutividadeJson.default.slides.sort(dynamicSort("title")));
        } else {
            if (risk) {
                list.push(...anyArenaRiscoJson.default.slides.sort(dynamicSort("title")));
            }
            if (bussiness) {
                list.push(...anyArenaNegociosJson.default.slides.sort(dynamicSort("title")));
            }
            if (inovation) {
                list.push(...anyArenaInovacaoJson.default.slides.sort(dynamicSort("title")));
            }
            if (fieldview) {
                list.push(...anyArenaInovacaoFieldviewJson.default.slides.sort(dynamicSort("title")));
            }
            if (productivity) {
                list.push(...anyArenaProdutividadeJson.default.slides.sort(dynamicSort("title")));
            }
        }

        setTimeout(() => {
            setAnimationActive(true);
        }, 100);

        const startValue = value instanceof Array ? value[0] : value;
        const endValue = value instanceof Array ? value[1] : 1000000;
        const newList = list.filter((item) =>
            (parseInt(item.points_redemption) >= startValue && parseInt(item.points_redemption) <= endValue));

        setMaisServicosList(newList);
    }, [risk, bussiness, inovation, fieldview, productivity, value]);

    const setDefault = () => {
        setRisk(false);
        setBussiness(false);
        setInovation(false);
        setProductivity(false);
        setFieldview(false);

        setValue([0, 1000000]);
    }

    const handleChange = (event: any, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    const retornoCard = (itemService: ArenaSlidesModel) => {
        setItemMaisServicos(itemService);
        setTimeout(() => {
            setIsShowModal(true);
        }, 100);
    }

    const closeModal = () => {
        setIsShowModal(false);
    }

    const Stars = () => {
        const allStars = [];
        let yellowStarsTotal = 0;
        for (let index = 0; index < userProps.user.stars; index++) {
            allStars.push(<StarIcon />);
            yellowStarsTotal++;
        }
        let grayStarsTotal = 5 - yellowStarsTotal;
        for (let index = 0; index < grayStarsTotal; index++) {
            allStars.push(<StarIcon className="gray" />);
        }
        return (<>{allStars}</>);
    }

    const onChangeFiltro = (valRanking?: any) => {
        retornoCard(maisServicosList[valRanking]);
        setRanking(valRanking);
        setCloseRanking();
    };

    return (
        <section className={"more-services flex" + " animation-" + animationActive}>
            <div className="area-filter flex flex-cv flex-wr">
                <img src={logo} alt="Logo" />
                <div className="filter">
                    <h1>
                        Mais serviços <br />
                        disponíveis <br />
                        para você.
                    </h1>
                    {userProps && userProps.user && (
                        <div className="box-points flex flex-ch">
                            <div className="esq">
                                <small>Você possui,</small>
                                <h3>{userProps.user?.points}</h3>
                                <small>Pontos Impulso Bayer.</small>
                            </div>
                            <ol className="right flex flex-cv flex-ch">
                                <Stars />
                            </ol>
                        </div>
                    )}
                    <div className="filters">
                        <div className="box-filter">
                            <div className="title flex flex-sp flex-cv">
                                <h5>Filtrar por categoria:</h5>
                                <Button onClick={() => setDefault()}>Limpar filtros</Button>
                            </div>
                            <div className="categories flex flex-sp flex-wr">
                                <Button className={productivity ? 'active' : ''} onClick={() => toggleByArea('productivity')}>Produtividade</Button>
                                <Button className={risk ? 'active' : ''} onClick={() => toggleByArea('risk')}>Risco</Button>
                                <Button className={bussiness ? 'active' : ''} onClick={() => toggleByArea('bussiness')}>Negócios</Button>
                                <Button className={inovation ? 'active' : ''} onClick={() => toggleByArea('inovation')}>Inovação</Button>
                                <Button className={fieldview ? 'active' : ''} onClick={() => toggleByArea('fieldview')}>FieldView™</Button>
                            </div>
                        </div>
                        <div className="box-filter">
                            <div className="title">
                                <h5>Filtrar por Pontuação Média:</h5>
                            </div>
                            <Slider
                                value={value}
                                min={0}
                                max={1000000}
                                valueLabelFormat={valueLabelFormat}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                getAriaValueText={valuetext}
                                marks={marks}
                            />
                        </div>
                    </div>
                </div>
                <span className="powered">
                    Powered by: <img src={impulsoImg} alt="Impulso Bayer" />
                </span>
            </div>
            <div className="area-text flex flex-wr">
                <div className="lista-suspensa">
                    <FormControl>
                        <InputLabel>Todos os serviços:</InputLabel>
                        <Select
                            {...bindRanking}
                            onChange={(event) => onChangeFiltro(event.target.value)}
                            open={isOpenRanking}
                        >
                            {maisServicosList?.map((item: ArenaSlidesModel, indexSelect) => (
                                <MenuItem className={item.type} value={indexSelect}>{item.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                {maisServicosList?.map((item: ArenaSlidesModel) => (
                    <CardServicosComponent maisServicos={item} retornoCard={
                        () => retornoCard(item)
                    } />
                ))}
            </div>
            <Button className="bt-fechar" onClick={onClose}>
                <HighlightOffIcon />
            </Button>
            {itemMaisServicos && (
                <div className={isShowModal ? "modal-more-services active" : "modal-more-services"}>
                    <ItemSlideComponent
                        isPlaying={true}
                        key={itemMaisServicos.id}
                        slideData={itemMaisServicos}
                        showMoreServices={false}
                    />
                    <Button onClick={closeModal} className={"bt-fechar " + itemMaisServicos.type}>
                        <HighlightOffIcon />
                    </Button>
                </div>
            )}
        </section>
    );
}