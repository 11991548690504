import React, { useEffect } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import { sendEvent } from '../../scripts/events';
import { FinalVideoService } from '../../services/final-video.service';
import YouTubePage from '../youtube/youtube.page';
import InsuranceSimulator from '../insurance-simulator/insurance-simulator.page';
import './totem.page.scss';

export default function TotemPage() {

  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showVideoOqueE, setShowVideoOqueE] = React.useState<boolean>(false);
  const [showVideoDiferenciais, setShowVideoDiferenciais] = React.useState<boolean>(false);
  const [showSimule, setShowSimule] = React.useState<boolean>(false);
  const [showDesktop, setShowDesktop] = React.useState<boolean>(true);

  useEffect(() => {

    if (window.innerWidth < 1024) {
      setShowDesktop(false);
    }

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('totem') > -1) {
        setShowBolinhas(true);
      }
    });

    document.body.classList.add("page-totem");
    document.body.classList.add("bt-inicio_totem-ativo");

    return () => {
      document.body.classList.remove("page-totem");
      document.body.classList.remove("bt-inicio_totem-ativo");
    };
  }, []);

  return (
    <section className='totem'>
      {
        showBolinhas &&
        <>
          <FullscreenHud>
            <FullscreenHudElement key="configs" highlight={assets.empty} >
              <>
                <defs>
                  <filter id="blur">
                    <feGaussianBlur stdDeviation="4" />
                  </filter>
                </defs>
              </>
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-2" highlight={assets.empty} >
              <>
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "290" : "-300"} r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "290" : "-300"} r="10" className='circle-button borda' />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "290" : "-300"} r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-1" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'totem', label: 'Risc_ptoq', action: 'click' });
              setShowVideoOqueE(true);
            }}>
              {
                showDesktop ?
                  <rect x='600' y='130' width='680' height='200' />
                  :
                  <rect x="200" y="-480" width="1380" height="320" />
              }
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-4" highlight={assets.empty} >
              <>
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "490" : "120"} r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "490" : "120"} r="10" className='circle-button borda' />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "490" : "120"} r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-3" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'totem', label: 'Risc_ptdif', action: 'click' });
              setShowVideoDiferenciais(true);
            }}>
              {
                showDesktop ?
                  <rect x='600' y='330' width='680' height='200' />
                  :
                  <rect x="200" y="-60" width="1380" height="320" />
              }
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-6" highlight={assets.empty} >
              <>
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "690" : "520"} r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "690" : "520"} r="10" className='circle-button borda' />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "690" : "520"} r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-5" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'totem', label: 'Risc_ptsimule', action: 'click' });
              setShowSimule(true);
            }}>
              {
                showDesktop ?
                  <rect x='600' y='535' width='680' height='200' />
                  :
                  <rect x="200" y="370" width="1380" height="320" />
              }
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-8" highlight={assets.empty} >
              <>
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "890" : "950"} r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "890" : "950"} r="10" className='circle-button borda' />
                <circle cx={showDesktop ? "940" : "270"} cy={showDesktop ? "890" : "950"} r="10" className='circle-button' strokeWidth='10px' />
              </>
            </FullscreenHudElement>
            <FullscreenHudElement key="opt-7" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'totem', label: 'Risc_ptfale', action: 'click' });
              window.open('https://wa.me/551142103591?text=Olá!%20Vim%20através%20do%20Espaço%20Bayer%20e%20gostaria%20de%20mais%20informações%20sobre%20o%20Seguro%20Agrícola.%20');
            }}>
              {
                showDesktop ?
                  <rect x='600' y='740' width='680' height='200' />
                  :
                  <rect x="200" y="800" width="1380" height="320" />
              }
            </FullscreenHudElement>

          </FullscreenHud>

          {
            showVideoOqueE &&
            <YouTubePage
              videoId={'MUFk-60NbjM'}
              videoName={'climate'}
              onClose={() => {
                setShowVideoOqueE(false);
              }} />
          }

          {
            showVideoDiferenciais &&
            <YouTubePage
              videoId={'QvyKjzMC45s'}
              videoName={'climate'}
              onClose={() => {
                setShowVideoDiferenciais(false);
              }} />
          }

          {
            showSimule &&
            <InsuranceSimulator onClose={() => {
              setShowSimule(false);
            }} />
          }
        </>
      }
    </section>
  );
}
