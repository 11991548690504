import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as slidesJson from './arena-gestao-adm-cursos.json';
import ArenaSlidesComponent from '../../../components/arena-slides/arena-slides.component';

export type ArenaCursosPageProps = {
    onClose: () => void;
    metadata?: any;
}

export default function ArenaGestaoADMCursosPage({ onClose, metadata }: ArenaCursosPageProps) {

    return (
        <ArenaSlidesComponent
            firstSlide={metadata}
            onClose={onClose}
            slidesJson={slidesJson}
            colorDark={'#102d44'}
            colorLight={'#f7fcff'}
            transform={'scale(0.5) translate(-16vw,-20vh)'}
            typeCursos={true}
            openMoreServices={()=>{}}
        />
    );
}