import React, { useEffect, useState } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import HoverMessage from '../../components/hover-message/hover-message.component';
import { sendEvent } from '../../scripts/events';
import { FinalVideoService } from '../../services/final-video.service';
import YouTubePage from '../youtube/youtube.page';
import BoardProductivityPage from '../board-patrulha/board-productivity.page';
import ArenaProdutividadePage from '../arenas/arena-produtividade/arena-produtividade.page';
import "./produtividade.page.scss"

export default function ProdutividadePage() {
  const [message, setMessage] = useState('');
  const handleMouse = (message: string) => setMessage(message);
  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [showVideoPatrulha, setShowVideoPatrulha] = React.useState<boolean>(false);
  const [showBoardPatrulha, setShowBoardPatrulha] = React.useState<boolean>(false);
  const [showArenaProdutividadeSlide, setShowArenaProdutividadeSlide] = React.useState<boolean>(false);

  useEffect(() => {
    document.body.classList.add("bt-voltar-superior-ativo");

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('produtividade') > -1) {
        setShowBolinhas(true);
        if (localStorage.getItem('tarefaTipo') === 'Tarefa 2') {
          setShowVideoPatrulha(true);
        }
      }
    });

    document.body.classList.add("produtividade");

    return () => {
      document.body.classList.remove("bt-voltar-superior-ativo");
      document.body.classList.remove("produtividade");
    };
  }, []);

  return (
    <section className='produtividade'>
      {
        showBolinhas &&
        <>
          <FullscreenHud>
            <FullscreenHudElement key="configs" highlight={assets.empty} >
              <>
                <defs>
                  <filter id="blur">
                    <feGaussianBlur stdDeviation="4" />
                  </filter>
                </defs>
                <circle cx="1100" cy="915" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1100" cy="915" r="10" className='circle-button borda' />

                <circle cx="1545" cy="590" r="25" className='circle-button blur' style={{ filter: "url(#blur)" }} />
                <circle cx="1545" cy="590" r="10" className='circle-button borda' />
              </>
            </FullscreenHudElement>

            <FullscreenHudElement key="slider-1" highlight={assets.empty} >
              <circle cx="1100" cy="915" r="10" className='circle-button' strokeWidth='10px' />
            </FullscreenHudElement>
            <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_produtividade', label: 'Prod_ptserv', action: 'click' });
              setShowArenaProdutividadeSlide(true);
            }}>
              <rect x='850' y='820' width='400' height='200'
                onMouseOver={() => handleMouse('Conheça os serviços para ter mais produtividade')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

            <FullscreenHudElement key="opt-1" highlight={assets.empty} >
              <circle cx="1545" cy="590" r="10" className='circle-button' strokeWidth='10px' />
            </FullscreenHudElement>
            <FullscreenHudElement key="slider" highlight={assets.empty} onClick={() => {
              sendEvent({ category: 'arena_produtividade', label: 'Prod_pthero', action: 'click' });
              setShowVideoPatrulha(true);
            }}>
              <rect x='1350' y='480' width='400' height='200'
                onMouseOver={() => handleMouse('Você sabia que nós temos um serviço exclusivo de monitoramento da sua lavoura contra pragas? Clique aqui que te mostro como ele funciona!')}
                onMouseOut={() => handleMouse('')}
              />
            </FullscreenHudElement>

          </FullscreenHud>
          <div className={message ? 'msg-hover flex flex-ch active' : 'msg-hover flex flex-ch'}>
            <HoverMessage className="msg-hover-balao">{message}</HoverMessage>
          </div>

          {
            showVideoPatrulha &&
            <YouTubePage
              videoId={'Iww2UlMmO34'}
              videoName={'patrulha'}
              onClose={() => {
                setShowBoardPatrulha(true);
                setShowVideoPatrulha(false);
              }} />
          }

          {
            showBoardPatrulha &&
            <BoardProductivityPage onClose={() => {
              setShowBoardPatrulha(false);
            }} />
          }

          {
            showArenaProdutividadeSlide &&
            <ArenaProdutividadePage onClose={() => {
              setShowArenaProdutividadeSlide(false);
            }} />
          }
        </>
      }
    </section>
  );
}
