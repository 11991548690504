import { useState, useEffect } from 'react';
import axios from 'axios';
import { retrieveGigya, retrieveUserInfo, setProvider, clearUserInfo } from './userService';
import { UserType } from '../../types/user';
import { sendEvent } from '../../scripts/events';
import { environment } from "../../environments/environment";

export function useUser() {
  const [ user, setUser ] = useState<UserType>(null);

  const userInfo = retrieveUserInfo();
  const gigya = retrieveGigya();

  // TODO
  //const URL = 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/';
  const URL = environment.apiOrbiaUrl;
  const ORBIA = true;

  const updateUserInfoByDoc = ({ doc }: { doc: string; }) => new Promise((resolve, reject) => {
    axios.get(`${URL}api/orbia/user-info-doc`, {
      params: {
        doc,
      }
    }).then((response) => {
      if (!response.data.details ) {
        throw new Error('Não foi possivel acessar os dados do usuário');
      }
      return response.data.details;
    }).then((details) => {
      const user = {
        UID: details.loyaltyMemberCode,
        email: details.email,
        name: details.nome,
        CPF: details.cPF,
        stars: details.estrelas,
        points: details.pontos,
        loading: true,
      };
      setUser(user);
    }).catch((err) => {
      setUser(null);
      reject(err);
    });
  });

  const updateUser = ({ UID, provider, email }: { UID: string; provider: string; email: string }) => new Promise((resolve, reject) => {
    if (!ORBIA) { // sensidia
        return axios.get(`${URL}api/sensidia/user-info`, {
          params: { email },
          withCredentials: true,
        }).then((response) => {
          if (!response.data.details ) {
            throw new Error('Não foi possivel acessar os dados do usuário');
          }
          setProvider(response.data.provider);
          sendEvent({ category: 'login', label: 'recarrega-dados', action: 'refresh' });
          return response.data.details;
        }).then((details) => {
        const user = {
          UID,
          email: details.personalData.email,
          name: details.personalData.nickname || details.personalData.name,
          CPF: details.personalData.documentNumber,
          stars: details.memberSegmentation.tierImpulses,
          points: details.memberSegmentation.memberTier,
          phone: details.personalData.cellphoneNumber || details.personalData.workphoneNumber,
          loading: true,
          plantedArea: details.memberPlantedArea,
        };
        setUser(user);

        resolve(user);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    } else {
      return axios.get(`${URL}api/orbia/user-info`, {
        params: {
          UID,
          provider
        }
      }).then((response) => {
        if (!response.data.details ) {
          throw new Error('Não foi possivel acessar os dados do usuário');
        }
        return response.data.details;
      }).then((details) => {
        const user = {
          UID,
          name: `${details.nome} ${details.sobrenome}`,
          email: details.email,
          CPF: details.cPF,
          stars: details.estrelas,
          points: details.pontos,
          loading: true,
        };
        setUser(user);
        resolve(user);
      }).catch((err) => {
        setUser(null);
        reject(err);
      });
    }
  });

  useEffect(() => {
    if (!ORBIA) { // sensidia
      if (!user && gigya.UID && gigya.provider && userInfo.email) {
        updateUser({ UID: gigya.UID, provider: gigya.provider, email: userInfo.email })
      }
    } else { // orbia
      if (!user && gigya.UID && gigya.provider) {
        updateUser({ UID: gigya.UID, provider: gigya.provider, email: '' });
        return;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ORBIA, user]);

  const logout = () => new Promise((resolve, reject) => {
    try {
      clearUserInfo();
      setUser(null);
      resolve(user);
    } catch (err) {
      reject(err);
    }
  });

  return {
    user,
    updateUser,
    logout,
  };
}
