import React, { useEffect } from 'react';
import { FullscreenHud, FullscreenHudElement } from '@dream-pixel/crew-engine-web';
import { assets } from '../../assets';
import { FinalVideoService } from '../../services/final-video.service';
import BoletimDoClimaComponent from './boletim-do-clima/boletim-do-clima.component';
import './clima.page.scss';
import { Button } from '@material-ui/core';

export default function ClimaPage() {

  const [showBolinhas, setShowBolinhas] = React.useState<boolean>(false);
  const [weatherRegion, setWeatherRegion] = React.useState<string>('south-west');

  useEffect(() => {

    FinalVideoService.getFinal().subscribe((nomeVideo: boolean) => {
      if (window.location.href.indexOf('clima') > -1) {
        setShowBolinhas(true);
      }
    });

  }, []);

  const changeWeather = (region: string) => {
    setWeatherRegion(region);

    document.body.classList.remove("north");
    document.body.classList.remove("midwest");
    document.body.classList.remove("north-east");
    document.body.classList.remove("south-west");
    document.body.classList.remove("south");

    document.body.classList.add(region);
  };

  return (
    <>
      {
        showBolinhas &&
        <>
          <div className="mapa">
            <FullscreenHud>
              <FullscreenHudElement key="north" highlight={assets.empty} onClick={() => changeWeather('north')}>
                <text x="300" y="250" className='text-button north'>Norte</text>
              </FullscreenHudElement>
              <FullscreenHudElement key="midwest" highlight={assets.empty} onClick={() => changeWeather('midwest')}>
                <svg className='text-button midwest'>
                  <text x="470" y="380">Centro</text>
                  <text x="470" y="430">Oeste</text>
                </svg>
              </FullscreenHudElement>
              <FullscreenHudElement key="north-east" highlight={assets.empty} onClick={() => changeWeather('north-east')}>
                <text x="690" y="290" className='text-button north-east'>Nordeste</text>
              </FullscreenHudElement>
              <FullscreenHudElement key="south-west" highlight={assets.empty} onClick={() => changeWeather('south-west')}>
                <text x="700" y="500" className='text-button south-west'>Sudeste</text>
              </FullscreenHudElement>
              <FullscreenHudElement key="south" highlight={assets.empty} onClick={() => changeWeather('south')}>
                <text x="500" y="680" className='text-button south'>Sul</text>
              </FullscreenHudElement>
            </FullscreenHud>
          </div>
          <BoletimDoClimaComponent region={weatherRegion} />
          <ul className='menu-mapa-mobile'>
            <li>
              <Button className='bt' onClick={() => changeWeather('north')}>
                Norte
              </Button>
            </li>
            <li>
              <Button className='bt' onClick={() => changeWeather('midwest')}>
                Centro Oeste
              </Button>
            </li>
            <li>
              <Button className='bt' onClick={() => changeWeather('north-east')}>
                Nordeste
              </Button>
            </li>
            <li>
              <Button className='bt' onClick={() => changeWeather('south-west')}>
                Sudeste
              </Button>
            </li>
            <li>
              <Button className='bt' onClick={() => changeWeather('south')}>
                Sul
              </Button>
            </li>
          </ul>
        </>
      }
    </>
  );
}
