import React, { useContext, useState, useEffect, useRef } from 'react';
import "./chat-live.page.scss";
import impulsoImg from "../../assets/menu/impulso-logo-branco.png";
import imgCarol from "../../assets/modal-login/mulher.png";
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Comments from '../../components/comments/comments';
import YouTube from 'react-youtube';
import { UserContext } from '../../components/userContext';
import CloseIcon from '@material-ui/icons/Close';
import QuestionMark from '@material-ui/icons/ContactSupport';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import LocalMall from '@material-ui/icons/LocalMall';
import Pause from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { sendEvent } from '../../scripts/events';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { SalvarTarefaSubjectService } from '../../services/salvar-tarefa.service';
import ComentaiosComponent from '../../components/comentarios/comentarios.component';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { SocketService } from '../../services/socket.service';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

export type ChatLivePageProps = {
    liveItem: Live;
    userConvidado?: string;
    onClose: () => void;
    onLogin: ({ command, metadata }: { command: number, metadata: {} }) => void;
}
type Live = {
    live: string;
    livecommerce: string;
    tamanho_fonte_live: string;
    theme: string;
    date: string;
    hour: string;
    status: string;
    liveid: string;
    iframe: string;
    name: string;
    speaker: string;
    desafio_premiado: string;
    texto_cta_carol: string;
    link_cta_carol: string;
    texto_link_cta_carol: string;
    link_cta_carol_2: string;
    texto_link_cta_carol_2: string;
}

type BannersLive = {
    banner: string;
    descricao: string;
    url: string;
    ativo: boolean;
}

export default function ChatLivePage(props: ChatLivePageProps) {
    const userProps = useContext(UserContext);
    const [liveData, setLiveData] = useState<Live>();
    const [liveConvidado, setLiveConvidado] = useState<string>();
    const [animationActive, setAnimationActive] = React.useState<boolean>(false);
    const [commentsActive, setCommentsActive] = useState(false);
    const [showProdutos, setShowProdutos] = useState(false);
    const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);
    const [idUnicoUsConvidado, setIdUnicoUsConvidado] = React.useState<string>();

    const [moderacaoChat, setModeracaoChat] = React.useState<any>();

    const [itensBanners, setItensBanners] = React.useState<BannersLive[]>([]);
    const [itensBannersUser, setItensBannersUser] = React.useState<BannersLive[]>([]);
    const [isBannersAtivo, setIsBannersAtivo] = React.useState<boolean>(false);

    const playerRef = useRef(null);
    const [audioOn, setAudioOn] = React.useState(true);
    const [deuPlay, setDeuPlay] = React.useState(false);

    let admWPLocal = localStorage.getItem('admWP');
    let emailAdminWP = window.atob((JSON.parse(admWPLocal)).nimdaliame);;
    let adminWP = emailAdminWP.split('@');

    useEffect(() => {

        setTimeout(() => {
            setAnimationActive(true);
            if (!localStorage.getItem("idConvidado")) {
                let n = Math.floor(Math.random() * 1000000000000)
                localStorage.setItem("idConvidado", ('conv' + n));
            }
            setIdUnicoUsConvidado(localStorage.getItem("idConvidado"));
        }, 100);

        conectarSocket();

        let admWP = localStorage.getItem('admWP');
        let itensModeracaoChat = (JSON.parse(admWP)).moderacao_chat;
        setModeracaoChat(itensModeracaoChat);
        setItensBanners((JSON.parse(admWP)).banners_live_commerce);

        setLiveData(props.liveItem);
        setLiveConvidado(props.userConvidado);

        if (window.innerWidth <= 1024) {
            setIsFullScreen(true);
        }

        return () => {
            //SocketService.disconnect();
        }
    }, [props.liveItem, props.userConvidado]);

    const onReady = (event: any) => {
        setLiveData(props.liveItem);
        setLiveConvidado(props.userConvidado);
    }

    const conectarSocket = () => {
        SocketService.on('resNodeBanners', (itens: Array<BannersLive>) => {
            setItensBannersUser(itens);
        });
    }

    const ativaBanner = (index: number) => {
        let newItensBanners: Array<BannersLive> = itensBanners;
        newItensBanners[index].ativo = !newItensBanners[index].ativo;
        setItensBanners([...newItensBanners]);
        SocketService.emit('callReactBanners', newItensBanners);
    }

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    }

    const toggleMuteVideo = () => {
        if (audioOn) {
            playerRef.current.internalPlayer.mute();
            setAudioOn(false);
        } else {
            playerRef.current.internalPlayer.unMute();
            setAudioOn(true);
        }
    };

    return (
        <section className={"chat-live flex" + " animation-" + animationActive}>
            <div className="area-title flex flex-cv flex-sp flex-wr">
                <img src={impulsoImg} alt="Impulso Bayer" />
                <div className="txt">
                    {liveData && (
                        <>
                            {
                                liveData.tamanho_fonte_live ?
                                    <h1 style={{ fontSize: `${liveData.tamanho_fonte_live}px` }}>{liveData.name}</h1>
                                    :
                                    <h1>{liveData.name}</h1>
                            }
                            <h2>{liveData.speaker}</h2>
                        </>
                    )}
                </div>
                <div className="box-carol flex flex-wr flex-bv">
                    {
                        liveData?.texto_cta_carol &&
                        <>
                            <img src={imgCarol} alt="Carol" />
                            <p>
                                <span>
                                    {liveData?.texto_cta_carol}
                                </span>
                                {
                                    liveData?.texto_link_cta_carol &&
                                    <span style={{ display: 'block' }}>
                                        <Button onClick={() => {
                                            sendEvent({ category: 'chat-live', label: 'resgate_cta_live', action: 'click' });
                                        }} className="bt" href={liveData?.link_cta_carol} target="_blank">
                                            {liveData?.texto_link_cta_carol}
                                        </Button>
                                    </span>
                                }
                                {
                                    liveData?.texto_link_cta_carol_2 &&
                                    <Button onClick={() => {
                                        sendEvent({ category: 'chat-live', label: 'resgate_cta_live', action: 'click' });
                                    }} className="bt branco" href={liveData?.link_cta_carol_2} target="_blank">
                                        {liveData?.texto_link_cta_carol_2}
                                    </Button>
                                }
                            </p>
                        </>
                    }
                </div>
            </div>
            <div className="area-text flex flex-cv flex-ch flex-wr">
                <div className="itens ">
                    <div className={"deu-play-" + deuPlay + " " + (isFullScreen ? "youtube ativo" : "youtube")}>

                        <YouTube
                            ref={playerRef}
                            containerClassName='youtube'
                            videoId={props?.liveItem?.liveid}
                            opts={{
                                height: '390',
                                width: '640',
                                playerVars: {
                                    autoplay: 0,
                                    //controls: 0,
                                    showinfo: 0,
                                    rel: 0,
                                    modestbranding: 1,
                                }
                            }}
                            onPlay={() => {
                                sendEvent({ category: 'chat-live', label: 'Live_play', action: 'click' });
                                //setAudioOn(true);
                                setDeuPlay(true);
                            }}
                            onEnd={() => {
                                //setAudioOn(false);
                            }}
                            onReady={onReady}
                        />

                        {/* <div className="iframe" dangerouslySetInnerHTML={{ __html: props?.liveItem?.iframe }}></div> */}

                        {/* <iframe width="640" height="390" src="https://sdk-hml.mimo.com.br/0.0.1/embed/player.html?liveId=1ec35d15-7d55-6056-9747-0a557297d766" allow="autoplay; encrypted-media"></iframe> */}
                        <Button className="bt-full" onClick={toggleFullScreen}>
                            {
                                isFullScreen ?
                                    <FullscreenExitIcon /> :
                                    <FullscreenIcon />
                            }
                        </Button>
                    </div>
                    {(userProps.user || liveConvidado) && (
                        <div className={"logado full-" + isFullScreen}>

                            {
                                props?.liveItem?.livecommerce === 'Sim' ?
                                    <>
                                        <Button className="bt-comments" onClick={() => {
                                            sendEvent({ category: 'chat-live', label: 'Live_chat', action: 'click' });
                                            setCommentsActive(true);
                                            setShowProdutos(false);
                                            if (localStorage.getItem('tarefaTipo') === 'Tarefa 3' && liveData.desafio_premiado === 'Live Especial do Clima com Marcos') {
                                                setTimeout(() => {
                                                    SalvarTarefaSubjectService.sendSalvar(true);
                                                }, 10000);
                                            }
                                            if (localStorage.getItem('tarefaTipo') === 'Tarefa 4' && liveData.desafio_premiado === 'Live Especial de Converse Bayer') {
                                                setTimeout(() => {
                                                    SalvarTarefaSubjectService.sendSalvar(true);
                                                }, 10000);
                                            }
                                        }}>
                                            <QuestionAnswerIcon /> <span>Chat ao vivo</span>
                                        </Button>

                                        <Button className="bt-comments" onClick={() => {
                                            sendEvent({ category: 'chat-live', label: 'Live_produtos', action: 'click' });
                                            setShowProdutos(true);
                                            setCommentsActive(false);
                                        }}>
                                            <LocalMall /> <span>Promoções</span>
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button className="bt-comments" onClick={() => {
                                            sendEvent({ category: 'chat-live', label: 'Live_chat', action: 'click' });
                                            setCommentsActive(true);
                                            setShowProdutos(false);
                                            if (localStorage.getItem('tarefaTipo') === 'Tarefa 3' && liveData.desafio_premiado === 'Live Especial do Clima com Marcos') {
                                                setTimeout(() => {
                                                    SalvarTarefaSubjectService.sendSalvar(true);
                                                }, 10000);
                                            }
                                            if (localStorage.getItem('tarefaTipo') === 'Tarefa 4' && liveData.desafio_premiado === 'Live Especial de Converse Bayer') {
                                                setTimeout(() => {
                                                    SalvarTarefaSubjectService.sendSalvar(true);
                                                }, 10000);
                                            }
                                        }}>
                                            <QuestionAnswerIcon /> <span>Chat ao vivo</span>
                                        </Button>
                                    </>
                            }

                            {
                                props?.liveItem?.livecommerce === 'Sim' &&
                                <Button className="bt-comments" onClick={() => {
                                    toggleMuteVideo();
                                }}>
                                    {
                                        audioOn ?
                                            <VolumeUpIcon />
                                            :
                                            <VolumeOffIcon />
                                    }
                                </Button>
                            }
                        </div>
                    )}
                    <Button className="bt-fechar" onClick={props.onClose}>
                        <HighlightOffIcon />
                    </Button>
                </div>
            </div>
            {(userProps.user || liveConvidado) && (
                <>
                    <div className={commentsActive ? "active modal-comments" : "modal-comments"}>
                        <Comments liveId={props?.liveItem?.liveid} userConvidado={{
                            user: {
                                UID: liveConvidado ? idUnicoUsConvidado : '',
                                name: liveConvidado,
                                email: 'aa@aa.com',
                                CPF: '12345678982',
                                stars: 0,
                                points: 0,
                                loading: true,
                                phone: '',
                                plantedArea: []
                            }
                        }} />
                        {/* <ComentaiosComponent liveId={props?.liveItem?.liveid} usuario={liveConvidado === adminWP[0] ? 'Espaço Bayer' : liveConvidado} palavrasModeradasADM={moderacaoChat} /> */}

                        <Button className="close-comments" onClick={() => setCommentsActive(false)}>
                            <CloseIcon />
                        </Button>
                    </div>
                </>
            )}

            {
                liveConvidado === adminWP[0] &&
                <div className={"adm-banners ativo-" + isBannersAtivo}>
                    <div className="itens">
                        {
                            itensBanners.map((item, index) => (
                                <div className={"item ativo-" + item.ativo} onClick={() => ativaBanner(index)}>
                                    <img src={item.banner} alt="Banner" />
                                </div>
                            ))
                        }
                    </div>
                    <Button className='bt' onClick={() => { setIsBannersAtivo(!isBannersAtivo) }}>
                        <ArrowBack />
                    </Button>
                </div>
            }
            <div className="user-banners">
                {
                    itensBannersUser.map((item, index) => (
                        <div className={"item ativo-" + item.ativo}>
                            <a href={item.url} target="_blank" className="flex flex-cv">
                                {/* <img src={item.banner} alt="Banner" /> */}
                                <div className="img" style={{ backgroundImage: `url(${item.banner})` }}></div>
                                <div className="desc" dangerouslySetInnerHTML={{ __html: item.descricao }}></div>
                                <AddShoppingCart />
                            </a>
                        </div>
                    ))
                }
            </div>
            <div className={"user-produtos ativo-" + showProdutos}>
                <div className="itens">
                    {
                        itensBanners.map((item, index) => (
                            <div className={"item"}>
                                <a onClick={() => {
                                    sendEvent({ category: 'chat-live', label: 'cta_live_prod' + (index + 1), action: 'click' });
                                }} href={item.url} target="_blank" className="flex flex-cv">
                                    <div className="img" style={{ backgroundImage: `url(${item.banner})` }}></div>
                                    <div className="desc" dangerouslySetInnerHTML={{ __html: item.descricao }}></div>
                                    <AddShoppingCart />
                                </a>
                            </div>
                        ))
                    }
                </div>
                <Button className="close-produtos" onClick={() => setShowProdutos(false)}>
                    <CloseIcon />
                </Button>
                <Button onClick={() => {
                    sendEvent({ category: 'chat-live', label: 'bt_duvida_prod_live', action: 'click' });
                }} className="duvida-produtos" href="https://wa.me/551142103591?text=Ol%C3%A1!%20Tenho%20d%C3%BAvidas%20sobre%20as%20ofertas%20da%20Live%20Commerce%20FieldView%20Shop.%20Me%20chamo%20" target="_blank">
                    <QuestionMark />
                </Button>
            </div>
        </section>
    );
}