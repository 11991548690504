import { createAudioData, createImageData, createVideoData, AssetData } from '@dream-pixel/crew-engine-web';
import {
  isMobile
} from "react-device-detect";

export const desktopAssets = {
  menu: createImageData(require('./menu.[p=1].png'), 1802, '', 1),

  audioOff: createImageData(require('./audioOff.[p=1].png'), 1802, '', 1),
  audioOn: createImageData(require('./audioOn.[p=1].png'), 3697, '', 1),

  empty: createImageData(require('./empty.[p=1].png'), 61281, '', 1),

  // Piso inferior
  reunioes: createVideoData(require('./videos2/003_Reuniao.mp4'), 4823449, '', 1),
  clima: createVideoData(require('./videos2/004_Clima.mp4'), 1782579, '', 1),
  forum: createVideoData(require('./videos2/005_Forum.mp4'), 1992294, '', 1),
  lives: createVideoData(require('./videos2/006_Live.mp4'), 1992294, '', 1),
  expodireto: createVideoData(require('./videos2/006_Live.mp4'), 1992294, '', 1),
  chatCarol: createVideoData(require('./videos2/16_Help_Inicio.mp4'), 1887436, '', 1),
  homeSuperior: createVideoData(require('./videos2/007_Piso_Superior.mp4'), 104857, '', 1),
  entradaInferior: createVideoData(require('./videos2/002_Piso_Inferior.mp4'), 5347737, '', 1),
  home: createVideoData(require('./videos2/002_Piso_Inferior_fade.mp4'), 104857, '', 1),
  homeInferior: createVideoData(require('./videos2/002_Piso_Inferior_fade.mp4'), 104857, '', 1),

  negocios: createVideoData(require('./videos2/012_Negocios.mp4'), 1572864, '', 1),
  inovacao: createVideoData(require('./videos2/013_inovacao.mp4'), 1363148, '', 1),
  produtividade: createVideoData(require('./videos2/008_Produtividade.mp4'), 1887436, '', 1),
  risco: createVideoData(require('./videos2/009_Risco.mp4'), 2831155, '', 1),
  totem: createVideoData(require('./videos2/010_Risco_Totem.mp4'), 1258291, '', 1),
  voltarRisco: createVideoData(require('./videos2/011_Risco_Totem_Reverso.mp4'), 1258291, '', 1),
  foxXpro: createVideoData(require('./videos2/014_Fox_Pro.mp4'), 1258291, '', 1),
  foxXproPlantacao: createVideoData(require('./videos2/015_Fox_Pro_Plantacao.mp4'), 1258291, '', 1),

  loading: createImageData(require('./loading.[p=0].png'), 2832520, '', 0),
  music: createAudioData(require('./music.[p=1].mp3'), 2990938, '', 1),
  temp: createImageData(require('./temp.[p=0].png'), 2281392, '', 0)
};

export const mobileAssets = {
  menu: createImageData(require('./menu.[p=1].png'), 1802, '', 1),

  audioOff: createImageData(require('./audioOff.[p=1].png'), 1802, '', 1),
  audioOn: createImageData(require('./audioOn.[p=1].png'), 3697, '', 1),

  empty: createImageData(require('./empty.[p=1].png'), 61281, '', 1),

  entradaInferior: createVideoData(require('./videos2/001_Entrada_Mobile.mp4'), 5347737, '', 1),
  chatCarol: createVideoData(require('./videos2/16_Help_Entrada_vertical.mp4'), 1887436, '', 1),

  loading: createImageData(require('./loading.[p=0].png'), 2832520, '', 0),
  music: createAudioData(require('./music.[p=1].mp3'), 2990938, '', 1),
  temp: createImageData(require('./temp.[p=0].png'), 2281392, '', 0)
};

export const assetsMobileList: AssetData[] = Object.getOwnPropertyNames(mobileAssets).map(prop => (mobileAssets as any)[prop]);
export const assetsDesktopList: AssetData[] = Object.getOwnPropertyNames(desktopAssets).map(prop => (desktopAssets as any)[prop]);

export const assets = window.innerWidth < 768 ? mobileAssets : desktopAssets;
