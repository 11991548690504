import React, { useEffect, useRef } from 'react';
import './expodireto.page.scss';
import emblemaBayer from '../../assets/lpshowrural/emblema-bayer.png';
import logoShowRural from '../../assets/lpshowrural/expodireto/logo-expodireto.png';
import icoCalendario from '../../assets/lpshowrural/ico-calendario.png';
import logoCfv from '../../assets/lpshowrural/cfv.jpg';
import logoImpulso from '../../assets/lpshowrural/impulso.jpg';
import logoImpulsoFoot from '../../assets/lpshowrural/expodireto/logo-poweby-footer.png';
import logoFootExpo from '../../assets/lpshowrural/expodireto/logo-footer.png';

import adengoFoot from '../../assets/lpshowrural/adengo.jpg';
import curbixFoot from '../../assets/lpshowrural/curbix.jpg';
import foxFoot from '../../assets/lpshowrural/fox.jpg';
import vtpro4Foot from '../../assets/lpshowrural/vtpro4.jpg';
import { Button } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LoadVideoBgService } from '../../services/load-video-bg.service';
import { ROTAVIDEOS } from '../../constants/rotas-videos.constant';
import { SalvarTarefaSubjectService } from '../../services/salvar-tarefa.service';

export function ExpodiretoPage() {

  const [showModalShowRural, setShowModalShowRural] = React.useState<boolean>(false);
  const [videoAtual, setVideoAtual] = React.useState<number>(0);

  const myRef = useRef(null);

  const executeScroll = () => {
    if (myRef.current.scrollTop > 90) {
      document.body.classList.add("hide-logo");
    } else {
      document.body.classList.remove("hide-logo");
    }

    if (myRef.current.scrollHeight - myRef.current.scrollTop === myRef.current.clientHeight) {
      if (localStorage.getItem('tarefaTipo') === 'Tarefa 8') {
        setTimeout(() => {
          SalvarTarefaSubjectService.sendSalvar(true);
        }, 10000);
      }
    }
  }

  useEffect(() => {
    document.body.classList.add("expodireto");

    return () => {
      document.body.classList.remove("expodireto");
    }
  }, []);

  return (
    <>
      <section className="expodireto" ref={myRef} onScroll={executeScroll}>
        <div className="banner">
          <div className="top flex flex-ri flex-cv">
            <div className="dir flex flex-cv">
              <Button
                className='closeModal'
                aria-label='Fechar'
                onClick={() => LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.home)}
              />
              <img src={emblemaBayer} alt="Logo" />
            </div>
          </div>

          <div className="container">
            <div className="cols flex flex-wr">
              <div className="esq">
                <h1>
                O METAVERSO <br />
                DA BAYER NA <br />
                EXPODIRETO
                </h1>
                <h2>
                Não-Me-Toque/RS <br />
                07 a 11 de Março de 2022 
                </h2>
                <p>
                No mundo físico e no virtual, aqui você confere <br />
                todas as atrações do Espaço Bayer na feira.
                </p>
                <p>
                Acompanhe nossas TRANSMISSÕES AO VIVO <br />
                e saiba mais sobre as oportunidades Bayer com <br />
                os melhores produtos e serviços para o <br />
                Universo do Agronegócio.
                </p>
              </div>
              <div className="dir">
                <img src={logoShowRural} alt="Logo Show Rural" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="banner-expo" onClick={()=>{
          LoadVideoBgService.sendNomeVideo(ROTAVIDEOS.expodireto);
        }}>
          <img src={bannerExpoDesk} alt="Banner" className="desktop" />
          <img src={bannerExpoMob} alt="Banner" className="mobile" />
        </div> */}
        <div className="transmissoes">
          <div className="container">
            <div className="cols flex flex-wr flex-sp">
              <div className="esq">
                <h3>
                  É dia de
                </h3>
                <h4>
                  Feira!
                </h4>
                <p>
                  Confira as nossas transmissões com tudo <br />
                  que aconteceu na Expodireto 2022
                </p>
                <small>
                  *Horário de Brasília
                </small>
              </div>

              <div className="itens flex flex-sp flex-wr">
                <div className="item ativo" onClick={() => {
                  setShowModalShowRural(true);
                  setVideoAtual(1);
                }}>
                  <div className="thumb t1"></div>
                  <h5 className='ativo'>
                    <span>
                      Transmissão realizada
                    </span>
                  </h5>
                  <div className="box-calendario flex flex-cv">
                    <img src={icoCalendario} alt="Ico calendário" />
                    <span>
                      09/03, às 13h
                    </span>
                  </div>
                </div>
                <div className="item ativo" onClick={() => {
                  setShowModalShowRural(true);
                  setVideoAtual(2);
                }}>
                  <div className="thumb t2"></div>
                  <h5 className='ativo'>
                    <span>
                    Transmissão realizada
                    </span>
                  </h5>
                  <div className="box-calendario flex flex-cv">
                    <img src={icoCalendario} alt="Ico calendário" />
                    <span>
                      10/03, às 13h
                    </span>
                  </div>
                </div>
                <div className="item ativo" onClick={() => {
                  setShowModalShowRural(true);
                  setVideoAtual(3);
                }}>
                  <div className="thumb t3"></div>
                  <h5 className='ativo'>
                    <span>
                    Transmissão realizada
                    </span>
                  </h5>
                  <div className="box-calendario flex flex-cv">
                    <img src={icoCalendario} alt="Ico calendário" />
                    <span>
                      11/03, às 15h
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="oportunidades">
          <div className="container">
            <h2>
              OPORTUNIDADES BAYER
            </h2>
            <h3>
              Todos os produtos e serviços apresentados na Expodireto você encontra aqui!
            </h3>
            <p>
              <strong>
                Clique e saiba mais:
              </strong>
            </p>
            <div className="box-owl">
              <OwlCarousel
                className='owl-theme'
                loop
                margin={10}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  450: {
                    items: 1,
                  },
                  600: {
                    items: 1,
                  },
                  1000: {
                    items: 2,
                  },
                }}
              >
                <div className="item">
                  <a href="https://www.agro.bayer.com.br/impulso-bayer" target='_blank'>
                    <img src={logoImpulso} alt="Logo Impulso" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://www.agro.bayer.com.br/impulso-bayer/beneficios/climatefieldview" target='_blank'>
                    <img src={logoCfv} alt="Logo CFV" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://www.agro.bayer.com.br/essenciais-do-campo/protecaodecultivos/adengo" target='_blank'>
                    <img src={adengoFoot} alt="Logo Adengo" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://www.agro.bayer.com.br/essenciais-do-campo/protecaodecultivos/curbix" target='_blank'>
                    <img src={curbixFoot} alt="Logo Curbix" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://www.agro.bayer.com.br/fungicida-fox-xpro" target='_blank'>
                    <img src={foxFoot} alt="Logo Fox" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://vtpro4.com.br/" target='_blank'>
                    <img src={vtpro4Foot} alt="Logo Vtpro4" />
                  </a>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div className="expodireto">
          <div className="container">
            <div className="flex flex-ch flex-cv">
              <div className="esq">
                <img onClick={executeScroll} src={logoFootExpo} alt="Logo Expodireto" />
              </div>
              <div className="dir">
                <h4>SAVE THE DATE</h4>
                <h5>04 a 08/04/2022</h5>
                <p>
                  Com transmissão <br />
                  e conteúdos exclusivos <br />
                  by ESPAÇO BAYER
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="container">
            <img src={logoImpulsoFoot} alt="Logo Impulso" />
          </div>
        </div>
      </section>
      <div className={"modal-show-rural flex flex-ch flex-cv " + (showModalShowRural && 'ativo')}>
        {
          showModalShowRural &&
          <>
            {
              videoAtual === 1 &&
              <iframe allowFullScreen width="560" height="315" src="https://www.youtube.com/embed/spc6PH6xWq4" title="YouTube video player" allow="fullscreen, accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            }
            {
              videoAtual === 2 &&
              <iframe allowFullScreen width="560" height="315" src="https://www.youtube.com/embed/Rr3Afo6g1lI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            }
            {
              videoAtual === 3 &&
              <iframe allowFullScreen width="560" height="315" src="https://www.youtube.com/embed/xJCJtPqTqZU" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            }
          </>
        }

        <Button
          className='closeModal'
          aria-label='Fechar'
          onClick={() => {
            setShowModalShowRural(false);
          }}
        />
      </div>
    </>
  );
}
