import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { UserType } from '../../types/user';
import { UserContext } from '../userContext';

const URL = 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/';

const HyvorContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

type HyvorTalkProps = {
  user: UserType;
  liveId: string;
}

type userConvidado = {
  user: UserType;
}

const HyvorTalk = ({ user, liveId }: HyvorTalkProps) => {
  useEffect(() => {
    const executeHyvorScript = () => {
      const customHyvorScript = document.createElement('script');
      const hyvorScript = document.createElement('script');

      const UID = user.UID;
      const name = user.name;
      const email = user.email;

      customHyvorScript.src = `${URL}api/hyvor/get-app?id=${UID}&name=${name}&email=${email}&liveId=${liveId}`;
      hyvorScript.src = "//talk.hyvor.com/web-api/embed";
      customHyvorScript.async = true;
      hyvorScript.async = true;
      document.body.appendChild(customHyvorScript);
      document.body.appendChild(hyvorScript);

      return () => {
        document.body.removeChild(customHyvorScript);
        document.body.removeChild(hyvorScript);
      }
    };
    executeHyvorScript();
  }, []);

  return <HyvorContainer id="hyvor-talk-view" />;
}

export default function Comments({ liveId, userConvidado }: { liveId: string, userConvidado?: userConvidado }) {
  const usContext = useContext(UserContext);
  const userProps = userConvidado.user.UID ? userConvidado : usContext;
  const [showHyvorTalk, setShowHyvorTalk] = useState(false);

  useEffect(() => {
    if (userProps.user) {
      setShowHyvorTalk(false);
      setTimeout(() => {
        setShowHyvorTalk(true);
      }, 500);
      setTimeout(() => {
        setShowHyvorTalk(false);
      }, 1000);
      setTimeout(() => {
        setShowHyvorTalk(true);
      }, 1500);
      //return;
    } else {
      setShowHyvorTalk(false);
    }

  }, [userProps.user]);

  return (
    <>
      {showHyvorTalk && (
        <HyvorTalk user={userProps.user} liveId={liveId} />
      )}
      {!showHyvorTalk && (
        <p>Carregando ...</p>
      )}
    </>
  );
}