
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import GigyaLoginComponent from './components/gigya-login/gigya-login.component';
import HeaderComponent from './components/header/header.component';
import LoadingComponent from './components/loading/loading.component';
import SnackComponent from './components/snack/snack.component';
import VideosBgComponent from './components/videos-bg/videos-bg';
import { ROTAVIDEOS } from './constants/rotas-videos.constant';
import AgendaPage from './pages/agenda/agenda.page';
import ChatCarolPage from './pages/chat-carol/chat-carol.page';
import ChatPlantacaoPage from './pages/chat-chat-plantacao/chat-plantacao.page';
import ChatFoxPage from './pages/chat-fox/chat-fox.page';
import ClimaPage from './pages/clima/clima.page';
import ForumPage from './pages/converse-bayer/forum.page';
import EntradaInferiorPage from './pages/entrada-inferior/entrada-inferior.page';
import EntradaPage from './pages/entrada/entrada.page';
import HomeSuperiorPage from './pages/home-superior/home-superior.page';
import HomePage from './pages/home/home.page';
import InovacaoPage from './pages/inovacao/inovacao.page';
import LoadingEntradaPage from './pages/loading-entrada/loading-entrada.page';
import MaisServicosPage from './pages/mais-servicos/mais-servicos.page';
import NegociosPage from './pages/negocios/negocios.page';
import NotFoundPage from './pages/not-found/not-found.page';
import ProdutividadePage from './pages/produtividade/produtividade.page';
import RiscoPage from './pages/risco/risco.page';
import TotemPage from './pages/totem/totem.page';
import { LoadVideoBgService } from './services/load-video-bg.service';
import { MaisServicosService } from './services/mais-servicos.service';
import RedoIcon from '@material-ui/icons/Redo';
import StayCurrentLandscapeIcon from '@material-ui/icons/StayCurrentLandscape';
import { ShowRuralPage } from './pages/show-rural/show-rural.page';
import Home360ReunioesComponent from './pages/home/home360-reunioes/home360-reunioes.component';
import Home360RiscoComponent from './pages/home-superior/home-superior360-risco/home-superior360-risco.component';
import { ExpodiretoPage } from './pages/expodireto/expodireto.page';
import { AgroBrasiliaPage } from './pages/agrobrasilia/agrobrasilia.page';
import { TecnoShowPage } from './pages/tecnoshow/tecnoshow.page';

function Routes() {

    const [audioContrVisible, setAudioContrVisible] = useState(false);
    const [showLoadingPage, setShowLoadingPage] = useState(true);
    const [showMaisServicos, setShowMaisServicos] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {

        MaisServicosService.get().subscribe(res => {
            setShowMaisServicos(true);
        });

        let _nomeVideo = window.location.pathname.replace(/\//g, '');
        if (_nomeVideo === '') {
            setShowLoadingPage(false);
        } else {
            document.body.classList.add("loaded");
        }
    }, []);

    const onLoad = () => {
        let _nomeVideo = window.location.pathname.replace(/\//g, '');

        if (_nomeVideo != '') {
            setShowLoadingPage(false);
            LoadVideoBgService.sendNomeVideo(_nomeVideo);
        }
    }

    const onEntrar = () => {
        setAudioContrVisible(true);
    }

    return (
        <BrowserRouter>
            <VideosBgComponent />
            <HeaderComponent
                audioContrVisible={audioContrVisible}
                login={() => {
                    setShowLogin(true);
                }}
            />
            <Switch>
                <Route exact path="/" component={() => (<LoadingEntradaPage onLoad={onLoad} onEntrar={onEntrar} />)} />
                <Route exact path={'/' + ROTAVIDEOS.entrada} component={() => (<EntradaPage onEntrar={onEntrar} />)} />
                <Route exact path={'/' + ROTAVIDEOS.entradaInferior} component={EntradaInferiorPage} />
                <Route exact path={'/' + ROTAVIDEOS.home} component={HomePage} />
                <Route exact path={'/' + ROTAVIDEOS.homeInferior} component={HomePage} />
                <Route exact path={'/' + ROTAVIDEOS.homeReunioes} component={Home360ReunioesComponent} />
                <Route exact path={'/' + ROTAVIDEOS.homeRisco} component={Home360RiscoComponent} />
                <Route exact path={'/' + ROTAVIDEOS.inovacao} component={InovacaoPage} />
                <Route exact path={'/' + ROTAVIDEOS.produtividade} component={ProdutividadePage} />
                <Route exact path={'/' + ROTAVIDEOS.risco} component={RiscoPage} />
                <Route exact path={'/' + ROTAVIDEOS.totem} component={TotemPage} />
                <Route exact path={'/' + ROTAVIDEOS.voltarRisco} component={RiscoPage} />
                <Route exact path={'/' + ROTAVIDEOS.reunioes} component={AgendaPage} />
                <Route exact path={'/' + ROTAVIDEOS.negocios} component={NegociosPage} />
                <Route exact path={'/' + ROTAVIDEOS.clima} component={ClimaPage} />
                <Route exact path={'/' + ROTAVIDEOS.forum} component={ForumPage} />
                <Route exact path={'/' + ROTAVIDEOS.lives} component={AgendaPage} />
                {/* <Route exact path={'/' + ROTAVIDEOS.expodireto} component={AgendaPage} /> */}
                <Route exact path={'/' + ROTAVIDEOS.chatCarol} component={ChatCarolPage} />
                <Route exact path={'/' + ROTAVIDEOS.homeSuperior} component={HomeSuperiorPage} />
                <Route exact path={'/' + ROTAVIDEOS.foxXpro} component={ChatFoxPage} />
                <Route exact path={'/' + ROTAVIDEOS.foxXproPlantacao} component={ChatPlantacaoPage} />

                <Route path={'/' + ROTAVIDEOS.showRural} component={ShowRuralPage} />
                <Route path={'/' + ROTAVIDEOS.agrobrasilia} component={AgroBrasiliaPage} />
                <Route path={'/' + ROTAVIDEOS.feira} component={ShowRuralPage} />
                <Route path={'/' + ROTAVIDEOS.expodireto} component={ExpodiretoPage} />
                <Route path={'/' + ROTAVIDEOS.tecnoshow} component={TecnoShowPage} />
                <Route component={NotFoundPage} />
            </Switch>
            {
                showMaisServicos &&
                <MaisServicosPage onClose={() => {
                    setShowMaisServicos(false);
                }} />
            }
            {
                showLoadingPage &&
                <LoadingEntradaPage onLoad={onLoad} onEntrar={onEntrar} />
            }
            {
                showLogin &&
                <GigyaLoginComponent
                    handleOnLogin={() => {
                        setShowLogin(false);
                    }}
                    redirectTo={0}
                    onClose={() => {
                        setShowLogin(false);
                    }}
                    metadata={() => {
                        //metadata
                    }} />
            }
            <SnackComponent />
            <LoadingComponent />

            <div className="msg-vertical flex flex-cv flex-ch flex-wr">
                <div>
                    <h3>Vire o seu smartphone/tablet na horizontal para acessar essa plataforma.</h3>
                    <div className="ico">
                        <RedoIcon className="redo" />
                        <StayCurrentLandscapeIcon className="vertical" />
                        <StayCurrentLandscapeIcon />
                    </div>
                    <h3>Pode ser necessário tirar a trava de girar a tela nas configurações do seu dispositivo.</h3>
                </div>
            </div>
        </BrowserRouter>
    )
}

export default Routes;